import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { fetchDataForToolkit } from "../helpers"
import { METHOD_TYPES } from "../../configs/constants"
import {
  ZAPIER_INTEGRATION,
  GET_ZAPIER_INTEGRATIONS_LIST,
  SEND_DATA_TO_ZAPIER_INDIVIDUALLY,
  SEND_DATA_TO_ALL_ZAPIER_SERVICES,
  GET_WEBHOOK_INFO,
  SYNCS_LEAD_FORMS, UPDATE_INTEGRATION_STATUS, GET_INTEGRATION_STATUS,
  GET_HUBSPOT_INFO,
  CONNECT_HUBSPOT,
  GET_SALESFORCE_INFO,
  CONNECT_SALESFORCE,
  GET_GOOGLESHEETS_INFO,
  CONNECT_GOOGLESHEETS,
  DISCONNECT_HUBSPOT,
  UPDATE_HUBSPOT,
  DISCONNECT_GOOGLESHEETS,
  DISCONNECT_SALESFORCE,
  UPDATE_SALESFORCE,
  UPDATE_GOOGLESHEETS
} from "./api"
import initialState from "./initialState"
import { enqueueSnackbar } from "notistack"
import {GET_CHANNELS_STATUS, UPDATE_CHANNEL_STATUS} from "../channelSlice/api";
import {getChannelsStatus, updateChannelStatus} from "../channelSlice";

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    updateIntegrationState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateIntegrationStatus.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.integrationStatus = {...state.integrationStatus , ...payload.data}
        }
      })
      .addCase(getIntegrationStatus.fulfilled, (state, { payload }) => {
        if (payload.data) state.integrationStatus = payload.data
      })
      .addCase(getZapierIntegrationList.fulfilled, (state, { payload }) => {
        if (payload?.data?.zaps) state.zapierIntegrationList = payload.data.zaps
      })
      .addCase(createZapierIntegration.fulfilled, (state, { payload }) => {
        if (payload?.data?.zap)
          state.zapierIntegrationList = [
            ...state.zapierIntegrationList,
            payload.data.zap,
          ]
      })
      .addCase(updateZapierIntegration.fulfilled, (state, { payload }) => {
        if (payload?.data?.zap) {
          const index = state.zapierIntegrationList.findIndex(
            (item) => item.webhookUrl === payload.data.zap.webhookUrl
          )
          if (index > -1) {
            state.zapierIntegrationList = [
              ...state.zapierIntegrationList.slice(0, index),
              payload.data.zap,
              ...state.zapierIntegrationList.slice(index + 1),
            ]
          }
        }
      })
      .addCase(deleteZapierIntegration.fulfilled, (state, { payload }) => {
        if (payload?.data?.zap) {
          const index = state.zapierIntegrationList.findIndex(
            (item) => item.webhookUrl === payload.data.zap.webhookUrl
          )
          if (index > -1) {
            state.zapierIntegrationList = [
              ...state.zapierIntegrationList.slice(0, index),
              ...state.zapierIntegrationList.slice(index + 1),
            ]
          }
        }
      })
      .addCase(getWebhookInfo.fulfilled, (state, { payload }) => {
        if (payload?.data?.integrationDoc) state.webhookInfo = payload.data.integrationDoc
      })
      .addCase(deleteWebhookLink.fulfilled, (state, { payload }) => {
        if (payload?.data) state.webhookInfo = {}
      })
      .addCase(updateWebhook.fulfilled, (state, { payload }) => {
        if (payload?.data?.integrationDoc) state.webhookInfo = payload.data.integrationDoc
      })
      .addCase(sendWebhookUrl.fulfilled, (state, { payload }) => {
        if (payload?.data?.integrationDoc) state.webhookInfo = payload?.data?.integrationDoc
      })
      .addCase(getHubspotInfo.fulfilled, (state,{payload}) => {
        if(payload.data.hubspot === undefined) {
          state.hubspotInfo.connection = false;
        } 
        else {
          state.hubspotInfo.connection = true;
          state.hubspotInfo.isEnabled = payload.data.hubspot;
        }
      })
      .addCase(disconnectHubspot.fulfilled,(state,{payload})=>{
        enqueueSnackbar("Disconnected",{variant: "error"});
        state.hubspotInfo.connection = false;
      })
      .addCase(getSalesforceInfo.fulfilled, (state,{payload})=>{
        if(payload.data.salesforce === undefined) {
          state.salesforceInfo.connection = false;
        } 
        else {
          state.salesforceInfo.connection = true;
          state.salesforceInfo.isEnabled = payload.data.salesforce;
        }
      })
      .addCase(disconnectSalesforce.fulfilled,(state,{payload})=>{
        enqueueSnackbar("Disconnected",{variant: "error"});
        state.hubspotInfo.connection = false;
      })
      .addCase(getGoogleSheetsInfo.fulfilled,(state,{payload})=>{
        if(payload.data["google-sheets"] === undefined) {
          state.googleSheetsInfo.connection = false;
        }
        else {
          state.googleSheetsInfo.connection = true;
          state.googleSheetsInfo.isEnabled = payload.data["google-sheets"];
        }
      })
      .addCase(disconnectGoogleSheets.fulfilled,(state,{payload})=>{
        enqueueSnackbar("Disconnected",{variant: "error"});
        state.googleSheetsInfo.connection = false;
      })
  },
})

export const updateIntegrationStatus = createAsyncThunk(
  "integration/updateIntegrationStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_INTEGRATION_STATUS,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)
export const getIntegrationStatus = createAsyncThunk(
  "integration/getIntegrationStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_INTEGRATION_STATUS,
        data,
      },
      helpers
    )
)

export const getZapierIntegrationList = createAsyncThunk(
  "integration/getZapierIntegrationList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_ZAPIER_INTEGRATIONS_LIST,
        data,
      },
      helpers
    )
)

export const createZapierIntegration = createAsyncThunk(
  "integration/createZapierIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_INTEGRATION,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const updateZapierIntegration = createAsyncThunk(
  "integration/updateZapierIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_INTEGRATION,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const deleteZapierIntegration = createAsyncThunk(
  "integration/deleteZapierIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_INTEGRATION,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
)

export const sendDatatoZapierIndividually = createAsyncThunk(
  "integration/sendDatatoZapierIndividually",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SEND_DATA_TO_ZAPIER_INDIVIDUALLY,
        method: METHOD_TYPES.POST,
        data,
        loader: false,
        modalLoader: true
      },
      helpers
    )
)

export const sendDatatoAllZapierServices = createAsyncThunk(
  "sendDatatoAllZapierServices",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SEND_DATA_TO_ALL_ZAPIER_SERVICES,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getWebhookInfo = createAsyncThunk(
  "integration/getWebhookInfo",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_WEBHOOK_INFO,
        data,
      },
      helpers
    )
)

export const sendWebhookUrl = createAsyncThunk(
  "integration/sendWebhookUrl",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_WEBHOOK_INFO,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const deleteWebhookLink = createAsyncThunk(
  "integration/deleteWebhookLink",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_WEBHOOK_INFO,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
)

export const updateWebhook = createAsyncThunk(
  "integration/updateWebhook",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_WEBHOOK_INFO,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const syncLeadForms = createAsyncThunk(
  "integration/syncLeadForms",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SYNCS_LEAD_FORMS,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getHubspotInfo = createAsyncThunk(
  "integration/getHubspotInfo",
  async (data = {}, helpers) => 
    fetchDataForToolkit(
      {
        url: GET_HUBSPOT_INFO,
        data,
      },
      helpers
    )
)

export const connectHubspot = createAsyncThunk(
  "integration/connectHubspot",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: CONNECT_HUBSPOT,
        data,
      },
      helpers
    ),
)

export const disconnectHubspot = createAsyncThunk(
  "integration/disconnectHubspot",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: DISCONNECT_HUBSPOT,
        data,
        method : METHOD_TYPES.DELETE
      },
      helpers
    ),
)

export const updateHubspot = createAsyncThunk(
  "integrations/updateHubspot",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: UPDATE_HUBSPOT,
        data,
        method : METHOD_TYPES.PUT
      },
      helpers
    )
)

export const getSalesforceInfo = createAsyncThunk(
  "integration/getSalesforceInfo",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: GET_SALESFORCE_INFO,
        data
      },
      helpers
    )
)

export const connectSalesforce = createAsyncThunk(
  "integration/connectSalesforce",
  async (data={},helpers) => 
    fetchDataForToolkit(
      {
        url: CONNECT_SALESFORCE,
        data
      },
      helpers
    )
)

export const disconnectSalesforce = createAsyncThunk(
  "integration/disconnectSalesforce",
  async (data={},helpers) => 
    fetchDataForToolkit(
      {
        url: DISCONNECT_SALESFORCE,
        data,
        method: METHOD_TYPES.DELETE
      },
      helpers
    )
)

export const updateSalesforce = createAsyncThunk(
  "integration/updateSalesforce",
  async (data={},helpers) => 
    fetchDataForToolkit(
      {
        url: UPDATE_SALESFORCE,
        data,
        method: METHOD_TYPES.PUT
      },
      helpers
    )
)


export const getGoogleSheetsInfo = createAsyncThunk(
  "integration/getGoogleSheetsInfo",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: GET_GOOGLESHEETS_INFO,
        data,
      },
      helpers
    )
)

export const connectGoogleSheets = createAsyncThunk(
  "integration/connectGoogleSheets",
  async (data={}, helpers) =>
    fetchDataForToolkit(
      {
        url: CONNECT_GOOGLESHEETS,
        data
      },
      helpers
    )
)

export const disconnectGoogleSheets = createAsyncThunk(
  "integration/disconnectGoogleSheets",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: DISCONNECT_GOOGLESHEETS,
        data,
        method : METHOD_TYPES.DELETE
      },
      helpers
    )
)

export const updateGoogleSheets = createAsyncThunk(
  "integrations/updateGoogleSheets",
  async (data={},helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_GOOGLESHEETS,
        data,
        method: METHOD_TYPES.PUT
      },
      helpers
    )
)

export const { updateIntegrationState } = integrationSlice.actions
export default integrationSlice.reducer
