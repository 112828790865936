const initialState = {
  loading: false,
  journeys: {},
  steps: {},
  journeyOrder: [],
  journeyDrawerState: {
    open: false,
    journeyId: null,
  },
  trackedEvents:{},
  guidedJourneyState: {
    stepIndex: 0,
    runJourney: false,
    completedSteps: [],
  }
}

export default initialState;
