import { Button, Card, Divider, Grid, IconButton, MenuItem, MenuList, Modal, Paper, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { ROUTE_PATH } from '../../../data/configs/constants';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import LockIcon from '@mui/icons-material/Lock';
import { StyledButton } from '../StyledButton';
import PaywallTooltip from './PaywallTooltip';

const ShowUpgradeOptions = ({
    plans,
    addOns,
    size = "default",
    title = "Get access to this feature",
    message = "Please subscribe to a plan or purchase addons from the list given below to unlock feature.",
    btnData = {}
}) => {
    const [openModal, setOpenModal] = useState(false)
    const handleClick = () => {
        setOpenModal(true)
    }
    const handleModalClose = () => {
        setOpenModal(false)
    }

    if (size === "default") return <RenderPayWall plans={plans} addOns={addOns} title={title} message={message}/>
    else return <Box sx={{
        display: 'flex',
        gap: 1
    }}>
        <Modal open={openModal} onClose={handleModalClose} sx={{
            outline: 0,
            overflow: 'auto',
            width: '90%',
            maxWidth: 600,
            maxHeight: '50%',
            m: 'auto',
        }}>
            <Paper sx={{ p: 2, color: 'primary' }}>
                <RenderPayWall plans={plans} addOns={addOns} title={title} message={message} />
            </Paper>
        </Modal>
        <PaywallTooltip title={title} arrow>
            {size === "icon" ? <IconButton onClick={handleClick} color='primary'>
                <LockIcon />
            </IconButton> :
                <StyledButton variant="contained" startIcon={<LockIcon />} onClick={handleClick} {...btnData.props}>{btnData.label || "Unlock Feature"}</StyledButton>
            }
        </PaywallTooltip>
    </Box>

}

const RenderPayWall = ({ plans, addOns, message, title }) => {
    const navigate = useNavigate();
    const handlePlansClick = () => {
        navigate(ROUTE_PATH.MAIN_PRICING)
    }
    const handleAddonClicked = () => {
        navigate(ROUTE_PATH.MAIN_PRICING + '#addon');
    }
    return <Box sx={{
        width: '100%',
        maxWidth: 700,
        p: 2,
    }}>
        <Typography
            variant="h6"
            align="center"
            color="inherit"
        >
            {title}
        </Typography>
        <Typography variant='body2' align='center' marginBottom={2}>{message}</Typography>
        <Grid container spacing={2} justifyContent={'center'}>
            <RenderGridItem key={1} item={plans} handleClick={handlePlansClick} title="Plan Subscription" btnText="Buy Plan" type="plan" />
            <RenderGridItem key={2} item={addOns} handleClick={handleAddonClicked} title="Purchase Addons" btnText="Buy Addon" type="addOns" />
        </Grid>
    </Box>
}

const RenderGridItem = ({ item, handleClick, title, btnText, type }) => {
    if (!item?.length) return null;
    return (
        <Grid item xs={12} sm={6}>
            <Card variant='outlined' sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant='body1' fontWeight={700} align='center' marginY={1}>{title}</Typography>
                    <Divider />
                    <MenuList>
                        {item?.map((plan, index) => (
                            <MenuItem onClick={handleClick} sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {type === "plan" ? <WorkspacePremiumIcon color='primary' fontSize='inherit' /> : <AddToPhotosIcon color='primary' fontSize='inherit' />}
                                <Typography noWrap>{plan}</Typography>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Button variant='contained' color='primary' fullWidth size='small' onClick={handleClick}>{btnText}</Button>
                </Box>
            </Card>
        </Grid>
    )
}

export default ShowUpgradeOptions