import React, { useEffect, useState } from 'react'
import PaywallWrapper from '../PaywallWrapper';
import { useSelector } from 'react-redux';

const AclComponentWrapper = ({ children, componentAcl = null, payWallSize = "default", hideOnly = false, sx={} }) => {
    const [componentState, setComponentState] = useState("hide");
    const user = useSelector((state) => state.authDetails.user)
    const payWallLogicData = componentAcl?.payWallObject || {};
    const hideLogicData = componentAcl?.hideObject || {};

    useEffect(() => {
        if (componentAcl?.hide && hideLogicData?.status === true) {
            setComponentState("hide")
        }
        else if (componentAcl?.payWall && payWallLogicData.status === true && !hideOnly) {
            setComponentState("paywall")
        }
        else {
            setComponentState("show")
        }
    }, [user, componentAcl])

    if (componentState === "show") return children;
    else if (componentState === "paywall" && !hideOnly) return (
        <PaywallWrapper
            sxProps={sx}
            payWallLogicData={payWallLogicData}
            size={payWallSize}>
            {children}
        </PaywallWrapper>
    )
    else return null
}

export default AclComponentWrapper