import { configureStore, combineReducers } from "@reduxjs/toolkit"
import logger from "redux-logger"

import { clearAllDataFromLocalStorage } from "../utils"
import { resetReduxState, resetTenantState } from "./authSlice"

import authMiddleware from "./authSlice/middleware"
import liveChatMiddleware from "./liveChatSlice/middleware"
import pageMiddleware from "./userJourneySlice/middleware"

import pageReducer from "./pageSlice"
import authReducer from "./authSlice"
import dashboardReducer from "./dashboardSlice"
import overviewReducer from "./overviewSlice"
import liveChatReducer from "./liveChatSlice"
import chatHistorySlice from "./chatHistorySlice"
import feedbackReducer from "./feedbackSlice"
import leadCaptureReducer from "./leadCaptureSlice"
import trainingReducer from "./trainingSlice"
import channelSlice from "./channelSlice"
import integrationSlice from "./integrationSlice"
import templateSlice from "./templateSlice"
import WorkspaceSettingsSlice from "./WorkspaceSettingsSlice"
import BillingSlice from "./billingSlice"
import automatedLeadSlice from "./automatedLeadSlice"
import userJourneySlice from "./userJourneySlice"

const appReducer = combineReducers({
  pageDetails: pageReducer,
  authDetails: authReducer,
  dashboardDetails: dashboardReducer,
  overviewDetails: overviewReducer,
  liveChatDetails: liveChatReducer,
  chatHistoryDetails: chatHistorySlice,
  feedbackDetails: feedbackReducer,
  leadCaptureDetails: leadCaptureReducer,
  trainingDetails: trainingReducer,
  channelDetails: channelSlice,
  integrationDetails: integrationSlice,
  templateDetails: templateSlice,
  workspaceSettingsDetails: WorkspaceSettingsSlice,
  billingDetails: BillingSlice,
  leadCaptureEmailDetails : automatedLeadSlice,
  userJourneyDetails: userJourneySlice,
})

const rootReducer = (state, action) => {
  if (resetReduxState.match(action)) {
    clearAllDataFromLocalStorage()
    return appReducer(action.payload, action)
  } else if (resetTenantState.match(action)) {
    return appReducer(action.payload, action)
  }
  return appReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware().concat(
      authMiddleware,
      liveChatMiddleware,
      pageMiddleware
    )
    if (process.env.NODE_ENV !== "production")
      middleware = middleware.concat(logger)
    return middleware
  },
  devTools: process.env.NODE_ENV !== "production",
})

export default store
