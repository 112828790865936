import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import {
  SIGNUP_USER,
  LOGIN_USER,
  FORGOT_PASSWORD,
  LOGOUT_USER,
  RESET_PASSWORD,
  FETCH_MY_PROFILE,
  UPDATE_USER_DETAILS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_TOKEN,
  DELETE_USER_PROFILE,
  USER_QUOTA,
  FETCH_ACL,
  SEND_REFERRAL_EMAIL,
  GET_REFERRAL_DATA,
  UPDATE_USER_JOURNEY,
  UPDATE_USER_PREFERENCES,
} from "./api"

import { LOCAL_STORAGE, METHOD_TYPES } from "../../configs/constants"
import { setDataInLocalStorage } from "../../utils"
import { fetchDataForToolkit } from "../helpers"

import initialState from "./initialState"

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuthState(state, action) {
      return { ...state, ...action.payload }
    },
    updateJourneyPreferenceState(state, action) {
      return {
        ...state,
        user: {
          ...state.user,
          journeys: {
            ...state.user?.journeys,
            ...action.payload,
          },
        },
      }
    },
    updateUserPreferencesState(state, action) {
      return {
        ...state,
        user: {
          ...state.user,
          preferences: {
            ...state.user?.preferences,
            ...action.payload,
          }
        }
      }
    },
    trackUserEvent(state, action) {
      return {
        ...state,
        userTrackedEvents: [...state?.userTrackedEvents, action.payload]
      }
    },
    resetTenantState() {
      return
    },
    resetReduxState() {
      return
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data.token) {
          state.isLoggedIn = true
          state.token = payload.data.token
          state.userId = payload.data.userId
          setDataInLocalStorage(LOCAL_STORAGE.IS_LOGGED_IN, true)
          setDataInLocalStorage(LOCAL_STORAGE.TOKEN, payload.data.token)
          setDataInLocalStorage(LOCAL_STORAGE.USER_ID, payload.data.userId)
          setDataInLocalStorage(LOCAL_STORAGE.IS_OLD_USER, true)
        }
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.isLoggedIn = true
          state.token = payload.data.token
          state.userId = payload.data.userId
          setDataInLocalStorage(LOCAL_STORAGE.IS_LOGGED_IN, true)
          setDataInLocalStorage(LOCAL_STORAGE.TOKEN, payload.data.token)
          setDataInLocalStorage(LOCAL_STORAGE.USER_ID, payload.data.userId)
          setDataInLocalStorage(LOCAL_STORAGE.IS_OLD_USER, true)
        }
      })
      .addCase(fetchMyProfile.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.user = payload.data
          state.referralLink = payload.data.referralLink
        }
      })
      .addCase(updateUserDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.user = {
            ...payload.data,
            picture: `${payload.data.picture}?timestamp=${new Date().getTime()}`,
          }
        }
      })
      .addCase(getUserQuota.fulfilled, (state, { payload }) => {
        if (payload?.data?.userQuota) state.userQuota = payload?.data?.userQuota
      })
      .addCase(getACL.fulfilled, (state, { payload }) => {
        if (payload?.data) state.acl = payload?.data?.acl
      })
      .addCase(getReferralData.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.referredCount = payload?.data?.totalCount
          state.referralData = payload?.data?.referralData
          state.maxRewardCount = payload?.data?.maxRewardCount
          state.showReferralWarning = payload?.data?.showMaxReferWarning
          state.rewardedCount = payload?.data?.rewardedCount
        }
      })
      .addCase(updateUserJourneyPreferences.fulfilled, (state, { payload }) => {
        state.user = {
          ...state.user,
          journeys:{
            ...state.user?.journeys,
            ...payload.data.journeyUpdates
          }
        }
      })
      .addCase(updateUserPreferences.fulfilled, (state, {payload}) => {
        if(payload?.data) {
          state.user.preferences = payload?.data?.preferences
        }
      })
  },
})

export const signupUser = createAsyncThunk(
  "auth/signupUser",
  async (data = {}, helpers) =>{
    const referralEnabled = process.env.REACT_APP_REFERRAL_ENABLED;
    const params = new URLSearchParams(window.location.search);
    if(referralEnabled !== 'true') {
        params.delete('referralCode');
    }
    return fetchDataForToolkit(
      {
        url: SIGNUP_USER+"?"+params.toString(),
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
  }
)

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: LOGIN_USER,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
)

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: FORGOT_PASSWORD,
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
)

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: RESET_PASSWORD.replace(":token", data.token),
        method: METHOD_TYPES.POST,
        data,
        authLoader: true,
      },
      helpers
    )
)

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: LOGOUT_USER,
        data,
        loaderText: "Logging Out",
      },
      helpers
    )
)

export const fetchMyProfile = createAsyncThunk(
  "auth/fetchMyProfile",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: FETCH_MY_PROFILE,
        loaderText: "Fetching user data ...",
      },
      helpers
    )
)

export const updateUserDetails = createAsyncThunk(
  "auth/updateUserDetails",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_USER_DETAILS,
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        data,
      },
      helpers
    )
)

export const deleteUserProfile = createAsyncThunk(
  "auth/deleteUserProfile",
  async (data = {}, helpers) => fetchDataForToolkit(
    {
      url: DELETE_USER_PROFILE,
      method: METHOD_TYPES.DELETE,
      data
    },
    helpers
  )
)

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: VERIFY_EMAIL,
      },
      helpers
    )
)

export const verifyEmailToken = createAsyncThunk(
  "auth/verifyEmailToken",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: VERIFY_EMAIL_TOKEN,
        loader: false,
        data,
      },
      helpers
    )
)

export const getUserQuota = createAsyncThunk(
  "auth/getUserQuota",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: USER_QUOTA,
        loader: false
      },
      helpers
    )
)

export const getACL = createAsyncThunk(
  "auth/getACL",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: FETCH_ACL,
        loader: true,
        data
      },
      helpers
    )
)

export const getReferralData = createAsyncThunk(
  "auth/getReferralData",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_REFERRAL_DATA,
        loader: false,
        data: data
      },
      helpers
    )
)

export const sendReferralEmail = createAsyncThunk(
  "auth/sendReferralEmail",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SEND_REFERRAL_EMAIL,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)


export const updateUserJourneyPreferences = createAsyncThunk(
  "userJourney/updateUserJourneyPreferences",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_USER_JOURNEY,
        method: METHOD_TYPES.PATCH,
        data: data,
        loader: false,
      },
      helpers,
    ),
);

export const updateUserPreferences = createAsyncThunk(
  "auth/updateUserPreferences",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_USER_PREFERENCES,
        method: METHOD_TYPES.PUT,
        data,
        loader: false
      },
      helpers
    )
)


export const { updateAuthState, resetTenantState, resetReduxState, updateUserPreferencesState, trackUserEvent, updateJourneyPreferenceState  } =
  authSlice.actions
export default authSlice.reducer
