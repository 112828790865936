import { Suspense, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"

import { updatePageState } from "../../../../../data/store/pageSlice"
import { ACL_IDENTIFIERS, APP_PAGES } from "../../../../../data/configs/constants"
import {
  LiveChatEnabled,
  getLiveChatConfigurations,
  getMembers,
  updateLiveChatConfigurations,
  updateTenantInfo
} from "../../../../../data/store/overviewSlice"
import OperationalHourSettings from "../../../../components/OperationalHourSettings"
import Grid from "@mui/material/Grid"
import AclComponentWrapper from "../../../../components/AclComponentWrapper"
import { Alert, AlertTitle, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField } from "@mui/material"
import ErrorBoundary from "../../../../components/ErrorBoundary"
import LiveChatDisabled from "../../LiveChats/LiveChatDisabled"
import SpinnerLoader from "../../../../components/SpinnerLoader"
import StyledSwitch from "../../../../components/StyledSwitch"
import { updateLiveChatState } from "../../../../../data/store/liveChatSlice"
import liveChatInitialState from "../../../../../data/store/liveChatSlice/initialState"
import { StyledButton } from "../../../../components/StyledButton"
import {getDenyList, updateDenyList} from "../../../../../data/store/templateSlice";

const LIVE_CHAT_PSEUDO_NAME = "liveChat"

const HumanAssistSettings = () => {
  const { tenantId } = useParams()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const tenantInfo = useSelector((state) => state.overviewDetails.tenantInfo)
  const currentPageAcl = useSelector((state) => state.pageDetails?.currentPageAcl);
  const liveChatConfigurations = useSelector(
    (state) => state.overviewDetails.liveChatConfigurations
  )
  const isLiveChatsEnabled = useSelector(
    (state) =>
      state.overviewDetails?.tenantInfo?.featureControl?.liveChat?.active
  )
  const [negationInputText, setNegationInputText] = useState("")
    const denyList = useSelector((state) => state.templateDetails.denyList)
    const negationList = denyList?.[LIVE_CHAT_PSEUDO_NAME]?.denyList || []

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.TENANT_HUMAN_ASSIST_SETTINGS,
        currentSiderModule: APP_PAGES.TENANT_HUMAN_ASSIST_SETTINGS,
        showTenantHeader: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getMembers({
        tenantId,
      })
    )
    dispatch(getLiveChatConfigurations({ tenantId }))
  }, [dispatch, tenantId])

  const handleUpdateOperationalHour = (changedValues) => {
    const payload = {
      ...tenantInfo,
      ...changedValues,
    }
    dispatch(updateTenantInfo(payload))
  }

  const handleEmailNotification = (e) => {
    dispatch(
      updateLiveChatConfigurations({
        tenantId,
        enableEmailNotify: e.target.checked,
      })
    )
      .unwrap()
      .then((res) => {
        enqueueSnackbar(
          res?.message ? res?.message : "Updated Agent Email notification "
        )
      })
  }

  const handleUserConfirmation = (e) => {
    const payload = {
      ...tenantInfo,
      userConfirmationNeeded: e.target.checked,
    }
    dispatch(updateTenantInfo(payload))
  }

  const handleLiveChatOnlySwitch = (e) => {
    dispatch(updateTenantInfo({
      ...tenantInfo,
      liveChatOnly: e.target.checked
    }))
      .unwrap()
      .then((res) => {
        enqueueSnackbar(
          res?.message ? res?.message : "Tenant Live Chat Settings Updated", {
          variant: 'success'
        }
        )
      })
  }

  const handleLiveChats = () => {
    dispatch(
      LiveChatEnabled({
        tenantId,
        status: false,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(updateLiveChatState(liveChatInitialState))
      })
  }

    const updateNegationList = (newList) => {
        dispatch(updateDenyList({
                    tenantId: tenantId,
                    feature: LIVE_CHAT_PSEUDO_NAME,
                    payload: {
                        ...denyList[LIVE_CHAT_PSEUDO_NAME],
                        denyList: newList
                    }
                }
            )
        )
    }

    useEffect(() => {
        dispatch(getDenyList({
            feature: LIVE_CHAT_PSEUDO_NAME,
            tenantId: tenantId
        }))
    }, [dispatch, tenantId]);

  if (!isLiveChatsEnabled) return (
    <ErrorBoundary>
      <Suspense fallback={<SpinnerLoader />}>
        <LiveChatDisabled
          tenantId={tenantInfo.tenantId}
          active={isLiveChatsEnabled}
        />
      </Suspense>
    </ErrorBoundary>
  )

  return (
    <Box>
      <Grid container rowSpacing={1} justifyContent={'space-between'} padding={1}>
        <Grid item md={12} xs={12}>
          <AclComponentWrapper payWallSize="small" componentAcl={currentPageAcl?.[ACL_IDENTIFIERS.LIVE_CHAT_CONFIGURATION]}>
              <Typography variant={"h6"} mb={1}>
                Live Chat Configuration
              </Typography>
              <OperationalHourSettings
                data={tenantInfo?.featureControl?.liveChat?.operationalHourSettings}
                onChange={handleUpdateOperationalHour}
              />
            <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default', boxShadow: 0 }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="subtitle1" fontWeight="fontWeightMedium">Ask for user confirmation</Typography>
                <StyledSwitch
                  sx={{ ml: 1 }}
                  size="small"
                  checked={tenantInfo?.featureControl?.liveChat?.userConfirmationNeeded || false}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={handleUserConfirmation}
                />
              </Stack>
              <Typography variant="caption" color="text.secondary" display="flex">
                Note: If this mode is enabled, your smart chat-bot will ask the user for confirmation before transferring to a live representative.
              </Typography>
            </Paper>
              {liveChatConfigurations?.enableEmailNotify !== undefined && (
                <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default', boxShadow: 0 }}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant="subtitle1" fontWeight="fontWeightMedium">Email Notification</Typography>
                    <StyledSwitch
                      sx={{ ml: 1 }}
                      size="small"
                      checked={liveChatConfigurations?.enableEmailNotify}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={handleEmailNotification}
                    />
                  </Stack>
                  <Typography variant="caption" color="text.secondary" display="flex">
                    Note: Agent can change getting email notification during any busy
                    state.
                  </Typography>
                </Paper>
              )}
          </AclComponentWrapper>
          <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default', boxShadow: 0 }}>
            <Typography variant="subtitle1" fontWeight="fontWeightMedium">Ignore list</Typography>
            <Typography variant="body2" color="text.secondary" display="flex" marginBottom={2}>
              Add messages to ignore from live chat transfer triggers.
            </Typography>
            <TextField
              size="small"
              placeholder="Enter message to negate"
              value={negationInputText}
              fullWidth
              onChange={(e) => setNegationInputText(e.target.value)}
              onKeyDown={(e) => {
                  if (e.key === "Enter") {
                      updateNegationList([...negationList, negationInputText])
                      setNegationInputText("")
                  }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={() => {
                        updateNegationList([...negationList, negationInputText])
                        setNegationInputText("")
                      }}
                    >
                      <i class="ri-add-line"></i>
                    </IconButton>
                  </InputAdornment>
                ),
              }} />
            {negationList.length > 0 ? (
              <Grid container spacing={1} marginTop={1}>
                {negationList.map((negation, index) => (
                  <Grid item>
                    <Chip
                      key={index}
                      label={negation}
                      onDelete={() => {
                        updateNegationList(negationList.filter((negationItem) => negationItem !== negation))
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body2" textAlign={'center'} color="textSecondary" marginTop={1}>
                No messages added
              </Typography>
            )}
          </Paper>
          <Alert severity="warning" icon={false} action={<StyledSwitch
            size="small"
            checked={tenantInfo?.featureControl?.liveChat?.liveChatOnly || false}
            inputProps={{ "aria-label": "controlled" }}
            onChange={handleLiveChatOnlySwitch}
          />} sx={{ mb: 2 }}>
            <AlertTitle>Disable AI</AlertTitle>
            <Typography color="text.secondary" display="flex">
              Note: Please be aware that by taking this action, all AI responses will be disabled,
              and the completion of chats will require the assistance of live agents.
            </Typography>
          </Alert>
          <Alert severity="error" icon={false} action={    <StyledButton size="small" color="error" variant="outlined" onClick={() => {
                setOpen(true)
              }}>
                Disable
              </StyledButton>}>
            <AlertTitle>Disable live chat</AlertTitle>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'start'}>
              <Typography color="text.secondary" display="flex" flex={1}>
                Note: Please be aware that by taking this action, all live chat functionality will be disabled. You can re-enable it at any time.
              </Typography>
            </Stack>
          </Alert>
          <Dialog open={open} onClose={() => {
            setOpen(false)
          }}>
            <DialogTitle>
              <Typography variant="h6">Disable live chat</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to disable live chat on your bot?</Typography>
            </DialogContent>
            <DialogActions>
              <StyledButton color="error" onClick={() => {
                setOpen(false)
              }}>Cancel</StyledButton>
              <StyledButton color="primary" variant="contained" onClick={() => {
                handleLiveChats()
                setOpen(false)
              }}>Disable</StyledButton>
            </DialogActions>
          </Dialog>
        </Grid>
    </Grid>
    </Box>
  )
}

export default HumanAssistSettings
