export const GET_ZAPIER_INTEGRATIONS_LIST = "/bot/v2/integrations/zapier/list"
export const ZAPIER_INTEGRATION = "/bot/v2/integrations/zapier"
export const SEND_DATA_TO_ZAPIER_INDIVIDUALLY =
  "/bot/v2/integrations/zapier/sendAll"
export const SEND_DATA_TO_ALL_ZAPIER_SERVICES =
  "/bot/v2/integrations/zapier/syncLeads"
export const GET_WEBHOOK_INFO = "bot/v2/integrations/webhook/link"
export const SYNCS_LEAD_FORMS = "bot/v2/integrations/webhook/syncLeads"
export const GET_HUBSPOT_INFO = "bot/v2/integrations/hubspot/status"
export const CONNECT_HUBSPOT = "bot/v2/integrations/hubspot/connect"
export const DISCONNECT_HUBSPOT = "bot/v2/integrations/hubspot/disconnect"
export const UPDATE_HUBSPOT = "bot/v2/integrations/hubspot/update/mapping"
export const GET_GOOGLESHEETS_INFO = "bot/v2/integrations/google-sheets/status"
export const GET_SALESFORCE_INFO = "bot/v2/integrations/salesforce/status"
export const CONNECT_GOOGLESHEETS = "bot/v2/integrations/google-sheets/connect"
export const CONNECT_SALESFORCE = "bot/v2/integrations/salesforce/connect"
export const UPDATE_SALESFORCE = "bot/v2/integrations/salesforce/update/mapping"
export const DISCONNECT_SALESFORCE = "bot/v2/integrations/salesforce/disconnect"
export const DISCONNECT_GOOGLESHEETS = "bot/v2/integrations/google-sheets/disconnect"
export const UPDATE_GOOGLESHEETS = "bot/v2/integrations/google-sheets/update/mapping"
export const UPDATE_INTEGRATION_STATUS = "bot/v2/integrations/general/status"
export const GET_INTEGRATION_STATUS = "bot/v2/integrations/general/status"