import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState"
import { ADD_UPSTREAM, CONFIG, EMAIL_CONFIGURATION, GET_ALL_UPSTREAM_LIST, GET_USER_UPSTREAM_LIST, WORKSPACE, REFRESH_DNS_STATUS, UPLOAD_WORKSPACE_IMG } from "./api";
import { LOCAL_STORAGE, WORKSPACE_UPSTREAM_STATUS } from "../../configs/constants";
import { fetchDataForToolkit } from "../helpers";
import { setDataInLocalStorage } from "../../utils";

const workspaceSettingsSlice = createSlice
    ({
        name: "workspaceSettings",
        initialState,
        reducers: {
            updateWorkspaceSettingsState(state, action) {
                return { ...state, ...action.payload };
            },
            resetWorkspaceSettingsState() {
                return initialState;
            },
        },
        extraReducers: (builder) => {
            builder.addCase(initializeDomainSetting.fulfilled, (state, action) => {
                state.allUpstreamList = action.payload.allUpstreamList;
                state.userUpstreamList = action.payload.userUpstreamList;
                updateUpstreamStatus(state)
            })
                .addCase(getUserUpstreamList.fulfilled, (state, action) => {
                    state.userUpstreamList = action.payload.data.dnsMappings;
                    updateUpstreamStatus(state)
                })
                .addCase(createNewUserDNSMapping.fulfilled, (state, action) => {
                    state.userUpstreamList = action.payload.data.dnsMappings;
                    updateUpstreamStatus(state)
                })
                .addCase(refreshDNSStatus.fulfilled, (state, action) => {
                    state.userUpstreamList = action.payload.data.dnsMappings;
                    state.userUpstreamList.forEach((item) => {
                        state.allUpstreamStatus[item.label] = { ...item, feStatus: WORKSPACE_UPSTREAM_STATUS.ADDED }
                    })
                })
                .addCase(removeDnsMapping.fulfilled, (state, action) => {
                    state.userUpstreamList = action.payload.data.dnsMappings;
                    updateUpstreamStatus(state)
                })
                .addCase(getUserWorkspaceConfiguration.fulfilled, (state, action) => {
                    state.configData = action.payload.data;
                    state.themeConfigInputData = {
                        ...state.themeConfigInputData,
                        ...action.payload.data?.branding?.theme
                    };
                    const branding = action.payload.data?.branding;
                    state.infoConfigInputData = {
                        ...state.infoConfigInputData,
                        organizationLabel: branding?.organizationLabel,
                        logoUrlLarge: branding?.logoUrlLarge,
                        logoUrlSmall: branding?.logoUrlSmall
                    }
                    state.loginScreenConfigInputData = {
                        ...state.loginScreenConfigInputData,
                        ...branding?.loginAppearance,
                    }
                })
                .addCase(updateEmailConfig.fulfilled, (state, action) => {
                    state.emailConfigInputData.loading = false;
                    state.emailConfigInputData.error = "";
                    state.emailConfigInputData = initialState.emailConfigInputData;
                    state.configData.branding = {
                        ...state.configData.branding,
                        ...action.payload?.data?.branding
                    };
                })
                .addCase(refreshEmailConfig.fulfilled, (state, action) => {
                    state.emailConfigInputData.loading = false;
                    state.emailConfigInputData.error = "";
                    const domainConfig = state.configData.branding.emailConfig?.domainConfig;
                    Object.keys(domainConfig)?.forEach((key) => {
                        domainConfig[key] = {
                            ...domainConfig[key],
                            valid: action.payload?.data?.domainConfig?.[key]?.valid
                        }
                    })
                    state.configData.branding.emailConfig = {
                        ...state.configData.branding.emailConfig,
                        isDomainVerified: action.payload?.data?.isDomainVerified,
                        domainConfig
                    };  
                })
                .addCase(deleteEmailConfig.fulfilled, (state, action) => {
                    state.emailConfigInputData.loading = false;
                    state.emailConfigInputData.error = "";
                    state.emailConfigInputData = initialState.emailConfigInputData;
                    state.configData.branding.emailConfig = {};
                })
                .addCase(uploadWorkspaceImg.fulfilled, (state, action) => {
                    state.emailConfigInputData.error = "";
                })
                .addCase(updateWorkspaceConfig.fulfilled, (state, action) => {
                    state.configData = action.payload.data;
                })
                .addCase(getDomainConfig.fulfilled, (state, action) => {
                    state.domainConfig = action.payload?.data;
                    setDataInLocalStorage(LOCAL_STORAGE.DOMAIN_CONFIG, action.payload?.data)
                })
        }
    })

const updateUpstreamStatus = (state) => {
    const userUpstreamList = state.userUpstreamList;
    const notAddedUpstreams = state.allUpstreamList.filter((upstream) => {
        return !userUpstreamList.some((userUpstream) => userUpstream.label === upstream.label);
    });
    notAddedUpstreams.forEach((item) => {
        state.allUpstreamStatus[item.label] = { ...item, feStatus: WORKSPACE_UPSTREAM_STATUS.NOT_ADDED };
    });
    userUpstreamList.forEach((item) => {
        state.allUpstreamStatus[item.label] = { ...item, feStatus: WORKSPACE_UPSTREAM_STATUS.ADDED };
    });
};


export const initializeDomainSetting = createAsyncThunk("workspaceSettings/initializeDomainUpstreams", async (data = {}, helpers) => {
    const result = {};
    try {
        const allUpstreamData = await fetchDataForToolkit({
            url: GET_ALL_UPSTREAM_LIST,
            method: "GET",
            data: {},
            loader: true,
        }, helpers)
        const allUserUpstreamData = await fetchDataForToolkit({
            url: GET_USER_UPSTREAM_LIST,
            method: "GET",
            data: {},
            loader: true,
        }, helpers)
        result.allUpstreamList = allUpstreamData.data.upstreams;
        result.userUpstreamList = allUserUpstreamData.data.dnsMappings;
        return result;
    } catch (err) {
        throw err;
    }

});
export const getUserUpstreamList = createAsyncThunk("workspaceSettings/getUserUpstreamList", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: GET_USER_UPSTREAM_LIST,
        method: "GET",
        data: {},
        loader: false,
    }, helpers)
});
export const createNewUserDNSMapping = createAsyncThunk("workspaceSettings/createNewUserDNSMapping", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: ADD_UPSTREAM,
        method: "POST",
        data: data,
        loader: false,
    }, helpers)
});
export const refreshDNSStatus = createAsyncThunk("workspaceSettings/refreshDNSStatus", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: REFRESH_DNS_STATUS.replace(":domain_uuid", data?.domain_uuid),
        method: "GET",
        data: {
            upstream_uuid: data?.upstream_uuid,
            host: data?.host,
            upstream: data?.upstream
        },
        loader: false,
    }, helpers)
});
export const removeDnsMapping = createAsyncThunk("workspaceSettings/removeDnsMapping", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: REFRESH_DNS_STATUS.replace(":domain_uuid", data?.domain_uuid) + `?upstream_uuid=${data?.upstream_uuid}&host=${data?.host}&upstream=${data?.upstream}`,
        method: "DELETE",
        data: {},
        loader: false,
    }, helpers)
});
export const getUserWorkspaceConfiguration = createAsyncThunk("workspaceSettings/getUserWorkspaceConfiguration", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: CONFIG,
        method: "GET",
        data: {},
        loader: true,
    }, helpers)
});
export const updateEmailConfig = createAsyncThunk("workspaceSettings/updateEmailConfig", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: EMAIL_CONFIGURATION,
        method: "PUT",
        data: data,
        loader: false,
    }, helpers)
});
export const refreshEmailConfig = createAsyncThunk("workspaceSettings/refreshEmailConfig", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: EMAIL_CONFIGURATION,
        method: "GET",
        data: {},
        loader: false,
    }, helpers)
});
export const deleteEmailConfig = createAsyncThunk("workspaceSettings/deleteEmailConfig", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: EMAIL_CONFIGURATION,
        method: "DELETE",
        data: {},
        loader: false,
    }, helpers)
});
export const uploadWorkspaceImg = createAsyncThunk("workspaceSettings/uploadWorkspaceImg", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: UPLOAD_WORKSPACE_IMG,
        method: "POST",
        data: data,
        loader: false,
        contentType: "multipart/form-data"
    }, helpers)
});
export const updateWorkspaceConfig = createAsyncThunk("workspaceSettings/updateWorkspaceConfig", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: CONFIG,
        method: "PUT",
        data: data,
        loader: false,
    }, helpers)
});
export const getDomainConfig = createAsyncThunk("workspaceSettings/getDomainConfig", async (data = {}, helpers) => {
    return fetchDataForToolkit({
        url: WORKSPACE,
        method: "GET",
        data: {},
        loader: false,
    }, helpers)
});


export const {
    updateWorkspaceSettingsState,
    resetWorkspaceSettingsState,
} = workspaceSettingsSlice.actions
export default workspaceSettingsSlice.reducer