import dayjs from "dayjs"

import { LIVE_CHAT_TABS } from "../../configs/constants"

const initialState = {
  isSocketConnected: false,
  socketRequestProcessing: false,
  pageJoining: false,
  selectedChatListTab: LIVE_CHAT_TABS.ALL_CHATS,
  liveChats: {},
  unseenLiveChatCount: 0,
  botChatPsids: [],
  inQueuePsids: [],
  myChatPsids: [],
  otherAgentChatPsids: [],
  allChats: [],
  allChatsLoading: false,
  selectedDateFilter: {
    endDate: dayjs().format(),
    startDate: dayjs().subtract(7, "days").format(),
  },
  lastScannedTicketId: "",
  selectedChats: {},
  selectedChatId: null,
  unreadMessageCountMap: {},
  hasMore: false
}

export default initialState
