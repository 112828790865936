import dayjs from "dayjs"

const initialState = {
    leadCaptureList: [],
    columns: [],
    totalCount: 0,
    endDate: dayjs().format(),
    startDate: dayjs().subtract(7, "days").format(),
    dynamicLeadList: [],
  }
  
  export default initialState