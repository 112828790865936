const initialState = {
  selectedChannel: "",
  facebookPages: [],
  telegramBotInfo: [],
  channelsStatus: {},
  instagramPages: [],
  integratedWhatsappList: [],
  connectionInfo: {},
  gbm:{
    integrationData:[],
    addedLocations: [],
    verifiedLocations: [],
    nonLocalRegions: [],
  },
  discord:{
    bots: [],
  },
  zapier: {
    apiData: {},
    data: []
  },
  pabbly: {
    apiData: {},
    data: []
  },
  msteams:{
    data: []
  },
  slack:{
    integrationData:[],
  }
}

export default initialState
