export const SIGNUP_USER = "/iam/v2/users/signup"
export const LOGIN_USER = "/iam/v2/users/signin"
export const LOGOUT_USER = "/iam/v2/users/logout"
export const FORGOT_PASSWORD = "/iam/v2/users/forgot-password"
export const RESET_PASSWORD = "/iam/v2/users/reset-password/:token"
export const FETCH_MY_PROFILE = "/iam/v2/users/profile"
export const UPDATE_USER_DETAILS = "/iam/v2/users/profile/update"
export const DELETE_USER_PROFILE = "/iam/v2/users/profile/delete"
export const VERIFY_EMAIL = "/iam/v2/users/resend-verification"
export const VERIFY_EMAIL_TOKEN = "/iam/v2/users/verify-email"
export const USER_QUOTA = "/brain/v1/admin/analytics/user/static/quota"
export const FETCH_ACL = "/iam/policy/acl"
export const SEND_REFERRAL_EMAIL = "/iam/v2/users/signup-referral/invite"
export const SET_REFERRER_DATA = "/iam/v2/users/signup-referral"
export const GET_REFERRAL_DATA = "/iam/v2/users/referral-data"
export const UPDATE_USER_JOURNEY = "/iam/v2/users/profile/journeys";
export const UPDATE_USER_PREFERENCES = "/iam/v2/users/profile/preferences"
