import SpinnerLoader from "../SpinnerLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { connectHubspot } from "../../../data/store/integrationSlice";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { getHubspotInfo } from "../../../data/store/integrationSlice";
import { ROUTE_PATH } from "../../../data/configs/constants";
const HubspotAuthentication = () => {
  const dispatch = useDispatch();
  const url = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(url.search);
  const code = param.get("code");
  const storedTenantId = localStorage.getItem("HUBSPOT_TENANT_ID");
  const tenantId = JSON.parse(storedTenantId);

  useEffect(() => {
    dispatch(connectHubspot({ tenantId, code }))
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          enqueueSnackbar("Connected",{variant: "success"});
        }
        const url = ROUTE_PATH.HUBSPOT.replace(":tenantId",tenantId);
        localStorage.removeItem("HUBSPOT_TENANT_ID");
        let count = 0;
        let myInterval = setInterval(() => {
          dispatch(getHubspotInfo({ tenantId }))
            .unwrap()
            .then((res) => {
              if (res.data?.hubspot) clearInterval(myInterval);
              count++;
              if (count === 3) clearInterval(myInterval);
            });
        }, 2000);
        navigate(url);
      });
  }, []);

  return <SpinnerLoader />;
};
export default HubspotAuthentication;
