import axios from "axios"
import { enqueueSnackbar } from "notistack"

import { getDataFromLocalStorage, log, setDataInLocalStorage } from "../utils"
import { updatePageState } from "./pageSlice"
import { LOCAL_STORAGE, METHOD_TYPES } from "../configs/constants"
import { updateAuthState } from "./authSlice"

const getHeaders = (contentType, token) => {
  let headers = { 
    "Content-Type": contentType,
    "x-served-for": window?.location?.host,
  }
  if (token) headers.Authorization = `bearer ${token}`
  return headers
}

export const history = {
  navigate: null,
  location: null,
}

const debouncedChangeLoaderFn = (dispatch, modalLoader, authLoader, loader, boolValue, txtValue) => {
  if (modalLoader) dispatch(updatePageState({ modalLoading: boolValue }))
  else if (authLoader) dispatch(updatePageState({ authLoading: boolValue }))
  else if (loader)
    dispatch(updatePageState({ pageLoading: boolValue, pageLoaderText: txtValue }))
}

export const fetchDataForToolkit = async (
  {
    url,
    method = METHOD_TYPES.GET,
    data = {},
    timeout = 20000,
    authLoader = false,
    modalLoader = false,
    loader = true,
    loaderText = "loading",
    contentType = "application/json",
    responseType,
    baseURL = process.env.REACT_APP_BASE_URL,
    axiosArgs = {},
  },
  { rejectWithValue, dispatch, getState }
) => {
  let timerRef
  let hasLoaderStarted = false
  try {
    // timeout to start loader
    timerRef = setTimeout(() => {
      debouncedChangeLoaderFn(dispatch, modalLoader, authLoader, loader, true, loaderText)
      hasLoaderStarted = true
    }, 500)
    const response = await axios({
      url,
      baseURL,
      method,
      timeout,
      responseType,
      headers: getHeaders(contentType, getState().authDetails.token),
      params: method === METHOD_TYPES.GET ? data : {},
      data: method !== METHOD_TYPES.GET ? data : {},
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 412,
      ...axiosArgs,
    })
    const updateUserToken = response.data?.data?.updateUserToken
    if(updateUserToken && updateUserToken?.updated) {
      setDataInLocalStorage(LOCAL_STORAGE.TOKEN, updateUserToken?.token)
      dispatch(updateAuthState({ token: updateUserToken?.token }))
    }
    return response.data
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue({
      name: err.name,
      data: err.response.data,
      status: err.response.status,
    })
  } finally {
    // clearing loader timeout
    clearTimeout(timerRef)
    // stop loader only if loader started and stop after 500ms to prevent flickering
    if (hasLoaderStarted) {
      debouncedChangeLoaderFn(dispatch, modalLoader, authLoader, loader, false, "")
    }
  }
}

export const fetchDataAndProceed = (
  { dispatch, getState },
  {
    url,
    method = METHOD_TYPES.GET,
    data = {},
    timeout = 20000,
    modalLoader = false,
    loader = true,
    loaderText = "loading",
    contentType = "application/json",
    baseURL = process.env.REACT_APP_BASE_URL,
    responseType,
    axios_args = {},
  } = {},
  callback
) => {
  let token = ""
  if (getState) token = getState().authDetails.token
  else token = getDataFromLocalStorage(LOCAL_STORAGE.TOKEN, null)

  if (dispatch)
    if (modalLoader) dispatch(updatePageState({ modalLoading: true }))
    else if (loader)
      dispatch(
        updatePageState({ pageLoading: true, pageLoaderText: loaderText })
      )

  axios({
    url,
    baseURL,
    method,
    timeout,
    responseType,
    params: method === METHOD_TYPES.GET ? data : {},
    data: method !== METHOD_TYPES.GET ? data : {},
    headers: getHeaders(contentType, token),
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 412,
    ...axios_args,
  })
    .then((response) => {
      log(`${url} [${method}] RESPONSE: `, response.data)

      callback(false, response.data)
      if (dispatch)
        if (modalLoader) dispatch(updatePageState({ modalLoading: false }))
        else if (loader)
          dispatch(updatePageState({ pageLoading: false, pageLoaderText: "" }))
    })
    .catch((error) => {
      log(`${url} [${method}] ERROR: `, error.response)
      if (error?.response?.data?.message || error?.response?.data?.data?.error) {
        enqueueSnackbar(error?.response?.data?.data?.error || error.response?.data?.message, { variant: "error" })
      }
      callback(true)
      if (dispatch)
        if (modalLoader) dispatch(updatePageState({ modalLoading: false }))
        else if (loader)
          dispatch(updatePageState({ pageLoading: false, pageLoaderText: "" }))
    })
}
