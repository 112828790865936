import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"

import { useDispatch } from "react-redux"

import { updateAuthState } from "../../../data/store/authSlice"
import { LOCAL_STORAGE, ROUTE_PATH } from "../../../data/configs/constants"
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../../data/utils"

import SpinnerLoader from "../SpinnerLoader"

const GoogleAuthentication = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has("token") && queryParams.has("userId")) {
      const token = queryParams.get("token")
      const userId = queryParams.get("userId")
      const isLoggedIn = true
      dispatch(updateAuthState({ token, userId, isLoggedIn }))
      setDataInLocalStorage(LOCAL_STORAGE.TOKEN, token)
      setDataInLocalStorage(LOCAL_STORAGE.USER_ID, userId)
      setDataInLocalStorage(LOCAL_STORAGE.IS_LOGGED_IN, isLoggedIn)
      setDataInLocalStorage(LOCAL_STORAGE.IS_OLD_USER, true)
      navigate(
        getDataFromLocalStorage(LOCAL_STORAGE.LAST_ROUTE, ROUTE_PATH.DASHBOARD)
      )
      localStorage.removeItem(LOCAL_STORAGE.LAST_ROUTE)
    } else {
      enqueueSnackbar("Missing auth token", { variant: "error" })
      navigate(ROUTE_PATH.LOGIN)
    }
  }, [dispatch, navigate])

  return <SpinnerLoader brand />
}

export default GoogleAuthentication
