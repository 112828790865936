const initialState = {
  pricingTableData: {},
  loading: false,
  loadError: false,
  planGuideSteps : [],
  planDetail : {},
  planGuideStep : 1,
  planGuideAnswers : {},
  planGuideCustomField : {},
  showFinalPlanFlag : true,
}

export default initialState
