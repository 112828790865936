import BOT_IMAGE from './img/bot_widget_fancy.svg'
import LIVE_CHAT from './img/live_chat.svg'
import USER_PROFILE from './img/bot_user_profile.svg'
import ERROR_IMG from './img/error.svg'
import EMPTY_IMG from './img/empty.svg'
import WARNING_IMG from './img/warning.svg'
import ACCESS_DENIED_IMG from './img/access_denied.svg'
import UPSTREAM_STATUS_NOT_LIVE from './img/upstreamStatusNotLive.svg'
import UPSTREAM_STATUS_LIVE from './img/upstreamStatusLive.svg'
import OPINION_IMG from './img/undraw_opinion_re_jix4.svg'
import EMAIL_CONFIG_UNVERIFIED from './img/emailConfigUnverified.svg'
import EMAIL_CONFIG_VERIFIED from './img/emailConfigVerified.svg'
import WL_LOGIN_BG from './img/dashboardLoginBg.png'
import DISCORD from './img/discord.svg'
import ECOMMERCE from './img/ecommerce.svg'
import GENERAL_BOT from './img/general_bot.svg';
import ONBOARDING from './img/onboarding.svg';
import CREATE_BOT from './img/createBot.svg';
import LEAD_QUAIFICATION from './img/leadQualification.svg';
import STATIC_LEADS from './img/staticLeads.svg';
import DYNAMIC_LEADS from './img/dynamicLeads.svg'
import SAD_CIRCLE from './img/sad_circle.svg'
import MOTIVATION from './img/motivation.svg'
import GRID_BG from './img/gridBg.svg'
import LOGO_SVG from './img/logo.svg'
import SEMI_CIRCLE from './img/semiCircle.svg'
import YT_LOGO from './img/ytLogo.svg'
import SONY_LOGO from './img/sonyLogo.svg'
import SLACK_LOGO from './img/slackLogo.svg'

import SERVICE_MAINTENANCE from './img/serviceMaintenance.svg'
export const IMG_LOGO_DARK = require("./img/logoDark.png")
export const IMG_LOGO_LIGHT = require("./img/logoLight.png")
export const IMG_LOGO_FULL_DARK = require("./img/logoFullDark.webp")
export const IMG_LOGO_FULL_LIGHT = require("./img/logoFullLight.webp")
export const IMG_LOGO_FULL_COLOR_DARK = require("./img/logoFullColorDark.png")
export const IMG_LOGO_FULL_COLOR_LIGHT = require("./img/logoFullColorLight.png")

export const IMG_BOT_LIGHT = require("./img/botLight.png")
export const IMG_BOT_DARK = require("./img/botDark.png")
export const IMG_OPINION = OPINION_IMG
export const IMG_G2_LOGO = require("./img/g2Logo.png")
export const IMG_404 = require("./img/404.png")
export const IMG_ERROR = ERROR_IMG
export const IMG_EMPTY = EMPTY_IMG
export const IMG_BOT_WIDGET_FANCY = BOT_IMAGE
export const IMG_BOT_CONVERSATION = LIVE_CHAT
export const IMG_PROFILE_DETAILS = USER_PROFILE
export const IMG_WARNING = WARNING_IMG
export const IMG_UPSTREAM_STATUS_NOT_LIVE = UPSTREAM_STATUS_NOT_LIVE
export const IMG_UPSTREAM_STATUS_LIVE = UPSTREAM_STATUS_LIVE
export const IMG_SERVICE_MAINTENANCE = SERVICE_MAINTENANCE
export const IMG_EMAIL_CONFIG_UNVERIFIED = EMAIL_CONFIG_UNVERIFIED
export const IMG_EMAIL_CONFIG_VERIFIED = EMAIL_CONFIG_VERIFIED
export const IMG_ACCESS_DENIED = ACCESS_DENIED_IMG
export const IMG_BOT_USER = require("./img/botUser.png")
export const IMG_WL_LOGIN_BG = WL_LOGIN_BG
export const IMG_DISCORD = DISCORD
export const IMG_ECOMMERCE = ECOMMERCE
export const IMG_GENERAL_BOT = GENERAL_BOT
export const IMG_ONBOARDING = ONBOARDING
export const IMG_CREATE_BOT = CREATE_BOT
export const IMG_LEAD_QUALIFICATION = LEAD_QUAIFICATION
export const IMG_STATIC_LEADS = STATIC_LEADS
export const IMG_DYNAMIC_LEADS = DYNAMIC_LEADS
export const IMG_MOTIVATION = MOTIVATION
export const IMG_GRID_BG = GRID_BG
export const IMG_LOGO_SVG = LOGO_SVG
export const IMG_SEMI_CIRCLE = SEMI_CIRCLE
export const IMG_YT_LOGO = YT_LOGO
export const IMG_SONY_LOGO = SONY_LOGO
export const IMG_SLACK_LOGO = SLACK_LOGO

export const FLAG_AND_TRAIN = require("./video/flagandtrain.gif") //rename
export const IMG_BOT_AVATAR_1 =
  "https://orimon-public-assets.s3.ap-south-1.amazonaws.com/avatars/1.png"
export const IMG_BOT_AVATAR_2 =
  "https://orimon-public-assets.s3.ap-south-1.amazonaws.com/avatars/2.png"
export const IMG_BOT_AVATAR_3 =
  "https://orimon-public-assets.s3.ap-south-1.amazonaws.com/avatars/3.png"

export const IMG_ENGAGEMENT = require("./img/engagement.webp")
export const IMG_GROWTH = require("./img/growth.webp")
export const IMG_MULTI_LINGUAL = require("./img/multiLingual.webp")

export const IMG_SALES_DRIVEN_CHATBOT = require("./img/salesDriveChatbot.png")
export const IMG_CONVERSATIONAL_EXCELLENCE = require("./img/convExcellence.png")
export const IMG_PERSONALIZED_SALES = require("./img/personalizedSales.png")
export const IMG_EASY_CHATBOT_CREATION = require("./img/createChatbot.png")
export const IMG_SEAMLESS_INTEGRATION = require("./img/integration.png")
export const IMG_LIVE_CHAT_SUPPORT = require("./img/liveChat.png")

export const IMG_WORDPRESS = require("./img/wordpress.png")
export const IMG_SOCIAL_MEDIA = require("./img/social-media.png")
export const IMG_FACEBOOK = require("./img/facebook.png")
export const IMG_INSTAGRAM = require("./img/instagram.png")
export const IMG_TELEGRAM = require("./img/telegram.png")
export const IMG_WHATSAPP = require("./img/whatsapp.png")
export const IMG_ZAPIER = require("./img/zapier.png")
export const IMG_WEBHOOK = require("./img/webhook.png")
export const IMG_GBM = require("./img/gbm.png")
export const IMG_WEBFLOW = require("./img/webflow.png")
export const IMG_SLACK = require("./img/slack.png")
export const IMG_SLACK_HINT1 = require("./img/slackHint1.jpg")
export const IMG_SLACK_HINT3 = require("./img/slackHint3.jpg")
export const IMG_SLACK_HINT2 = require("./img/slackHint2.jpg")
export const IMG_SLACK_HINT4 = require("./img/slackHint4.jpg")
export const IMG_SLACK_HINT5 = require("./img/slackhint5.jpg")
export const IMG_SHOPIFY = require("./img/shopify.png")
export const IMG_WOO_COMMERCE = require("./img/WooCommerce.png")
export const IMG_HUBSPOT = require("./img/hubspot.png")
export const IMG_SALESFORCE =  require("./img/salesforce.png")
export const IMG_GOOGLESHEETS = require("./img/googleSheets.png")
export const IMG_GOOGLEDOCS = require("./img/googleDocs.png")
export const IMG_MSTEAMS = require("./img/msteams.png")

export const IMG_SPACEX = require("./img/spaceX.png")
export const IMG_APPLE = require("./img/apple.png")
export const IMG_RICH_DAD = require("./img/richDad.png")
export const IMG_LIFE_COACH = require("./img/lifeCoaching.png")

export const IMG_LIVE_CHAT_BG = require("./img/liveChatBg.png")
export const IMG_LEAD_CAPTURE_BG = require("./img/leadCaptureBg.png")
export const IMG_DISCORD_HELPER_1 = require("./img/discordIntegrationHelper1.png")
export const IMG_DISCORD_HELPER_2 = require("./img/discordIntegrationHelper2.png")

export const IMG_PABBLY = require("./img/pabbly.png")
export const IMG_GBM_HINT_LOCAL_1 =  require("./img/gbmHintLocal1.png")
export const IMG_GBM_HINT_LOCAL_2 =  require("./img/gbmHintLocal2.png")
export const IMG_GBM_HINT_NON_LOCAL_1 =  require("./img/gbmHintNonLocal1.png")
export const IMG_GBM_HINT_NON_LOCAL_2 =  require("./img/gbmHintNonLocal2.png")
export const IMG_SAD_CIRCLE = SAD_CIRCLE
export const IMG_STARRY_BG = require("./img/starryBackground.jpg") 

//========================= AUDIO =========================

export const AUDIO_NOTIFICATION = require("./audio/notification.mp3")

//========================= VIDEO =========================

export const VIDEO_SALES_DRIVEN_CHATBOT = require("./video/salesDrivenChatbot.webm")
export const VIDEO_CONVERSATIONAL_EXCELLENCE = require("./video/convExcellence.webm")
export const VIDEO_PERSONALIZED_SALES = require("./video/personalizedSales.webm")
export const VIDEO_EASY_CHATBOT_CREATION = require("./video/createChatbot.webm")
export const VIDEO_SEAMLESS_INTEGRATION = require("./video/integration.webm")
export const VIDEO_LIVE_CHAT_SUPPORT = require("./video/liveChat.webm")
export const IMG_GOOGLE_ICON = require("./img/googleIcon.png")
export const GIF_REDEEM_COUPON = require("./gif/redeem.gif")


//========================= FILES =========================
export const FILE_SAMPLE_ECOMMERCE_CATALOG = require("./file/EcomCatalog.csv")
export const FILE_SAMPLE_DELETE_ECOMMERCE_CATALOG = require("./file/EcomDeleteCatalog.csv")