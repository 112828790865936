import styled from "@emotion/styled";
import { Tab } from "@mui/material";
import { THEME_MODE } from "../../../data/configs/constants";

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      minWidth: 0,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.mode === THEME_MODE.LIGHT ? 'black' : 'white',
      marginRight: theme.spacing(2),
      '&.MuiTab-root': {
        paddingInline: 0,
      },
      '&:hover': {
        color: theme.palette.mode === THEME_MODE.LIGHT ? 'black' : 'white',
        opacity: 1,
      },
      '&.Mui-selected': {
        color: theme.palette.mode === THEME_MODE.LIGHT ? 'black' : 'white',
        fontWeight: theme.typography.fontWeightMedium,
      },
    }),
  );