import styled from "@emotion/styled";
import { Tabs } from "@mui/material";
import { THEME_MODE } from "../../../data/configs/constants";

export const StyledTabs = styled((props) => <Tabs {...props} />)(
    ({ theme }) => ({
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.mode === THEME_MODE.LIGHT ? 'black' : 'white',
            display: 'flex',
            justifyContent: 'center',
        },
    }));