import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"

import { updatePageState } from "../../../data/store/pageSlice"
import { verifyEmailToken } from "../../../data/store/authSlice"

import SpinnerLoader from "../SpinnerLoader"
import { APP_PAGES, ROUTE_PATH } from "../../../data/configs/constants"

const EmailVerification = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.VERIFY_EMAIL,
        currentSiderModule: "",
      })
    )
  }, [dispatch])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has("token")) {
      dispatch(
        verifyEmailToken({
          token: queryParams.get("token"),
        })
      )
        .unwrap()
        .then((res) => {
          enqueueSnackbar(res?.message || "Email verified successfully", {
            variant: "success",
          })
          navigate(ROUTE_PATH.LOGIN)
        })
        .catch(() => {
          navigate(ROUTE_PATH.LOGIN)
        })
    } else {
      enqueueSnackbar("Missing Token", {
        variant: "error",
      })
      navigate(ROUTE_PATH.LOGIN)
    }
  }, [dispatch, navigate])

  return <SpinnerLoader brand />
}

export default EmailVerification
