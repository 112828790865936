export const GET_USER_CHATLOG_DETAILS =
  "/brain/v1/admin/conversation/history/sessions/:sessionId"


//======================== Need to review the below api =============================

export const GET_CHAT_LIST_FOR_ADMIN =
  "/brain/v1/superadmin/conversation/history/sessions"
export const GET_CHAT_LIST_FOR_USER =
  "/brain/v1/admin/conversation/history/sessions"
export const GET_CHATLOG_FOR_ADMIN =
  "/brain/v1/superadmin/conversation/history/sessions/:sessionId"
export const GET_CHATLOG_FOR_USER =
  "/brain/v1/admin/conversation/history/sessions/:sessionId"
export const GET_FLAG = "/brain/v1/conversation/flagchatlog?tenantId=:tenantId"
export const JOIN_CHAT_SESSION = '/brain/v1/admin/conversation/join-room'

export const GET_CHATLOG_DOWNLOAD_LINK = "brain/v1/admin/conversation/download/sessions/:sessionId"

export const GET_MESSAGE_FEEDBACKS = "brain/v1/admin/conversation/history/feedback"
export const UPDATE_MESSAGE_FEEDBACKS = "brain/v1/admin/conversation/history/feedback/:feedbackId"