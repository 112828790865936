import { styled } from "@mui/material/styles"
import Accordion from "@mui/material/Accordion"

const StyledAccordion = styled(Accordion)(({ theme, variant="elevation" }) => ({
  marginBottom: theme.spacing(0),
  borderRadius: theme.spacing(1),
  backgroundColor: variant === "elevation" ? theme.palette.background.default : theme.palette.background.paper,
  boxShadow: 'none',
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
}))

export default StyledAccordion
