import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"

import { updatePageState } from "../../../../../data/store/pageSlice"
import { ACL_IDENTIFIERS, APP_PAGES, ROUTE_PATH } from "../../../../../data/configs/constants"
import { uniqueId } from "../../../../../data/utils"
import {
  getMembers,
  deleteMembers,
  transferTenant,
  getLiveChatConfigurations
} from "../../../../../data/store/overviewSlice"

import CustomDataGrid from "../../../../components/CustomDataGrid"
import AddMember from "../../../../components/AddMember"
import Grid from "@mui/material/Grid"
import ShowInvitedUsers from "../../../../components/ShowInvitedUsers"
import AclComponentWrapper from "../../../../components/AclComponentWrapper"
import { Add } from "@mui/icons-material"
import CenteredPaperModal from "../../../../components/CenteredPaperModal"
import { Card, CardContent } from "@mui/material"
import { StyledTabs } from "../../../../components/StyledTabs"
import { StyledTab } from "../../../../components/StyledTab"
import { StyledButton } from "../../../../components/StyledButton"
import { StyledChips } from "../../../../components/StyledChips"
import { StyledLoadingButton } from "../../../../components/StyledLoadingButton"

const TeamMembers = () => {
  const { tenantId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [defaultEditData, setDefaultEditData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const memberList = useSelector((state) => state.overviewDetails.memberList)
  const currentPageAcl = useSelector((state) => state.pageDetails?.currentPageAcl);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const isWhiteLabeledDashboard = process.env.REACT_APP_WHITE_LABELED_APP === "true";

  useEffect(() => {
    dispatch(
      updatePageState({
        currentPage: APP_PAGES.TENANT_MEMBERS,
        currentSiderModule: APP_PAGES.TENANT_MEMBERS,
        showTenantHeader: true,
      })
    )
  }, [dispatch])


  useEffect(() => {
    dispatch(
      getMembers({
        tenantId,
      })
    )
    dispatch(getLiveChatConfigurations({ tenantId }))
  }, [dispatch, tenantId])

  const columns = [
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 200,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 150,
    },
    {
      field: "role",
      headerName: "Role",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 200,
      renderCell: (parameters) => {
        if (parameters.row.role === "tenantManager")
          return <Typography variant="body2">Bot Manager</Typography>
        else if (parameters.row.role === "owner")
          return <Typography variant="body2">Owner</Typography>
      },
    },
    {
      field: "isLiveAgent",
      headerName: "Live Agent",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 300,
      renderCell: (parameters) => {
        return <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {!parameters.row.isLiveAgent && <StyledChips size='small' label='Inactive'/>}
          {parameters.row.isLiveAgent && <StyledChips size='small' label='active' color='primary'/>}
        </Box>
      }
    },
    {
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 200,
      renderCell: (data) => {
        return (
          <Box sx={{
            display: "flex",
            justifyContent: 'center',
            gap: 1
          }}>
            <IconButton
              size="small"
              disabled={data.row.role === "owner"}
              onClick={() => {
                setOpenModal(true);
                setDefaultEditData({
                  ...data.row
                })
              }}>
              <Typography variant="h6"><i class="ri-pencil-line"></i></Typography>
            </IconButton>
            <IconButton
              size="small"
              disabled={data.row.role === "owner"}
              onClick={() => {
                deleteMember(data.row._id, data.row.userId)
              }}
            >
              <Typography variant="h6"><i class="ri-delete-bin-7-line"></i></Typography>
            </IconButton>
          </Box>
        )
      },
    },
  ]


  const deleteMember = (id, userId) => {
    dispatch(
      deleteMembers(
        id,
        tenantId,
        userId,
        () => {
          dispatch(
            getMembers({
              tenantId,
            })
          )
          enqueueSnackbar(`${email} has been removed from team`, {
            variant: "success",
          })
        }
      )
    )
  }


  const handleTabChanged = (e, newValue) => {
    setSelectedTab(newValue);
  }

  const handleModalClose = () => {
    setOpenModal(false);
  }

  const transferBot = () => {
    dispatch(
      transferTenant(tenantId, email, () => {
        navigate(ROUTE_PATH.DASHBOARD)
        enqueueSnackbar(`Bot successfully tranferred to ${email}`, {
          variant: "success",
        })
      })
    )
  }

  const handleInputChange = (event) => {
    setEmail(event.target.value)
  }

  return (
    <Box>
      <Grid container spacing={2} padding={1}>
        <Grid item xs={12}>
          <Typography variant='h6' mb={1}>Team Members</Typography>
        </Grid>
        <AclComponentWrapper payWallSize="small" componentAcl={currentPageAcl?.[ACL_IDENTIFIERS.ADD_TEAM_MEMBERS]}>
          <StyledTabs value={selectedTab} onChange={handleTabChanged} sx={{mx:2}}>
            <StyledTab label="Members" />
            <StyledTab label="Invitations" />
            {!isWhiteLabeledDashboard && <StyledTab label="Transfer Ownership" />}
          </StyledTabs>
          <Grid item md={12} xs={12}>
            {selectedTab === 0 ? (
            <AclComponentWrapper payWallSize="small" componentAcl={currentPageAcl?.[ACL_IDENTIFIERS.ADD_TEAM_MEMBERS]}>
                <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default', boxShadow: 0 }}>
                  <Stack direction={'row'} justifyContent={'space-between'} mb={1}>
                  <Typography variant="h6" mb={1}>
                    Team members
                  </Typography>
                  <StyledButton startIcon={<Add/>} onClick={()=>{
                    setOpenAddMemberModal(true)
                  }}>Add member</StyledButton>
                  </Stack>
                  <CenteredPaperModal open={openAddMemberModal} onClose={() => {
                    setOpenAddMemberModal(false)
                  }} sx={{
                    maxWidth: 600
                  }}>
                    <AddMember onClose={()=>setOpenAddMemberModal(false)}/>
                  </CenteredPaperModal>
                  <Card sx={{ boxShadow: 0 }}>
                  <CustomDataGrid
                    getRowId={() => uniqueId()}
                    rows={memberList}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel,
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    onPaginationModelChange={setPaginationModel}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    autoHeight
                  />
                  </Card>
                </Paper>
            </AclComponentWrapper>
          ) : selectedTab === 1 ? (
            <AclComponentWrapper payWallSize="small" componentAcl={currentPageAcl?.[ACL_IDENTIFIERS.INVITATIONS]}>
              <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default', boxShadow: 0 }}>
              <Box sx={{ display: selectedTab === 1 ? 'block' : 'none' }}>
                <ShowInvitedUsers />
              </Box>
              </Paper>
            </AclComponentWrapper>
          ) : (
            !isWhiteLabeledDashboard ? (
              <AclComponentWrapper payWallSize="small" componentAcl={currentPageAcl?.[ACL_IDENTIFIERS.TRANSFER_OWNERSHIP]}>
                <Paper sx={{ p: 2, mb: 1, backgroundColor:'background.default', boxShadow:0  }}>
                  <Typography variant="h6" mb={1}>
                    Transfer ownership
                  </Typography>
                  <Card>
                    <CardContent>
                      <Grid container justifyContent={'end'} spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="body2" fontWeight={500} gutterBottom>Recipient Email</Typography>
                          <TextField
                            placeholder="Enter email address"
                            value={email}
                            onChange={handleInputChange}
                            size="small"
                            fullWidth
                          />
                        </Grid>
                        <Grid item>
                          <StyledLoadingButton
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={transferBot}
                            disabled={!email}
                          >
                            Transfer Bot to user
                          </StyledLoadingButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </AclComponentWrapper>
            ): null
          )}
          </Grid>
        </AclComponentWrapper>
    </Grid>
      <CenteredPaperModal open={openModal} onClose={handleModalClose} >
        <AddMember defaultData={defaultEditData} onComplete={handleModalClose} />
      </CenteredPaperModal>
    </Box>
  )
}

export default TeamMembers
