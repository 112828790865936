import { Box } from '@mui/material'
import React from 'react'
import ShowMessage from '../ShowMessage';
import { IMG_SERVICE_MAINTENANCE } from '../../../data/assets';
import Button from "@mui/material/Button";
import {ROUTE_PATH} from "../../../data/configs/constants";
import {Link} from "react-router-dom";
const MaintenanceOverlay = () => {
  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <ShowMessage title="🔧 🚧 Service Under Maintenance!" src={IMG_SERVICE_MAINTENANCE} message={
        <span> The service you've requested is currently undergoing maintenance.
          <br />Please check back later to enjoy the service. Thank you for your patience! </span>}
      >
        <Link to={ROUTE_PATH.DASHBOARD}><Button variant={"contained"} sx={{m:2}}>Go to Home</Button></Link>
      </ShowMessage>
    </Box>
  )
}

export default MaintenanceOverlay