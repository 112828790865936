import dayjs from "dayjs"

const initialState = {
  selectedChat: "",
  chatList: [],
  chatListLoading: false,
  endDate: dayjs().format(),
  startDate: dayjs().format(),
  searchText: "",
  chatHistoryFilters: {},
  hasMore: false,
  chatlogDetails:[],
  userProfile:{},
  selectedTab: "chatHistory",
  messageFeedbacksFilter:{
    startDate: dayjs().format(),
    endDate: dayjs().format(),
    adminActions: ["pending"],
    sources: undefined,
  },
  messageFeedbacks:[],
  totalMessageFeedbacks:0,
}

export default initialState
