import { getDataFromLocalStorage } from "../../utils"
import { LOCAL_STORAGE } from "../../configs/constants"

const initialState = {
  isLoggedIn: getDataFromLocalStorage(LOCAL_STORAGE.IS_LOGGED_IN, false),
  token: getDataFromLocalStorage(LOCAL_STORAGE.TOKEN, ""),
  userId: getDataFromLocalStorage(LOCAL_STORAGE.USER_ID, ""),
  user: null,
  logoutSuccess: false,
  referralLink: "",
  referralData: [],
  referredCount: 0,
  showReferralWarning: false,
  maxRewardCount : 0,
  rewardedCount : 0,
  userQuota:[],
  acl: {},
  userTrackedEvents: [],
}

export default initialState
