import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { fetchDataForToolkit } from "../helpers"
import initialState from "./initialState"
import {
  GET_CHAT_LIST_FOR_ADMIN,
  GET_CHAT_LIST_FOR_USER,
  GET_CHATLOG_DOWNLOAD_LINK,
  GET_CHATLOG_FOR_ADMIN,
  GET_CHATLOG_FOR_USER,
  GET_FLAG,
  GET_MESSAGE_FEEDBACKS,
  JOIN_CHAT_SESSION,
  UPDATE_MESSAGE_FEEDBACKS,
} from "./api"
import { METHOD_TYPES } from "../../configs/constants"

const chatHistorySlice = createSlice({
  name: "chatHistory",
  initialState,
  reducers: {
    updateChatHistoryState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatList.fulfilled, (state, { meta, payload }) => {
        if (meta?.arg?.payload?.page === 1) {
          if (payload.data) {
            state.chatList = payload.data.chatSessions
            state.chatListLoading = false
            state.hasMore =
              payload.data.chatSessions.length === meta?.arg?.payload?.limit
          }
        } else if (meta?.arg?.payload?.page > 1) {
          if (payload.data) {
            state.chatList = [...state.chatList, ...payload.data.chatSessions]
            state.chatListLoading = false
            state.hasMore =
              payload.data.chatSessions.length === meta?.arg?.payload?.limit
          }
        }
      })
      .addCase(getChatList.pending, (state) => {
        state.chatListLoading = true
      })
      .addCase(getChatList.rejected, (state) => {
        state.chatListLoading = false
      })
      .addCase(getChatLogDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.chatlogDetails = payload.data.chatLogs
          state.userProfile = payload.data.userProfile
        }
      })
      .addCase(getChatLogDetailsWithoutLoader.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.chatlogDetails = payload.data.chatLogs
          state.userProfile = payload.data.userProfile
        }
      })
      .addCase(getFlag.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          const index = state.chatlogDetails.findIndex(
            (item) => item.id === payload.data.messageId
          )
          if (index > -1) {
            state.chatlogDetails[index] = {
              ...state.chatlogDetails[index],
              isTrainingRequired: payload.data.isTrainingRequired,
            }
          }
        }
      })
      .addCase(getMessageFeedbacks.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.messageFeedbacks = payload.data.chatLogs
          state.totalMessageFeedbacks = payload.data.totalCount
        }
      })
      .addCase(updateMessageFeedbacks.fulfilled, (state, { payload }) => {
        if (payload.data) {
          const index = state.messageFeedbacks.findIndex(
            (item) => item._id === payload.data._id
          )
          const appliedAdminActionsFilter = state.messageFeedbacksFilter.adminActions
          const appliedSourcesFilter = state.messageFeedbacksFilter.sources
          if (index > -1 && 
            (!appliedAdminActionsFilter || appliedAdminActionsFilter.includes(payload.data.adminAction)) && 
            (!appliedSourcesFilter || appliedSourcesFilter.includes(payload.data.source))) {
            state.messageFeedbacks[index] = payload.data
          } else{
            state.messageFeedbacks = [
              ...state.messageFeedbacks.slice(0, index),
              ...state.messageFeedbacks.slice(index + 1),
            ]
          }
        }
      })
  },
})

export const getChatList = createAsyncThunk(
  "chatHistory/getChatList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: data.isAdmin ? GET_CHAT_LIST_FOR_ADMIN : GET_CHAT_LIST_FOR_USER,
        method: METHOD_TYPES.POST,
        data: data.payload,
        loader: false,
      },
      helpers
    )
)

export const getChatLogDetails = createAsyncThunk(
  "chatHistory/getChatLogDetails",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: data.isAdmin
          ? GET_CHATLOG_FOR_ADMIN.replace(":sessionId", data.sessionId)
          : GET_CHATLOG_FOR_USER.replace(":sessionId", data.sessionId),
        method: METHOD_TYPES.POST,
        data: data.payload,
      },
      helpers
    )
)

export const getChatLogDetailsWithoutLoader = createAsyncThunk(
  "chatHistory/getChatLogDetailsWithoutLoader",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: data.isAdmin
          ? GET_CHATLOG_FOR_ADMIN.replace(":sessionId", data.sessionId)
          : GET_CHATLOG_FOR_USER.replace(":sessionId", data.sessionId),
        method: METHOD_TYPES.POST,
        data: data.payload,
        loader: false
      },
      helpers
    )
)

export const getFlag = createAsyncThunk(
  "chatHistory/getFlag",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_FLAG.replace(":tenantId", data.tenantId),
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const joinChatSession = createAsyncThunk(
  "chatHistory/joinChatSession",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: JOIN_CHAT_SESSION,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getChatlogDownloadLink = createAsyncThunk(
  "chatHistory/getChatlogDownloadLink",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_CHATLOG_DOWNLOAD_LINK.replace(":sessionId", data.sessionId),
        method: METHOD_TYPES.POST,
        data: data.payload,
        loaderText: 'Downloading chatlog...'
      },
      helpers
    )
)

export const getMessageFeedbacks = createAsyncThunk(
  "chatHistory/getMessageFeedbacks",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_MESSAGE_FEEDBACKS,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const updateMessageFeedbacks = createAsyncThunk(
  "chatHistory/updateMessageFeedbacks",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_MESSAGE_FEEDBACKS.replace(":feedbackId", data.feedbackId),
        method: METHOD_TYPES.PUT,
        data: data.payload,
      },
      helpers
    )
)

export const { updateChatHistoryState } = chatHistorySlice.actions
export default chatHistorySlice.reducer
