import React, { useEffect } from 'react'
import SpinnerLoader from '../SpinnerLoader'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GbmAuthentication = () => {
    const navigate = useNavigate();

    const getGoogleAuthTokens = async (code) => {
        const clientID = process.env.REACT_APP_GBM_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_GBM_CLIENT_SECRET;
        const redirectUrl = process.env.REACT_APP_GBM_VERIFY_URL;
        const tokenUrl = 'https://oauth2.googleapis.com/token';
        const params = new URLSearchParams();
        params.append('code', code);
        params.append('client_id', clientID);
        params.append('client_secret', clientSecret);
        params.append('redirect_uri', redirectUrl);
        params.append('grant_type', 'authorization_code');
        try {
            const response = await axios.post(tokenUrl, params)
            const tokens = response.data;
            return tokens;
        } catch (err) {
            throw err;
        }
    }

    useEffect(() => {
        const redirectToChannelsPage = (tokens, tenantId) => {
            const redirectParams = new URLSearchParams();
            Object.keys(tokens).forEach((key) => {
                redirectParams.append(key, tokens[key]);
            })
            navigate('/app/tenant/' + tenantId + "/channels?channel=gbm&" + redirectParams.toString())
        }

        const params = new URLSearchParams(window.location.search);
        if (!params.get('code') || !params.get('state')) return;
        getGoogleAuthTokens(params.get("code")).then((tokens) => {
            redirectToChannelsPage(tokens, params.get('state'));
        }).catch((err) => {
            navigate('/app/tenant/' + params.get('state') + '/channels?channel=gbm?error=true')
        })
    }, [navigate])

    return (
        <SpinnerLoader brand />
    )
}

export default GbmAuthentication