import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Outlet, useNavigate } from "react-router-dom";
import { TENANT_SETTINGS_SIDER_LIST } from "../../../../data/configs/constants";
import Badge from "@mui/material/Badge";
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import StyledAccordion from "../../../components/StyledAccordian";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useState } from "react";
import { StyledListItemButton } from "../../../components/StyledListItemButton";
import { StyledListItemIcon } from "../../../components/StyledListItemIcon";

export const Settings = () => {
  const navigate = useNavigate()
  const badgeOnSiderModule = useSelector(
    (state) => state.pageDetails.badgeOnSiderModule
  )
  const tenantInfo = useSelector((state) => state.overviewDetails.tenantInfo)
  const currentSiderModule = useSelector(
    (state) => state.pageDetails.currentSiderModule
  )
  const [mobileMenuOpen , setMobileMenuOpen] = useState(false)
  const currentPage = useSelector((state) => state.pageDetails.currentPage)
  const mobile = useSelector((state) => state.pageDetails.deviceData.mobile)
  return <Grid container spacing={2} sx={{height:'100%'}}>
    <Grid item sx={{display:{md:'initial',xs:'none'}, height:'100%'}} md={3}>
      <Card sx={{mb:4, boxShadow:0,borderRadius:2, height:'100%', overflow:'scroll'}}>
        <CardContent>
            {TENANT_SETTINGS_SIDER_LIST.map((item) => (
              <StyledListItemButton
                key={item.appPage}
                selected={currentSiderModule === item.appPage}
                onClick={() =>{
                  navigate(item.route.replace(":tenantId", tenantInfo.tenantId))
                }
                }
              >
                <Badge
                  color="secondary"
                  badgeContent={badgeOnSiderModule[item.appPage] || 0}
                >
                  <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                </Badge>
                <ListItemText
                  primary={item.appPage}
                  primaryTypographyProps={{
                    variant: "body2",
                    ml: 1.5,
                  }}
                />
              </StyledListItemButton>
            ))}
        </CardContent>
      </Card>
    </Grid>
    <Grid item md={9} xs={12} sx={{height:'100%'}}>
       <Box display={{xs: "block", md:'none'}}>
        <StyledAccordion sx={{backgroundColor:'background.paper'}} expanded={mobileMenuOpen} onChange={()=>setMobileMenuOpen(!mobileMenuOpen)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="od-customize-bot-data-source"
            id="od-customize-bot-data-source"
            sx={{color: "secondary.main"}}
          >
            {/*{source.icon}*/}
            <Typography ml={1}>{currentPage}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={1}>
              {TENANT_SETTINGS_SIDER_LIST.map((item) => {
                return (
                  <Chip
                    key={item.value}
                    color={
                      item.appPage === currentPage ? "primary" : "default"
                    }
                    icon={item?.icon}
                    label={item.appPage}
                    sx={{p: 2.5}}
                    onClick={() =>{
                      setMobileMenuOpen(false);
                      navigate(item.route.replace(":tenantId", tenantInfo.tenantId))
                    }}
                  />
                )
              })}
            </Stack>
          </AccordionDetails>
        </StyledAccordion>
      </Box>
      <Card sx={{mb:4,boxShadow:0,borderRadius:2, height:'100%', overflow:'scroll'}}>
        <CardContent>
          <Outlet />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}