import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import { IMG_EMPTY } from "../../../data/assets"

const ShowMessage = ({ message, title, sx, children, ...restProps }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Avatar
        variant="square"
        src={IMG_EMPTY}
        alt="Info"
        sx={(theme) => ({
          height: 150,
          width: 150,
          my: 2,
          p:1,
          // opacity: theme.palette.action.disabledOpacity,
          ...sx,
        })}
        {...restProps}
      />

      {title && (
        <Typography variant="h6" align="center" color="text.secondary">
          {title}
        </Typography>
      )}
      {message && (
        <Typography align="center" color="text.disabled">
          {message}
        </Typography>
      )}
      {children}
    </Box>
  )
}

ShowMessage.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

ShowMessage.defaultProps = {
  title: "Empty!!",
  message: "Result not found",
  sx: {},
  children: null,
}

export default ShowMessage
