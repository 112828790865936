import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import initialState from "./initialState"
import {
  GET_FACEBOOK_PAGES,
  INTEGRATE_FACEBOOK_PAGES,
  TOGGLE_SUBSCRIPTION_OF_FACEBOOK_PAGE,
  INTEGRATE_TELEGRAM_BOT,
  GET_TELEGRAM_BOT_INFO,
  TOGGLE_SUBSCRIPTION_OF_TELEGRAM_BOT,
  GET_CHANNELS_STATUS,
  UPDATE_CHANNEL_STATUS,
  INTEGRATE_INSTAGRAM_PAGES,
  VERIFY_WHATSAPP_DETAILS,
  GET_INTEGRATED_WHATSAPP_LIST,
  DELETE_WHATSAPP_NUMBER,
  UPDATE_WHATSAPP_NUMBER_STATUS,
  INTEGRATE_WHATSAPP_NUMBER,
  GBM_GET_INTEGRATED_GBM_STATUS,
  GBM_INTEGRATE_GBM,
  GBM_UPLOAD_GBM_IMG,
  GBM_DELETE_GBM,
  GBM_ADD_GBM_LOCATION,
  GBM_GET_GBM_LOCATION_LIST,
  GBM_DELETE_GBM_LOCATIONS,
  GBM_REQUEST_GBM_LOCATION_VERIFY,
  GBM_REQUEST_GBM_AGENT_VERIFICATION,
  GBM_GET_NON_LOCAL_REGIONS,
  GBM_REQUEST_NON_LOCAL_LAUNCH,
  GBM_REQUEST_NON_LOCAL_UNLAUNCH,
  GBM_REQUEST_LOCATION_LAUNCH,
  GBM_REQUEST_LOCATION_UNLAUNCH,
  GBM_REFRESH_STATUSES,
  DISCORD_GET_ALL_BOTS,
  DISCORD_UPDATE_COMMAND,
  DISCORD_ADD_NEW_COMMAND,
  DISCORD_DELETE_COMMAND,
  DISCORD_DELETE_BOT,
  DISCORD_INTEGRATE_BOT,
  DISCORD_GET_BOT_INFO,
  DISCORD_CHANGE_ACTIVATION_STATE,
  ZAPIER_GET_API_KEY,
  ZAPIER_GET_STATUS,
  ZAPIER_DELETE_API_KEY,
  ZAPIER_ADD_LEADS_WEBHOOK_URL,
  ZAPIER_UPDATE_MESSAGE_INTEGRATION,
  ZAPIER_VALIDATE_LEADS_WEBHOOK_URL,
  ZAPIER_UPDATE_LEADS_INTEGRATION,
  PABBLY_GET_STATUS,
  PABBLY_GET_API_KEY,
  PABBLY_DELETE_API_KEY,
  PABBLY_ADD_LEADS_WEBHOOK_URL,
  PABBLY_UPDATE_MESSAGE_INTEGRATION,
  PABBLY_VALIDATE_LEADS_WEBHOOK_URL,
  PABBLY_UPDATE_LEADS_INTEGRATION,
  MSTEAMS_GET_INTEGRATION,
  MSTEAMS_ADD_INTEGRATION,
  MSTEAMS_DELETE_INTEGRATION,
  SLACK_GET_INTEGRATION,
  SLACK_APP_MANIFEST,
  SLACK_INTEGRATE,
  SLACK_VERIFY_EVENT_URL,
  DELETE_TELEGRAM_BOT,
} from "./api"
import { fetchDataForToolkit } from "../helpers"
import { GBM_LAUNCH_STATUS, METHOD_TYPES } from "../../configs/constants"

const channelSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    updateChannelState(state, action) {
      return { ...state, ...action.payload }
    },
    setVerificationStatus(state, action) {
      return { ...state, gbm: {
        ...state.gbm,
        integrationData: [
          {
            ...state.gbm.integrationData[0],
            verificationStatus: action.payload
          }
        ]
      } }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFacebookPages.fulfilled, (state, { payload }) => {
        if (payload.data) state.facebookPages = payload.data
      })
      .addCase(
        toggleSubscriptionOfFacebookPage.fulfilled,
        (state, { payload }) => {
          if (payload.data && payload.data.facebookPageId) {
            const index = state.facebookPages.findIndex(
              (item) => item.facebookPageId === payload.data.facebookPageId
            )
            if (index > -1) {
              state.facebookPages = [
                ...state.facebookPages.slice(0, index),
                {
                  ...state.facebookPages[index],
                  isActive: !state.facebookPages[index].isActive,
                },
                ...state.facebookPages.slice(index + 1),
              ]
            }
          }
        }
      )
      .addCase(getTelegramBotInfo.fulfilled, (state, { payload }) => {
          state.telegramBotInfo = payload.data.botData
      })
      .addCase(getChannelsStatus.fulfilled, (state, { payload }) => {
        if (payload.data) state.channelsStatus = payload.data
      })
      .addCase(updateChannelStatus.fulfilled, (state, { payload }) => {
        if (payload.data?.channel) {
          state.channelsStatus[payload.data.channel] = payload.data.isActive
        }
      })
      .addCase(getInstagramPages.fulfilled, (state, { payload }) => {
        if (payload.data) state.instagramPages = payload.data
      })
      .addCase(
        toggleSubscriptionOfInstagramPage.fulfilled,
        (state, { payload }) => {
          if (payload.data && payload.data.facebookPageId) {
            const index = state.instagramPages.findIndex(
              (item) => item.facebookPageId === payload.data.facebookPageId
            )
            if (index > -1) {
              state.instagramPages = [
                ...state.instagramPages.slice(0, index),
                {
                  ...state.instagramPages[index],
                  isActive: !state.instagramPages[index].isActive,
                },
                ...state.instagramPages.slice(index + 1),
              ]
            }
          }
        }
      )
      .addCase(getIntegratedWhatsappList.fulfilled, (state, { payload }) => {
        if (payload?.data?.integratedNumbers) {
          state.integratedWhatsappList = payload.data.integratedNumbers
          state.connectionInfo = payload.data.connectionInfo
        }
      })
      .addCase(deleteWhatsappNumber.fulfilled, (state, { payload }) => {
        if (payload?.data?.whatsappPhoneNumberId) {
          const index = state.integratedWhatsappList.findIndex(
            (item) =>
              item.whatsappPhoneNumberId === payload.data.whatsappPhoneNumberId
          )
          if (index > -1) {
            state.integratedWhatsappList = [
              ...state.integratedWhatsappList.slice(0, index),
              ...state.integratedWhatsappList.slice(index + 1),
            ]
          }
        }
      })
      .addCase(updateWhatsappNumberStatus.fulfilled, (state, { payload }) => {
        if (payload?.data?.whatsappPhoneNumberId) {
          const index = state.integratedWhatsappList.findIndex(
            (item) =>
              item.whatsappPhoneNumberId === payload.data.whatsappPhoneNumberId
          )
          if (index > -1) {
            state.integratedWhatsappList = [
              ...state.integratedWhatsappList.slice(0, index),
              {
                ...state.integratedWhatsappList[index],
                isActive: payload?.data?.isActive,
              },
              ...state.integratedWhatsappList.slice(index + 1),
            ]
          }
        }
      })
      .addCase(integrateWhatsappNumber.fulfilled, (state, { payload }) => {
        if (payload?.data?.whatsappPhoneNumberId) {
          state.integratedWhatsappList = [
            payload.data,
            ...state.integratedWhatsappList,
          ]
        }
      })
      .addCase(getIntegratedGbmStatus.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.gbm.integrationData = payload.data
        }
      })
      .addCase(integrateGbm.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.gbm.integrationData = [
            ...payload.data
          ]
        }
      })
      .addCase(deleteGbmIntegration.fulfilled, (state, action)=>{
        state.gbm.integrationData = []
        state.channelsStatus.gbm = false
      })
      .addCase(addGbmLocation.fulfilled, (state, { payload }) => {
        state.gbm.addedLocations = [
          ...state.gbm.addedLocations,
          payload.data
        ]
      })
      .addCase(getGbmLocationsList.fulfilled, (state, { payload }) => {
        state.gbm.addedLocations = payload.data
      })
      .addCase(getGbmVerifiedLocationsList.fulfilled, (state, { payload }) => {
        state.gbm.verifiedLocations = payload.data
      })
      .addCase(getGbmNonLocalRegions.fulfilled, (state, { payload }) => {
        state.gbm.nonLocalRegions = payload.data
      })
      .addCase(requestLocationLaunch.fulfilled, (state, { payload }) => {
        payload.data?.forEach((item)=>{
          const index = state.gbm.verifiedLocations.findIndex((location)=>location.locationId === item.locationId)
          if(index > -1) state.gbm.verifiedLocations[index] = item
        })
      })
      .addCase(requestLocationUnlaunch.fulfilled, (state, { payload }) => {
        payload.data?.forEach((item)=>{
          const index = state.gbm.verifiedLocations.findIndex((location)=>location.locationId === item.locationId)
          if(index > -1) state.gbm.verifiedLocations[index] = item
        })
      })
      .addCase(requestNonLocalRegionsLaunch.fulfilled, (state, { payload }) => {
        payload.data?.forEach((item)=>{
          const index = state.gbm.nonLocalRegions.findIndex((region)=>region.code === item)
          if(index > -1) state.gbm.nonLocalRegions[index].launchStatus = GBM_LAUNCH_STATUS.LAUNCHED
        })
      })
      .addCase(requestNonLocalRegionsUnlaunch.fulfilled, (state, { payload }) => {
        payload.data?.forEach((item)=>{
          const index = state.gbm.nonLocalRegions.findIndex((region)=>region.code === item)
          if(index > -1) state.gbm.nonLocalRegions[index].launchStatus = GBM_LAUNCH_STATUS.NOT_LAUNCHED
        })
      })
      .addCase(discordGetAllBots.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.discord.bots = payload.data
        }
      })
      .addCase(discordIntegrateBot.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.discord.bots = [payload.data]
        }
      })
      .addCase(discordAddNewCommand.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.discord.bots[0].botCommands = [
            ...state.discord.bots[0].botCommands,
            payload.data,
          ]
        }
      })
      .addCase(discordDeleteCommand.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          const index = state.discord.bots[0].botCommands.findIndex(
            (item) => item.id === payload.data[0].id
          )
          if (index > -1) {
            state.discord.bots[0].botCommands = [
              ...state.discord.bots[0].botCommands.slice(0, index),
              ...state.discord.bots[0].botCommands.slice(index + 1),
            ]
          }
        }
      })
      .addCase(discordUpdateCommand.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          const index = state.discord.bots[0].botCommands.findIndex(
            (item) => item.id === payload.data.id
          )
          if (index > -1) {
            state.discord.bots[0].botCommands = [
              ...state.discord.bots[0].botCommands.slice(0, index),
              payload.data,
              ...state.discord.bots[0].botCommands.slice(index + 1),
            ]
          }
        }
      })
      .addCase(discordDeleteBot.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.discord.bots = []
        }
      })
      .addCase(discordChangeActivationStatus.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.discord.bots[0].isActive = payload.data.isActive
        }
      })
      .addCase(zapierGetApiKey.fulfilled, (state, { payload }) => {
        state.zapier.apiData = payload?.data
      })
      .addCase(zapierGetStatus.fulfilled, (state, { payload }) => {
        state.zapier.apiData = payload?.data
      })
      .addCase(zapierDeleteApiKey.fulfilled, (state, {payload})=>{
        state.zapier.apiData = {}
      })
      .addCase(zapierUpdateMessageIntegration.fulfilled, (state, {payload})=>{
        state.zapier.apiData = {
          ...state.zapier.apiData,
          ...payload?.data
        }
      })
      .addCase(zapierAddLeadsWebhookUrl.fulfilled, (state, {payload})=>{
        state.zapier.apiData = {
          ...state.zapier.apiData,
          ...payload?.data
        }
      })
      .addCase(zapierLeadsValidateWebhook.fulfilled , (state, {payload})=>{
        state.zapier.apiData = {
          ...state.zapier.apiData,
          ...payload?.data
        }
      })
      .addCase(zapierUpdateLeadsIntegration.fulfilled, (state, {payload})=>{
        state.zapier.apiData = {
          ...state.zapier.apiData,
          ...payload?.data
        }
      })
      .addCase(pabblyGetApiKey.fulfilled, (state, { payload }) => {
        state.pabbly.apiData = payload?.data
      })
      .addCase(pabblyGetStatus.fulfilled, (state, { payload }) => {
        state.pabbly.apiData = payload?.data
      })
      .addCase(pabblyDeleteApiKey.fulfilled, (state, {payload})=>{
        state.pabbly.apiData = {}
      })
      .addCase(pabblyUpdateMessageIntegration.fulfilled, (state, {payload})=>{
        state.pabbly.apiData = {
          ...state.pabbly.apiData,
          ...payload?.data
        }
      })
      .addCase(pabblyAddLeadsWebhookUrl.fulfilled, (state, {payload})=>{
        state.pabbly.apiData = {
          ...state.pabbly.apiData,
          ...payload?.data
        }
      })
      .addCase(pabblyLeadsValidateWebhook.fulfilled , (state, {payload})=>{
        state.pabbly.apiData = {
          ...state.pabbly.apiData,
          ...payload?.data
        }
      })
      .addCase(pabblyUpdateLeadsIntegration.fulfilled, (state, {payload})=>{
        state.pabbly.apiData = {
          ...state.pabbly.apiData,
          ...payload?.data
        }
      })
      .addCase(msteamsGetIntegration.fulfilled, (state, {payload})=>{
        state.msteams.data = payload.data
      })
      .addCase(msteamsAddIntegration.fulfilled,(state,{payload})=>{
        state.msteams.data.push(payload.data);
      })
      .addCase(msteamsDeleteIntegration.fulfilled,(state)=>{
        state.msteams.data = [];
      })
      .addCase(slackGetIntegration.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.slack.integrationData = payload.data
        }
      })
      .addCase(slackAppManifest.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          state.slack.integrationData = [
            ...state.slack.integrationData,
            payload.data
          ]
        }
      })
      .addCase(slackVerifyEventUrl.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          const status = payload.data.status
          const index = state.slack.integrationData.findIndex((item)=>item.applicationId === payload.data.applicationId)
          if(status){
            state.slack.integrationData[index].tasksCompleted.verifyEventUrl = true;
          }
        }
      })
      .addCase(slackIntegrate.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          const index = state.slack.integrationData.findIndex((item)=>item.applicationId === payload.data.applicationId)
          state.slack.integrationData[index] = payload.data
        }
      })
      .addCase(slackUpdate.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          const index = state.slack.integrationData.findIndex((item)=>item.applicationId === payload.data.applicationId)
          state.slack.integrationData[index] = payload.data
        }
      })
      .addCase(slackDelete.fulfilled, (state, { payload }) => {
        if (payload?.data) {
          const index = state.slack.integrationData.findIndex((item)=>item.applicationId === payload.data.applicationId)
          state.slack.integrationData = [
            ...state.slack.integrationData.slice(0, index),
            ...state.slack.integrationData.slice(index + 1)
          ]
        }
      })
  },
})

export const getFacebookPages = createAsyncThunk(
  "channels/getFacebookPages",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_FACEBOOK_PAGES,
        data,
      },
      helpers
    )
)

export const integrateFacebookPages = createAsyncThunk(
  "channels/integrateFacebookPages",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: INTEGRATE_FACEBOOK_PAGES,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const toggleSubscriptionOfFacebookPage = createAsyncThunk(
  "channels/toggleSubscriptionOfFacebookPage",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: TOGGLE_SUBSCRIPTION_OF_FACEBOOK_PAGE,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const integrateTelegramBot = createAsyncThunk(
  "channels/integrateTelegramBot",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: INTEGRATE_TELEGRAM_BOT,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getTelegramBotInfo = createAsyncThunk(
  "channels/getTelegramBotInfo",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TELEGRAM_BOT_INFO,
        data,
      },
      helpers
    )
)

export const deleteTelegramBot = createAsyncThunk(
  "channels/deleteTelegramBot",
  async(data = {}, helpers) => 
    fetchDataForToolkit(
      {
        url: DELETE_TELEGRAM_BOT,
        data,
        method: METHOD_TYPES.DELETE
      },
      helpers
    )
)

export const toggleSubscriptionOfTelegramBot = createAsyncThunk(
  "channels/toggleSubscriptionOfTelegramBot",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: TOGGLE_SUBSCRIPTION_OF_TELEGRAM_BOT,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const getChannelsStatus = createAsyncThunk(
  "channels/getChannelsStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_CHANNELS_STATUS,
        data,
      },
      helpers
    )
)

export const updateChannelStatus = createAsyncThunk(
  "channels/updateChannelStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_CHANNEL_STATUS,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getInstagramPages = createAsyncThunk(
  "channels/getInstagramPages",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_FACEBOOK_PAGES,
        data,
      },
      helpers
    )
)

export const toggleSubscriptionOfInstagramPage = createAsyncThunk(
  "channels/toggleSubscriptionOfInstagramPage",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: TOGGLE_SUBSCRIPTION_OF_FACEBOOK_PAGE,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const integrateInstagramPages = createAsyncThunk(
  "channels/integrateInstagramPages",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: INTEGRATE_INSTAGRAM_PAGES,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const verifyWhatsappDetails = createAsyncThunk(
  "channels/verifyWhatsappDetails",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: VERIFY_WHATSAPP_DETAILS,
        data,
      },
      helpers
    )
)

export const getIntegratedWhatsappList = createAsyncThunk(
  "channels/getIntegratedWhatsappList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_INTEGRATED_WHATSAPP_LIST,
        data,
      },
      helpers
    )
)

export const deleteWhatsappNumber = createAsyncThunk(
  "channels/deleteWhatsappNumber",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DELETE_WHATSAPP_NUMBER,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)

export const updateWhatsappNumberStatus = createAsyncThunk(
  "channels/updateWhatsappNumberStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_WHATSAPP_NUMBER_STATUS,
        data,
        method: METHOD_TYPES.PUT,
      },
      helpers
    )
)

export const integrateWhatsappNumber = createAsyncThunk(
  "channels/integrateWhatsappNumber",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: INTEGRATE_WHATSAPP_NUMBER,
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
)

export const getIntegratedGbmStatus = createAsyncThunk(
  "channel/getIntegratedGbmStatus",
  async (data={}, helpers)=>
    fetchDataForToolkit(
      {
        url: GBM_GET_INTEGRATED_GBM_STATUS,
        data,
      },
      helpers
    )
)

export const integrateGbm = createAsyncThunk(
  "channel/integrateGbm",
  async (data={}, helpers)=>
    fetchDataForToolkit(
      {
        url: GBM_INTEGRATE_GBM,
        data,
        method: METHOD_TYPES.POST
      },
      helpers
    )
)

export const uploadGbmImage = createAsyncThunk(
  "channel/uploadGbmImage",
  async ({tenantId, data}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_UPLOAD_GBM_IMG.replace(":tenantId", tenantId)+"?tenantId="+tenantId,
        method: METHOD_TYPES.POST,
        data,
        loader: false,
        contentType: "multipart/form-data"
      },
      helpers
    )
  }
)

export const deleteGbmIntegration = createAsyncThunk(
  "channel/deleteGbmIntegration",
  async (data, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_DELETE_GBM,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
  }
)

export const getGbmLocationsList = createAsyncThunk(
  "channel/getGbmLocationsList",
  async (data={}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_GET_GBM_LOCATION_LIST,
        data,
        loader: data.loader
      },
      helpers
    )
  }
)

export const getGbmVerifiedLocationsList = createAsyncThunk(
  "channel/getGbmVerifiedLocationsList",
  async (data={}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_GET_GBM_LOCATION_LIST,
        data:{
          ...data,
          filter: true,
        },
        loader: data.loader
      },
      helpers
    )
  }
)

export const addGbmLocation = createAsyncThunk(
  "channel/addGbmLocation",
  async (data={}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_ADD_GBM_LOCATION,
        method: METHOD_TYPES.POST,
        data,
        loader:false
      },
      helpers
    )
  }
)

export const deleteGbmLocations = createAsyncThunk(
  "channel/deleteGbmLocations",
  async (data = {}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_DELETE_GBM_LOCATIONS,
        data,
        method: METHOD_TYPES.DELETE
      },
      helpers
    )
  }
)

export const requestGbmLocationVerify = createAsyncThunk(
  "channel/requestGbmLocationVerify",
  async (data = {}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_REQUEST_GBM_LOCATION_VERIFY,
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
  }
)

export const getGbmNonLocalRegions = createAsyncThunk(
  "channel/getGbmNonLocalRegions",
  async (data = {}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_GET_NON_LOCAL_REGIONS,
        data,
        loader: data.loader
      },
      helpers
    )
  }
)

export const requestGbmAgentVerification = createAsyncThunk(
  "channel/requestGbmAgentVerification",
  async (data = {}, helpers) => {
    return fetchDataForToolkit(
      {
        url: GBM_REQUEST_GBM_AGENT_VERIFICATION,
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
  }
)

export const refreshVerificationAndLaunchStatuses = createAsyncThunk(
  'channel/refreshVerificationAndLaunchStatuses',
  async (data = {}, helpers) => {
    return fetchDataForToolkit({
      url: GBM_REFRESH_STATUSES,
      data,
      method: METHOD_TYPES.POST,
      timeout: 30000
    },
      helpers
    );
  }
);

export const requestNonLocalRegionsLaunch = createAsyncThunk(
  'channel/requestNonLocalRegionsLaunch',
  async (data = {}, helpers) => {
    return fetchDataForToolkit({
      url: GBM_REQUEST_NON_LOCAL_LAUNCH,
      data,
      method: METHOD_TYPES.POST,
    },
      helpers
    );
  }
);

export const requestNonLocalRegionsUnlaunch = createAsyncThunk(
  'channel/requestNonLocalRegionsUnlaunch',
  async (data = {}, helpers) => {
    return fetchDataForToolkit({
      url: GBM_REQUEST_NON_LOCAL_UNLAUNCH,
      data,
      method: METHOD_TYPES.POST,
    },
      helpers
    );
  }
);

export const requestLocationLaunch = createAsyncThunk(
  'channel/requestLocationLaunch',
  async (data = {}, helpers) => {
    return fetchDataForToolkit({
      url: GBM_REQUEST_LOCATION_LAUNCH,
      data,
      method: METHOD_TYPES.POST,
    },
      helpers
    );
  }
);

export const requestLocationUnlaunch = createAsyncThunk(
  'channel/requestLocationUnlaunch',
  async (data = {}, helpers) => {
    return fetchDataForToolkit({
      url: GBM_REQUEST_LOCATION_UNLAUNCH,
      data,
      method: METHOD_TYPES.POST,
    },
      helpers
    );
  }
);

export const discordGetAllBots = createAsyncThunk(
  "channels/discordGetAllBots",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_GET_ALL_BOTS,
        data,
      },
      helpers
    )
)


export const discordUpdateCommand = createAsyncThunk(
  "channels/discordUpdateCommand",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_UPDATE_COMMAND,
        data,
        method: METHOD_TYPES.PUT,
      },
      helpers
    )
)

export const discordAddNewCommand = createAsyncThunk(
  "channels/discordAddNewCommand",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_ADD_NEW_COMMAND,
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
)

export const discordDeleteCommand = createAsyncThunk(
  "channels/discordDeleteCommand",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_DELETE_COMMAND,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)

export const discordDeleteBot = createAsyncThunk(
  "channels/discordDeleteBot",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_DELETE_BOT,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)

export const discordIntegrateBot = createAsyncThunk(
  "channels/discordIntegrateBot",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_INTEGRATE_BOT,
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
)

export const discordGetBotInfo = createAsyncThunk(
  "channels/discordGetBotInfo",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_GET_BOT_INFO,
        data,
      },
      helpers
    )
)

export const discordChangeActivationStatus = createAsyncThunk(
  "channels/discordChangeActivationStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DISCORD_CHANGE_ACTIVATION_STATE,
        data,
        method: METHOD_TYPES.PUT,
      },
      helpers
    )
)

export const zapierGetStatus = createAsyncThunk(
  "channels/zapierGetStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_GET_STATUS,
        data,
        method: METHOD_TYPES.GET,
      },
      helpers
    )
)

export const zapierGetApiKey = createAsyncThunk(
  "channels/zapierGetApiKey",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_GET_API_KEY,
        data,
        method: METHOD_TYPES.GET,
      },
      helpers
    )
)

export const zapierDeleteApiKey = createAsyncThunk(
  "channels/zapierDeleteApiKey",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_DELETE_API_KEY,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)





export const zapierAddLeadsWebhookUrl = createAsyncThunk(
  "integration/zapierAddLeadsWebhookUrl",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_ADD_LEADS_WEBHOOK_URL,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
) 

export const zapierUpdateMessageIntegration = createAsyncThunk(
  "integration/zapierUpdateMessageIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_UPDATE_MESSAGE_INTEGRATION,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const zapierLeadsValidateWebhook = createAsyncThunk(
  "integration/zapierLeadsValidateWebhook",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_VALIDATE_LEADS_WEBHOOK_URL,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const zapierUpdateLeadsIntegration = createAsyncThunk(
  "integration/zapierUpdateLeadsIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: ZAPIER_UPDATE_LEADS_INTEGRATION,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const pabblyGetStatus = createAsyncThunk(
  "channels/pabblyGetStatus",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: PABBLY_GET_STATUS,
        data,
        method: METHOD_TYPES.GET,
      },
      helpers
    )
)


export const pabblyGetApiKey = createAsyncThunk(
  "channels/pabblyGetApiKey",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: PABBLY_GET_API_KEY,
        data,
        method: METHOD_TYPES.GET,
      },
      helpers
    )
)

export const pabblyDeleteApiKey = createAsyncThunk(
  "channels/pabblyDeleteApiKey",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: PABBLY_DELETE_API_KEY,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)


export const pabblyAddLeadsWebhookUrl = createAsyncThunk(
  "integration/pabblyAddLeadsWebhookUrl",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: PABBLY_ADD_LEADS_WEBHOOK_URL,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
) 

export const pabblyUpdateMessageIntegration = createAsyncThunk(
  "integration/pabblyUpdateMessageIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: PABBLY_UPDATE_MESSAGE_INTEGRATION,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const pabblyLeadsValidateWebhook = createAsyncThunk(
  "integration/pabblyLeadsValidateWebhook",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: PABBLY_VALIDATE_LEADS_WEBHOOK_URL,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const pabblyUpdateLeadsIntegration = createAsyncThunk(
  "integration/pabblyUpdateLeadsIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: PABBLY_UPDATE_LEADS_INTEGRATION,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const msteamsGetIntegration = createAsyncThunk(
  "integration/msteamsGetIntegration",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: MSTEAMS_GET_INTEGRATION,
        method: METHOD_TYPES.GET,
        data,
      },
      helpers
    )
)

export const slackGetIntegration = createAsyncThunk(
  "channels/slackGetIntegration",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SLACK_GET_INTEGRATION,
        data,
      },
      helpers
    )
)

export const msteamsAddIntegration = createAsyncThunk(
  "integration/msteamsAddIntegration",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: MSTEAMS_ADD_INTEGRATION,
        data,
        method: METHOD_TYPES.POST
      },
      helpers
    ),
)

export const msteamsDeleteIntegration = createAsyncThunk(
  "integration/msteamsDeleteIntegration",
  async (data={}, helpers) => 
    fetchDataForToolkit(
      {
        url: MSTEAMS_DELETE_INTEGRATION,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
)
export const slackAppManifest = createAsyncThunk(
  "channels/slackAppManifest",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SLACK_APP_MANIFEST,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const slackVerifyEventUrl = createAsyncThunk(
  "channels/slackVerifyEventUrl",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SLACK_VERIFY_EVENT_URL.replace(":tenantId", data.tenantId).replace(":applicationId", data.applicationId),
        data,
      },
      helpers
    )
)

export const slackIntegrate = createAsyncThunk(
  "channels/slackIntegrate",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SLACK_INTEGRATE.replace(":applicationId", data.applicationId),
        data,
        method: METHOD_TYPES.POST,
      },
      helpers
    )
)

export const slackUpdate = createAsyncThunk(
  "channels/slackUpdate",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SLACK_INTEGRATE.replace(":applicationId", data.applicationId),
        data,
        method: METHOD_TYPES.PUT,
      },
      helpers
    )
)

export const slackDelete = createAsyncThunk(
  "channels/slackDelete",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: SLACK_INTEGRATE.replace(":applicationId", data.applicationId),
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
)

export const { updateChannelState, setVerificationStatus } = channelSlice.actions
export default channelSlice.reducer
