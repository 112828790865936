import React from 'react'
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"

import { useDispatch } from "react-redux"

import { updateAuthState } from "../../../data/store/authSlice"
import { LOCAL_STORAGE, ROUTE_PATH, THEME_MODE } from "../../../data/configs/constants"
import {
    clearAllDataFromLocalStorage,
    setDataInLocalStorage,
} from "../../../data/utils"

import { Box, Dialog, DialogContent, Grid, Skeleton, Typography, alpha } from '@mui/material'
import * as _ from 'lodash'
import { useSelector } from 'react-redux'
import { IMG_LOGO_DARK, IMG_LOGO_LIGHT } from '../../../data/assets'
import { useTheme } from '@emotion/react'

const CANCEL_COUNTDOWN = 5

const EnterpriseAuthentication = () => {
    const dashboardSmallLogo = useSelector(state => _.get(state, 'workspaceSettingsDetails.domainConfig.branding.logoUrlSmall', null))
    const organizationLabel = useSelector(state => _.get(state, 'workspaceSettingsDetails.domainConfig.branding.organizationLabel', ''))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    const queryParams = new URLSearchParams(window.location.search)
    const timeToCancel = queryParams.get("timeToCancel")
    const totalTime = Number(timeToCancel) || CANCEL_COUNTDOWN
    const [timeLeft, setTimeLeft] = React.useState(totalTime)

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(prev => prev - 1)
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (timeLeft === -1) {
            navigate(ROUTE_PATH.DASHBOARD)
        }
    }, [timeLeft, navigate])

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.has("token") && queryParams.has("userId")) {
            const token = queryParams.get("token")
            const userId = queryParams.get("userId")
            const isLoggedIn = true
            dispatch(updateAuthState({ token, userId, isLoggedIn }))
            setDataInLocalStorage(LOCAL_STORAGE.TOKEN, token)
            setDataInLocalStorage(LOCAL_STORAGE.USER_ID, userId)
            setDataInLocalStorage(LOCAL_STORAGE.IS_LOGGED_IN, isLoggedIn)
            setDataInLocalStorage(LOCAL_STORAGE.IS_OLD_USER, true)
        } else {
            enqueueSnackbar("Missing auth token", { variant: "error" })
            navigate(ROUTE_PATH.LOGIN)
        }
    }, [dispatch, navigate])

    const handleDashboardSwitch = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const token = queryParams.get("token")
        const userId = queryParams.get("userId")
        var domainUrl = `${process.env.REACT_APP_DASHBOARD_URL}${ROUTE_PATH.VERIFY_GOOGLE_AUTH}?token=${token}&userId=${userId}`;
        window.open(domainUrl, "_self");
    }

    return <Dialog open hideBackdrop maxWidth='sm' fullWidth PaperProps={{
        variant: 'outlined',
    }}>
        <DialogContent>
            <Grid container justifyContent='center' alignItems='center' spacing={1}>
                <Grid item>
                    <Box component={'img'}
                        borderRadius={'50%'}
                        src={theme.palette.mode === THEME_MODE.DARK ? IMG_LOGO_DARK : IMG_LOGO_LIGHT}
                        alt="logo" height={80} width={80}
                        border={`solid 8px ${theme.palette.background.paper}`}
                        sx={{
                            transform: 'translateX(10%)',
                        }} />
                    {dashboardSmallLogo ? (
                        <Box component={'img'}
                            borderRadius={'50%'}
                            src={dashboardSmallLogo} alt="logo"
                            height={80} width={80}
                            border={`solid 8px ${theme.palette.background.paper}`}
                            sx={{
                                transform: 'translateX(-10%)',
                            }} />
                    ) : <Skeleton variant="circular" width={80} height={80} sx={{ display: 'inline' }} />}
                </Grid>
                <Grid item xs={12} marginBottom={4}>
                    <Typography variant='h5' textAlign={'center'} gutterBottom>
                        Hold Tight! 🚀
                    </Typography>
                    <Typography textAlign={'center'}>
                        You're about to be whisked away to your enterprise dashboard  <Typography
                            display={'inline'}
                            textAlign={'center'}
                            fontWeight={700}
                            variant='inherit'
                            color={'primary.main'}
                            gutterBottom>
                            {organizationLabel}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ButtonWithPercentage
                        value={(1 - (timeLeft / totalTime)) * 100}
                        variant='outlined' size='large' color='error' fullWidth onClick={() => {
                            handleDashboardSwitch()
                        }}>
                        Cancel and go back
                    </ButtonWithPercentage>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}

const ButtonWithPercentage = ({ value = 0, color = "primary", children, onClick }) => {
    const theme = useTheme()
    return (
        <Box onClick={onClick} sx={{
            position: 'relative',
            border: `1px solid ${theme.palette[color].main}`,
            paddingX: 2,
            paddingY: 1,
            borderRadius: 1,
            backgroundColor: 'transparent',
            overflow: 'hidden',
            cursor: 'pointer'
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${value}%`,
                height: '100%',
                backgroundColor: alpha(theme.palette[color].main, 0.2),
                transition: `width 1s linear`,
            }} />
            <Typography color={color} textAlign={'center'} fontWeight={500} sx={{
                pointerEvents: 'none',
            }}>{children}</Typography>
        </Box>
    )
}

export default EnterpriseAuthentication