import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { fetchDataForToolkit } from "../helpers"
import initialState from "./initialState"
import { CREATE_DYNAMIC_LEAD, DELETE_DYNAMIC_LEAD, GET_DYNAMIC_LEAD_LIST, GET_LEAD_CAPTURE_LIST, GET_LEAD_DATA_FOR_CSV, UPDATE_DYNAMIC_LEAD, UPDATE_DYNAMIC_LEAD_LIST } from "./api"
import { METHOD_TYPES } from "../../configs/constants"

const leadCaptureSlice = createSlice({
  name: "leadCapture",
  initialState,
  reducers: {
    updateLeadCaptureState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeadCaptureList.fulfilled, (state, { payload }) => {
      if (payload?.data?.leads && payload?.data?.columns) {
        state.leadCaptureList = payload.data.leads
        state.columns = payload.data.columns
        state.totalCount = payload.data.totalCount
      }
    })
    .addCase(getDynamicLeads.fulfilled, (state, { payload }) => {
      if (payload?.data?.leadCaptureQueries) {
        state.dynamicLeadList = payload.data?.leadCaptureQueries
      }
    })
    .addCase(createDynamicLead.fulfilled, (state, { payload }) => {
      if (payload?.data?.newLeadCaptureQuery) {
        state.dynamicLeadList = [...state.dynamicLeadList, ...payload.data.newLeadCaptureQuery]
      }
    })
    .addCase(updateDynamicLead.fulfilled, (state, { payload }) => {
      if (payload?.data?.leadCaptureQueries) {
        const updatedItem = payload.data.leadCaptureQueries?.[0]
        state.dynamicLeadList = state.dynamicLeadList.map((item) => {
          if (item.variable?.name === updatedItem?.variable?.name) {
            return updatedItem
          }
          return item
        })
      }
    })
    .addCase(deleteDynamicLead.fulfilled, (state, { payload }) => {
      if (payload?.data?.deletedLeadQuery) {
        state.dynamicLeadList = payload.data.deletedLeadQuery
      }
    })
    .addCase(updateDynamicLeadsList.fulfilled, (state, { payload }) => {
      if (payload?.data?.leadCaptureQueries) {
        state.dynamicLeadList = payload.data.leadCaptureQueries
      }
    })
  },
})

export const getLeadCaptureList = createAsyncThunk(
  "leadCapture/getLeadCaptureList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_LEAD_CAPTURE_LIST,
        data,
      },
      helpers
    )
)

export const getLeadDataForCSV = createAsyncThunk(
  "leadCapture/getLeadDataForCSV",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_LEAD_DATA_FOR_CSV,
        data,
      },
      helpers
    )
)

export const getDynamicLeads = createAsyncThunk(
  "leadCapture/getDynamicLeads",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_DYNAMIC_LEAD_LIST,
        data,
      },
      helpers
    )
)

export const createDynamicLead = createAsyncThunk(
  "leadCapture/createDynamicLead",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: CREATE_DYNAMIC_LEAD,
        data,
        method: METHOD_TYPES.POST
      },
      helpers
    )
)

export const updateDynamicLead = createAsyncThunk(
  "leadCapture/updateDynamicLead",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_DYNAMIC_LEAD,
        data,
        method: METHOD_TYPES.PUT
      },
      helpers
    )
)

export const deleteDynamicLead = createAsyncThunk(
  "leadCapture/deleteDynamicLead",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DELETE_DYNAMIC_LEAD,
        data,
        method: METHOD_TYPES.DELETE
      },
      helpers
    )
)

export const updateDynamicLeadsList = createAsyncThunk(
  "leadCapture/updateDynamicLeadsList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_DYNAMIC_LEAD_LIST,
        data,
        method: METHOD_TYPES.PUT,
        loader: false
      },
      helpers
    )
)

export const { updateLeadCaptureState } = leadCaptureSlice.actions
export default leadCaptureSlice.reducer
