import { COLORS } from "../../configs/constants"

const initialState = {
  selectedMenuItem: "",
  userUpstreamList: [],
  allUpstreamStatus: {},
  configData: {},
  domainConfig:{},
  domainConfigInputData: {
    host: "",
    email: "",
    error: "",
  },
  branding: {
    selectedTab: null,
  },
  emailConfigInputData: {
    editMode: false,
    sender: "",
    receiver: "",
    error: "",
    mailForDnsRecords: "",
    emailDomain: "",
    loading: false
  },
  themeConfigInputData: {
    selectedPreset:{},
    primaryColor: COLORS.PRIMARY_MAIN,
    secondaryDarkColor: COLORS.SECONADRY_MAIN,
    secondaryLightColor: COLORS.PRIMARY_LIGHT,
    themeObject:{},
    loading: false,
    error:"",
  },
  infoConfigInputData: {
    organizationLabel: "",
    logoUrlLarge: "",
    logoUrlSmall: "",
    loading: false,
    error: "",
  },
  loginScreenConfigInputData: {
    loginHtml:"",
    backgroundImgUrl:"",
    loading: false,
    error: "",
  },
}

export default initialState