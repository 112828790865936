import clevertap from "clevertap-web-sdk"
import { log } from "."

export const loadCleverTapScript = (
    FILE_URL,
    token,
    async = true,
    type = "text/javascript"
) => {
    try {
        clevertap.privacy.push({ optOut: false }) // Set the flag to true, if the user of the device opts out of sharing their data
        clevertap.privacy.push({ useIP: false })
        clevertap.init(token)
        clevertap.spa = true
    } catch (error) {
        log("clevertapLoadScript Error", error)
    }
}

export const cleverTap = {
    onUserLogin: (data) => {
        if (
            process.env.REACT_APP_CLEVERTAP_ENABLE &&
            process.env.REACT_APP_CLEVERTAP_TOKEN
        )
            clevertap.onUserLogin.push({
                Site: data,
            })
    },
    track: (eventName, data) => {
        if (
            process.env.REACT_APP_CLEVERTAP_ENABLE &&
            process.env.REACT_APP_CLEVERTAP_TOKEN
        ) {
            window.clevertap.event.push(eventName, data)
        }
    },
    setUpNotification: ()=>{
        clevertap.notifications.push({
            titleText:'Would you like to receive Push Notifications?',
            bodyText:'We promise to only send you relevant content and give you updates on your transactions',
            okButtonText:'Sign me up!',
            rejectButtonText:'No thanks',
            okButtonColor:'#f28046',
            serviceWorkerPath:'/service-worker.js'
        });
    }
}