import { useState } from "react"
import { useDispatch } from "react-redux"
import { enqueueSnackbar } from "notistack"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Switch from "@mui/material/Switch"
import IconButton from "@mui/material/IconButton"
import ButtonBase from "@mui/material/ButtonBase"
import DialogContent from "@mui/material/DialogContent"
import Dialog from "@mui/material/Dialog"
import CloseIcon from "@mui/icons-material/Close"
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite"

import {
  IMG_BOT_CONVERSATION,
  IMG_LIVE_CHAT_BG,
} from "../../../../../data/assets"

import { updateLiveChatState } from "../../../../../data/store/liveChatSlice"
import { LiveChatEnabled } from "../../../../../data/store/overviewSlice"
import liveChatInitialState from "../../../../../data/store/liveChatSlice/initialState"
import { Grid } from "@mui/material"
import { StyledButton } from "../../../../components/StyledButton"

const LiveChatDisabled = ({ tenantId, active }) => {
  const dispatch = useDispatch()
  const [showDialog, setShowDialog] = useState(false)

  const handleCloseDialog = (e, reason) => {
    if (reason !== "backdropClick") setShowDialog(false)
  }

  const handleOpenDialog = (e, reason) => {
    if (process.env.REACT_APP_LIVE_CHAT_DEMO_VIDEO) setShowDialog(true)
    else
      enqueueSnackbar("Video url is missing", {
        variant: "error",
      })
  }

  const handleLiveChats = (event) => {
    dispatch(
      LiveChatEnabled({
        tenantId,
        status: true,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(updateLiveChatState(liveChatInitialState))
      })
  }

  return (
    <>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', padding: 2 }}
      >
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          sx={{
            backgroundImage: `url(${IMG_LIVE_CHAT_BG})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            filter: "blur(4px)",
            opacity: 0.3,
            zIndex: -1,
          }}
        />

        <Paper sx={{ p: 2, maxWidth: 600, boxShadow: 0, borderRadius: 2, paddingBlock: 4 }}>
          <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={9} sm={5} md={5}>
              <Box
                component="img"
                src={IMG_BOT_CONVERSATION}
                alt="enable-live-chat-popup"
                width="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign={'center'} maxWidth={500} margin={'auto'}>
                Enable human assist to engage with your customers in real-time
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <StyledButton size="large" variant="contained" color="primary" fullWidth onClick={handleLiveChats} sx={{ mb: 1 }}>
                Enable human assist
              </StyledButton>
              {process.env.REACT_APP_LIVE_CHAT_DEMO_VIDEO && (
                <StyledButton size="large" onClick={handleOpenDialog} variant="contained" color="primary"
                  endIcon={<PlayCircleFilledWhiteIcon />}
                  fullWidth>
                  Watch the video
                </StyledButton>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Dialog
        open={showDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <IconButton
          size="small"
          sx={{
            zIndex: 1,
            position: "absolute",
            right: 0,
            top: 0,
            backgroundColor: "background.paper",
            "&:hover": {
              backgroundColor: "background.default",
            },
          }}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 1.5 }}>
          <Box
            component="iframe"
            src={process.env.REACT_APP_LIVE_CHAT_DEMO_VIDEO}
            title="Orimon AI"
            width="100%"
            height={420}
            border={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LiveChatDisabled
