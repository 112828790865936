import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialState from "./initialState";
import { GET_LEAD_CAPTURE_DATA, 
    POST_LEAD_CAPTURE_DATA, 
    PUT_LEAD_CAPTURE_DATA, DELETE_LEAD_CAPTURE_DATA } from "./api";
import { fetchDataForToolkit } from "../helpers";
import { METHOD_TYPES } from "../../configs/constants";
import { enqueueSnackbar } from "notistack";

const automatedLeadSlice = createSlice({
    name: "leadCaptureEmail",
    initialState,
    reducers: {
        updateLeadCaptureEmailData: (state,action) => {
            if(action.payload.targetHour === "") action.payload.targetHour = null;
            if(action.payload.targetDay === "") action.payload.targetDay = null;
            return {
                ...state,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLeadCaptureData.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    ...payload?.data?.leadsEmailConfig
                }
            })

            .addCase(deleteLeadCaptureData.fulfilled, (state,{payload}) => {
                if(payload.status === "success") {
                    enqueueSnackbar("Deleted Successfully");
                    return {
                        ...state,
                        ...initialState
                    }
                }          
            })

            .addCase(deleteLeadCaptureData.rejected,() => {
                enqueueSnackbar("Something Went Wrong");
            })
    }
})

export const getLeadCaptureData = createAsyncThunk(
    "leadCapture/getLeadCaptureData",
    async (data = {}, helpers) => 
      fetchDataForToolkit(
        {
          url: GET_LEAD_CAPTURE_DATA,
          data
        },
        helpers
      )
  )

export const postLeadCaptureData = createAsyncThunk(
    "leadCapture/postLeadCaptureData",
    async (data, helpers) => 
        fetchDataForToolkit(
            {
                url: POST_LEAD_CAPTURE_DATA,
                data,
                method: METHOD_TYPES.POST
            },
            helpers
        )
)

export const putLeadCaptureData = createAsyncThunk(
    "leadCapture/putLeadCaptureData",
    async (data, helpers) => 
        fetchDataForToolkit(
            {
                url: PUT_LEAD_CAPTURE_DATA,
                data,
                method: METHOD_TYPES.PUT
            },
            helpers
        )
)

export const deleteLeadCaptureData = createAsyncThunk(
    "leadCapture/deleteLeadCaptureData",
    async (data,helpers) => 
        fetchDataForToolkit(
            {
                url: DELETE_LEAD_CAPTURE_DATA,
                data,
                method: METHOD_TYPES.DELETE
            },
            helpers
        )
)

export const { updateLeadCaptureEmailData } = automatedLeadSlice.actions
export default automatedLeadSlice.reducer