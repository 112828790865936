export const GET_FLAGGEDCHAT_LIST_FOR_ADMIN = "/bot/v2/superadmin/flaggedchat/list"
export const GET_FLAGGEDCHAT_LIST_FOR_USER = "/bot/v2/nlp/flaggedchat/list"
export const DELETE_FLAGGED_CHAT = "/bot/v2/nlp/flaggedchat/delete"
export const UPDATE_FLAGGED_CHAT = "/bot/v2/nlp/flaggedchat/update"
export const FETCH_QNA = "/ai/training/fetch-qna"

export const CREATE_FAQ = '/bot/v2/faq'
export const UPDATE_DELETE_FAQ = '/bot/v2/faq/:faqId'
export const GET_FAQ_LIST = '/bot/v2/faq/list'

export const FETCH_EMBEDDINGS = '/ai/training/generate-qna'