export const INTEGRATE_FACEBOOK_PAGES =
  "/bot/v2/channels/fb/integrateFacebookPageOfTenant"
export const GET_FACEBOOK_PAGES =
  "/bot/v2/channels/fb/getIntegratedPagesOfTenant"
export const TOGGLE_SUBSCRIPTION_OF_FACEBOOK_PAGE =
  "/bot/v2/channels/fb/changeActivationModeofPage"
export const INTEGRATE_TELEGRAM_BOT =
  "bot/v2/channels/telegram/v1/setTelegramWebHook"
export const TOGGLE_SUBSCRIPTION_OF_TELEGRAM_BOT =
  "/bot/v2/channels/telegram/v1/updateActiveStatus"
export const GET_TELEGRAM_BOT_INFO = "/bot/v2/channels/telegram/v1/getBotInfo"
export const DELETE_TELEGRAM_BOT = "/bot/v2/channels/telegram/v1/integration"
export const GET_CHANNELS_STATUS = "bot/v2/channels/manager/listChannels"
export const UPDATE_CHANNEL_STATUS = "bot/v2/channels/manager/changeChannelMode"
export const INTEGRATE_INSTAGRAM_PAGES =
  "/bot/v2/channels/fb/integrateInstagramPageOfTenant"
export const VERIFY_WHATSAPP_DETAILS = "/bot/v2/channels/whatsapp/getNumberInfo"
export const GET_INTEGRATED_WHATSAPP_LIST =
  "/bot/v2/channels/whatsapp/getIntegratedNumbers"
export const INTEGRATE_WHATSAPP_NUMBER =
  "/bot/v2/channels/whatsapp/integrateWhatsappNumber"
export const DELETE_WHATSAPP_NUMBER =
  "/bot/v2/channels/whatsapp/deleteIntegratedNumber"
export const UPDATE_WHATSAPP_NUMBER_STATUS =
  "/bot/v2/channels/whatsapp/changeActivationMode"
export const GBM_GET_INTEGRATED_GBM_STATUS = "/bot/v2/channels/gbm/getIntegratedGbmOfTenant";
export const GBM_INTEGRATE_GBM = "/bot/v2/channels/gbm/integrateGbmOfTenant";
export const GBM_UPLOAD_GBM_IMG = "/bot/v2/channels/gbm/logoUploadGbm/:tenantId";
export const GBM_DELETE_GBM = "/bot/v2/channels/gbm/deleteIntegratedGbmOgTenant";
export const GBM_ADD_GBM_LOCATION = "/bot/v2/channels/gbm/createLocationGbmOfTenant";
export const GBM_GET_GBM_LOCATION_LIST = "/bot/v2/channels/gbm/getLocationsGbmOfTenant";
export const GBM_DELETE_GBM_LOCATIONS = "/bot/v2/channels/gbm/deleteLocationsGbmOfTenant";
export const GBM_REQUEST_GBM_LOCATION_VERIFY = "/bot/v2/channels/gbm/requestLocationVerificationGbm";
export const GBM_REQUEST_GBM_AGENT_VERIFICATION = "/bot/v2/channels/gbm/requestAgentVerificationGbm";
export const GBM_GET_NON_LOCAL_REGIONS = "bot/v2/channels/gbm/getNonLocalRegionsGbm";
export const GBM_REQUEST_NON_LOCAL_LAUNCH = "bot/v2/channels/gbm/requestNonLocalLaunchGbm";
export const GBM_REQUEST_NON_LOCAL_UNLAUNCH = "bot/v2/channels/gbm/requestNonLocalUnLaunchGbm";
export const GBM_REQUEST_LOCATION_LAUNCH = "bot/v2/channels/gbm/requestLocationLaunchGbm";
export const GBM_REQUEST_LOCATION_UNLAUNCH = "bot/v2/channels/gbm/requestLocationUnlaunchGbm";
export const GBM_REFRESH_STATUSES = "bot/v2/channels/gbm/refreshVerificationAndLaunchStatuses";
  
export const DISCORD_GET_ALL_BOTS = "/bot/v2/channels/discord/getIntegratedBots"
export const DISCORD_UPDATE_COMMAND = "/bot/v2/channels/discord/updateCommand"
export const DISCORD_ADD_NEW_COMMAND = "/bot/v2/channels/discord/addCommand"
export const DISCORD_DELETE_COMMAND = "/bot/v2/channels/discord/deleteCommand"
export const DISCORD_DELETE_BOT = "/bot/v2/channels/discord/deleteBot"
export const DISCORD_INTEGRATE_BOT = "/bot/v2/channels/discord/integrateDiscordBot"
export const DISCORD_GET_BOT_INFO = "/bot/v2/channels/discord/getBotInfo"
export const DISCORD_CHANGE_ACTIVATION_STATE = "/bot/v2/channels/discord/changeActivationMode"

export const ZAPIER_GET_API_KEY = "/bot/v2/integrations/zapier/auth/api-key"
export const ZAPIER_GET_STATUS = "/bot/v2/integrations/zapier/auth/status"
export const ZAPIER_DELETE_API_KEY = "/bot/v2/integrations/zapier/auth/api-key"
export const ZAPIER_ADD_LEADS_WEBHOOK_URL = "/bot/v2/integrations/zapier/leads/add-webhook"
export const ZAPIER_UPDATE_MESSAGE_INTEGRATION = "/bot/v2/integrations/zapier/message/update"
export const ZAPIER_VALIDATE_LEADS_WEBHOOK_URL = "/bot/v2/integrations/zapier/leads/validate-webhook"
export const ZAPIER_UPDATE_LEADS_INTEGRATION = "/bot/v2/integrations/zapier/leads/status"

export const PABBLY_GET_API_KEY = "/bot/v2/integrations/pabbly/auth/api-key"
export const PABBLY_GET_STATUS = "/bot/v2/integrations/pabbly/auth/status"
export const PABBLY_DELETE_API_KEY = "/bot/v2/integrations/pabbly/auth/api-key"
export const PABBLY_ADD_LEADS_WEBHOOK_URL = "/bot/v2/integrations/pabbly/leads/add-webhook"
export const PABBLY_UPDATE_MESSAGE_INTEGRATION = "/bot/v2/integrations/pabbly/message/update"
export const PABBLY_VALIDATE_LEADS_WEBHOOK_URL = "/bot/v2/integrations/pabbly/leads/validate-webhook"
export const PABBLY_UPDATE_LEADS_INTEGRATION = "/bot/v2/integrations/pabbly/leads/status"

export const MSTEAMS_GET_INTEGRATION = "bot/v2/channels/msteam/getIntegratedMsteamOfTenant"
export const MSTEAMS_ADD_INTEGRATION = "bot/v2/channels/msteam/IntegrateMsteamOfTenant"
export const MSTEAMS_DELETE_INTEGRATION = "bot/v2/channels/msteam/deleteIntegratedMsteamOfTenant"
export const SLACK_GET_INTEGRATION = "/bot/v2/channels/slack/integration"
export const SLACK_APP_MANIFEST = "/bot/v2/channels/slack/integration/appManifest"
export const SLACK_VERIFY_EVENT_URL = "/bot/v2/channels/slack/integration/eventUrl/:applicationId"
export const SLACK_INTEGRATE = "/bot/v2/channels/slack/integration/:applicationId"
