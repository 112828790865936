import { getDataFromLocalStorage, getDeviceData } from "../../utils"
import { LOCAL_STORAGE, MAINTENANCE_STATUS, THEME_MODE } from "../../configs/constants"

const initialState = {
  themeMode: getDataFromLocalStorage(
    LOCAL_STORAGE.THEME,
    THEME_MODE.SYSTEM
    // window.matchMedia("(prefers-color-scheme: dark)").matches
    //   ? THEME_MODE.DARK
    //   : THEME_MODE.LIGHT
  ),
  applySystemTheme: getDataFromLocalStorage(
    LOCAL_STORAGE.THEME,
    THEME_MODE.SYSTEM
  ) === THEME_MODE.SYSTEM,
  theme:null,
  deviceData: getDeviceData(),
  showTenantHeader: false,
  currentPage: "",
  currentSiderModule: "",
  badgeOnSiderModule: {},
  siderCollapse: getDataFromLocalStorage(LOCAL_STORAGE.SIDER_COLLAPSE, false),
  profileModalState: getDataFromLocalStorage(LOCAL_STORAGE.PROFILE_MODAL_STATE, false),
  pageLoading: false,
  progressPercent: null,
  pageLoaderText: "",
  modalLoading: false,
  authLoading: false,
  showCookiePopup: getDataFromLocalStorage(
    LOCAL_STORAGE.SHOW_COOKIE_STATUS,
    true
  ),
  systemTemplates: [],
  maintenanceInfo: {
    showMaintenanceBanner: false,
    info: {},
    servicesUnderMaintenance: [],
    status: MAINTENANCE_STATUS.PENDING
  },
  currentPageAcl: {},
  bannerNotifications: {
    notifications:[]
  },
  subscriptionPortalInfo : [],
  addOnPortalInfo : [],
  planUpdationPageData : {},
  invoicePreviewData : {},
  promoCode: {},
  invoice : {
    invoiceData : [],
    lastFetchedInvoiceId : '',
    hasMoreInvoice : false
  },
  enterpriseData : {},
  pausePopups: false
}

export default initialState
