import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { fetchDataForToolkit } from "../helpers"
import initialState from "./initialState"
import { GET_FEEDBACK_LIST_FOR_ADMIN, GET_FEEDBACK_LIST_FOR_USER } from "./api"

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    updateFeedbackState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeedbackList.fulfilled, (state, { payload }) => {
      if (payload.data) {
        state.feedbackList = payload.data.feedbacks
        state.totalCount = payload.data.totalCount
      }
    })
  },
})

export const getFeedbackList = createAsyncThunk(
  "feedback/getFeedbackList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: data.isAdmin
          ? GET_FEEDBACK_LIST_FOR_ADMIN
          : GET_FEEDBACK_LIST_FOR_USER,
        data: data.payload,
      },
      helpers
    )
)

export const { updateFeedbackState } = feedbackSlice.actions
export default feedbackSlice.reducer
