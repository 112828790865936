import { enqueueSnackbar } from "notistack"

import { resetReduxState, resetTenantState } from "."
import { setDataInLocalStorage } from "../../utils"
import { LOCAL_STORAGE, ROUTE_PATH } from "../../configs/constants"
import { history } from "../helpers"

const middleware = (store) => (next) => {
  const initialReduxState = store.getState()
  return (action) => {
    if (action?.error) {
      if (action?.payload?.status === 401) {
        setDataInLocalStorage(LOCAL_STORAGE.LAST_ROUTE, window.location.pathname)
        store.dispatch(resetReduxState())
      }
      else if (action.payload?.data?.message || action.payload?.data?.data?.error) {
        enqueueSnackbar(action.payload?.data?.data?.error || action.payload?.data?.message, { variant: "error" })
      }
      if(action?.payload?.data?.data?.errorAction === "subscription_redirect" && history.navigate)
        history.navigate(ROUTE_PATH.MAIN_PRICING)
    }

    if (resetTenantState.match(action)) {
      const currentState = store.getState()
      const actionWithInitialTenantState = {
        ...action,
        payload: {
          ...currentState,
          overviewDetails: initialReduxState.overviewDetails,
          liveChatDetails: initialReduxState.liveChatDetails,
          chatHistoryDetails: initialReduxState.chatHistoryDetails,
          feedbackDetails: initialReduxState.feedbackDetails,
          leadCaptureDetails: initialReduxState.leadCaptureDetails,
          trainingDetails: initialReduxState.trainingDetails,
          channelDetails: initialReduxState.channelDetails,
        },
      }
      return next(actionWithInitialTenantState)
    } else if (resetReduxState.match(action)) {
      const currentState = store.getState()
      const actionWithInitialState = {
        ...action,
        payload: {
          ...initialReduxState,
          pageDetails: {
            ...initialReduxState.pageDetails,
            themeMode: currentState.pageDetails.themeMode,
            theme: currentState.pageDetails.theme,
          },
          authDetails: {
            ...initialReduxState.authDetails,
            userId: "",
            isLoggedIn: false,
            token: "",
            logoutSuccess: action?.payload?.logoutSuccess,
          },
        },
      }
      return next(actionWithInitialState)
    }

    next(action)
  }
}

export default middleware
