import { Box, IconButton, Typography } from "@mui/material";
import { copyToClipboard } from "../../../data/utils";

const CodeViewer = ({ code, copyCode }) => {
  return (
    <Box
      border={1}
      borderRadius={1}
      backgroundColor="background.default"
      position="relative"
      borderColor="action.selected"
      px={1.5}
      mb={2}
      sx={{
        wordBreak: "break-word",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ wordBreak: "break-all" }}
      >
        {code}
      </Typography>
      <IconButton
        size="small"
        sx={{ position: "absolute", top: 5, right: 5 }}
        onClick={() => copyToClipboard(copyCode)}
      >
        <i class="ri-file-copy-line" style={{ fontSize: "inherit" }}></i>
      </IconButton>
    </Box>
  );
};
export default CodeViewer;
