import { useState, useEffect } from "react"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { enqueueSnackbar } from "notistack"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import Stack from "@mui/material/Stack"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Switch from "@mui/material/Switch"
import Chip from "@mui/material/Chip"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import InputLabel from "@mui/material/InputLabel"

import { DAYS } from "../../../data/configs/constants"
import StyledSwitch from "../StyledSwitch"
import Paper from "@mui/material/Paper";

dayjs.extend(utc)
dayjs.extend(timezone)

const DEFAULT_VALUE = {
  enable: false,
  shiftStart: dayjs().startOf("day").valueOf(),
  shiftEnd: dayjs().endOf("day").valueOf(),
  selectedDays: [0, 1, 2, 3, 4, 5, 6],
  timezone: dayjs.tz.guess(),
  text: "We are currently unavailable to take your chat request. Please contact us during working hours from Monday to Sunday between 7:00 AM to 12:00 AM.",
}

const OperationalHourSettings = ({ data, onChange }) => {
  const [updatedData, setUpdatedData] = useState({ ...DEFAULT_VALUE, ...data })
  const [error, setError] = useState(false)
  const [timeError, setTimeError] = useState(false)

  useEffect(() => {
    setUpdatedData({ ...DEFAULT_VALUE, ...data })
  }, [data])

  const handleSwitch = (event) => {
    if (event.target.checked) {
      setUpdatedData({
        ...updatedData,
        enable: true,
      })
    } else {
      onChange({
        operationalHourSettings: {
          ...data,
          enable: false,
        },
      })
    }
  }

  const handleInputChange = (changedValues) => {
    if (changedValues?.text) {
      setError(false)
    }
    setUpdatedData({
      ...updatedData,
      ...changedValues,
    })
  }

  const handleSave = () => {
    if (isNaN(updatedData.shiftStart) || isNaN(updatedData.shiftEnd) || timeError) {
      enqueueSnackbar("Selected Time is Invalid or start time is greater than end time")
    } else if (updatedData.selectedDays.length === 0) {
      enqueueSnackbar("You need to select atleast one day")
    } else if (!updatedData.text) {
      setError(true)
    } else onChange({ operationalHourSettings: updatedData })
  }

  const handleDaysSelect = (value) => {
    if (updatedData.enable) {
      if (updatedData?.selectedDays?.includes(value)) {
        setUpdatedData({
          ...updatedData,
          selectedDays: updatedData.selectedDays.filter(
            (item) => item !== value
          ),
        })
      } else {
        setUpdatedData({
          ...updatedData,
          selectedDays: [...updatedData.selectedDays, value],
        })
      }
    }
  }

  return (
    <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default', boxShadow: 0 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle1" fontWeight="fontWeightMedium" mb={1}>
          Operational Hours Settings
        </Typography>
        <FormControlLabel
          sx={{ mr: 0 }}
          control={
            <StyledSwitch
              size="small"
              inputProps={{ "aria-label": "controlled" }}
              checked={updatedData?.enable}
              onChange={handleSwitch}
            />
          }
          label="Enable"
        />
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap my={1}>
          <FormControl size="small" sx={{ flex: 1, minWidth: 200 }}>
            <InputLabel id="time-zone-select">Time zone</InputLabel>
            <Select
              labelId="time-zone-select"
              label="Time zone"
              inputProps={{ "aria-label": "Without label" }}
              value={updatedData?.timezone}
              onChange={(e) => handleInputChange({ timezone: e.target.value })}
              disabled={!updatedData?.enable}
              required
            >
              {Intl.supportedValuesOf("timeZone").map((timezone) => (
                <MenuItem key={timezone} value={timezone}>
                  {timezone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
            <TimePicker
              label={`Shift Start (${updatedData.timezone})`}
              disabled={!updatedData?.enable}
              value={dayjs(updatedData?.shiftStart)}
              timezone={updatedData.timezone}
              maxTime={dayjs(updatedData.shiftEnd)}
              onChange={(value) =>
                handleInputChange({ shiftStart: dayjs(value).valueOf() })
              }
              onError={(error) => setTimeError(Boolean(error))}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { flex: 1, minWidth: 200 },
                  inputProps: {
                    readOnly: true,
                  },
                },
              }}
            />
            <TimePicker
              label={`Shift End (${updatedData.timezone})`}
              disabled={!updatedData?.enable}
              value={dayjs(updatedData?.shiftEnd)}
              timezone={updatedData.timezone}
              minTime={dayjs(updatedData.shiftStart)}
              onError={(error) => setTimeError(Boolean(error))}
              onChange={(value) =>
                handleInputChange({ shiftEnd: dayjs(value).valueOf() })
              }
              slotProps={{
                textField: {
                  size: "small",
                  sx: { flex: 1, minWidth: 200 },
                },
              }}
            />
          </Stack>
        </Stack>
        <Typography variant="caption" color="text.secondary" mb={2}>
          Note: Please take into consideration the time-zone of the live agent
          while setting the active hours.
        </Typography>
      </LocalizationProvider>
      <Typography variant="subtitle2">Days</Typography>
      <Stack my={1} direction="row" flexWrap="wrap" spacing={1} useFlexGap>
        {DAYS.map((item) => {
          return (
            <Chip
              key={item.value}
              label={item.label}
              variant="filled"
              color={
                !updatedData.enable
                  ? "default"
                  : updatedData?.selectedDays?.includes(item.value)
                  ? "primary"
                  : "default"
              }
              sx={{
                cursor: updatedData.enable ? "pointer" : "not-allowed",
                textTransform: "uppercase",
              }}
              onClick={() => handleDaysSelect(item.value)}
            />
          )
        })}
      </Stack>
      <Typography variant="subtitle2" my={1}>
        Non operational hour message
      </Typography>
      <TextField
        placeholder="Customer will see this message during non operational hour while requesting live chat"
        size="small"
        disabled={!updatedData?.enable}
        value={updatedData?.text}
        error={error}
        helperText={error ? "This cannot be empty" : ""}
        onChange={(e) => handleInputChange({ text: e.target.value })}
        fullWidth
        multiline
        minRows={2}
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          disabled={!updatedData?.enable}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Paper>
  )
}

OperationalHourSettings.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

OperationalHourSettings.defaultProps = {
  data: {},
}

export default OperationalHourSettings
