import DashboardIcon from "@mui/icons-material/Dashboard"
import HistoryIcon from "@mui/icons-material/History"
import ModelTrainingIcon from "@mui/icons-material/ModelTraining"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import ReviewsIcon from "@mui/icons-material/Reviews"
import ThreePIcon from "@mui/icons-material/ThreeP"
import React from "react"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import {
  IMG_WEBHOOK,
  IMG_WEBFLOW,
  IMG_HUBSPOT,
  IMG_SALESFORCE,
  IMG_GOOGLESHEETS,
  IMG_ZAPIER,
  IMG_PABBLY,
  IMG_MSTEAMS,
  IMG_SLACK,
  IMG_GOOGLEDOCS
} from "../assets"
import SettingsIcon from "@mui/icons-material/Settings";
import { StyledChips } from "../../App/components/StyledChips"
import { Box, IconButton, Typography } from "@mui/material"
import { copyToClipboard } from "../utils"
import CodeViewer from "../../App/components/CodeViewer"

export const THEME_MODE = Object.freeze({
  LIGHT: "light",
  DARK: "dark",
  SYSTEM: "system"
})

export const COLORS = Object.freeze({
  PRIMARY_MAIN: "#000000",
  PRIMARY_DARK: "#0076D6",
  PRIMARY_LIGHT: "#0076D6",
  SECONDARY_DARK: "#3BA66B",
  SECONDARY_LIGHT: "#6B3BA6",
  TERTIARY_DARK: "#76D600",
  TERTIARY_LIGHT: "#D60076",
})

export const BREAKPOINT_VALUES = Object.freeze({
  xs: 0,
  sm: 600,
  lg: 1200,
  md: 900,
  xl: 1536,
})

export const MESSAGE_SENDER = Object.freeze({
  AGENT: "admin",
  USER: "user",
  CHATBOT: "bot",
  SYSTEM: "system",
})

export const METHOD_TYPES = Object.freeze({
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
})

export const LOCAL_STORAGE = Object.freeze({
  APP_VERSION: "od-app-version",
  THEME: "od-theme-mode",
  SIDER_COLLAPSE: "od-sider-collapse",
  IS_OLD_USER: "od-old-user",
  TOKEN: "od-oauth-token",
  USER_ID: "od-uid",
  COUNTRY: "od-country-code",
  IP_META: "od-platform-meta",
  IS_LOGGED_IN: "od-loggedin",
  WEBSITE_URL: "od-website-url",
  FEEDBACK_REQUIRE: "od-feedback-require",
  INSTAGRAM_TENANT_ID: "od-instagram-tenantid",
  LCS_UNREAD_MESSAGE_COUNT_MAP: "od-lcs-unread-message-count",
  PROFILE_MODAL_STATE : "od-profile-modal-state",
  SHOW_COOKIE_STATUS: "od-show-cookie-status",
  LAST_ROUTE: "od-last-route",
  UTM_PARAMS : 'od-utm-params',
  DOMAIN_CONFIG: 'od-domain-config',
  SHOW_LEAD_QUALIFICATION_POPOVER: 'od-show-lead-qualification-popover',
  DYNAMIC_POPUP_SEEN_COUNT_DATA: "od-popup-shown-count-data",
  AUTH_TYPE: 'od-auth-type',
  AUTH_METHOD: 'od-auth-method',
  USER_BANNER_DATA: 'od-user-banner-data',
  GUIDED_JOURNEY_STATE: 'od-guided-journey-state',
  COMPLETED_GUIDED_JOURNEY: 'od-completed-guided-journey',  
  HAS_CREATED_FIRST_BOT: 'od-has-created-first-bot',
  EXIT_INTENT_FORM_SUBMITTED: 'od-exit-intent-form-submitted',
  HAS_SEEN_STARTER_LTD: 'od-has-seen-starter-ltd',
  SKIP_ONBOARDING: 'od-skip-onboarding',
})

export const SESSION_STORAGE = Object.freeze({
  AUTOSAVE_LIVE_CHAT_MESSAGES: "od-autosave-live-chat-messages",
})

export const ROUTE_PATH = Object.freeze({
  //-------------- WEBSITE ROUTES --------------
  WEBSITE_HOME: "/",
  WEBSITE_PRICING: "/pricing",
  WEBSITE_FEATURED_BOTS: "/featured-bots",
  WEBSITE_PRIVACY_POLICY: "/privacy-policy",
  //---------------- AUTH ROUTES ----------------
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_EMAIL: "/verify-email",
  VERIFY_GOOGLE_AUTH: "/verify-google-auth",
  VERIFY_ENTERPRISE_AUTH: "/verify-enterprise-auth",
  VERIFY_INSTAGRAM_AUTH: "/app/verify-instagram-auth",
  VERIFY_GBM_AUTH: "/app/gbm-redirect",
  VERIFY_HUBSPOT: "/app/verify-hubspot",
  VERIFY_SALESFORCE: "/app/verify-salesforce",
  VERIFY_GOOGLE_SHEETS: "/app/verify-sheets",

  //---------------- APP REDIRECT ROUTES ----------------
  REDIRECT_SHOPIFY: "/app/onboarding/create-ecommerce-bot/shopify/redirect",

  //---------------- APP ROOT ROUTES ----------------

  DASHBOARD: "/app",
  TRIAL_PRICING: "/app/pricing",
  MAIN_PRICING: "/app/pricing",
  PROFILE: "/app/profile",
  WORKSPACE_SETTINGS: "/app/workspace-settings",
  ONBOARDING: "/app/onboarding",
  BUY_MORE: "/app/buy-more",
  THANK_YOU: "/app/thank-you",
  SUPPORT: "/app/support",
  REFERRAL: "/app/referral",
  CREATE_BOT: "/app/onboarding/create-bot",
  CREATE_E_COMMERCE_BOT: "/app/onboarding/create-e-commerce-bot",
  HUBSPOT: "/app/tenant/:tenantId/settings/integrations?integration=hubspot",
  GOOGLESHEETS: "/app/tenant/:tenantId/settings/integrations?integration=googlesheets",
  SALESFORCE: "/app/tenant/:tenantId/settings/integrations?integration=salesforce",
  WELCOME_ONBOARDING: "/app/welcome",

  //------------- APP BILLING ROUTES ------------

  BILLING_HOME: "/app/billing",

  //---------------- APP SELECTED WORKSPACE SETTINGS ROUTE ----------------
  WORKSPACE_GENERAL_SETTINGS: "/app/workspace-settings/general",
  WORKSPACE_BRANDING_SETTINGS: "/app/workspace-settings/branding",
  WORKSPACE_DASHBOARD_SETTINGS: "/app/workspace-settings/domain/dashboard",
  WORKSPACE_BOT_SETTINGS: "/app/workspace-settings/domain/bot",
  WORKSPACE_BRANDING_SETTINGS_EMAIL: "/app/workspace-settings/branding/email",
  WORKSPACE_BRANDING_SETTINGS_APPEARANCE: "/app/workspace-settings/branding/appearance",
  WORKSPACE_BRANDING_SETTINGS_COMPANY_DETAILS: "/app/workspace-settings/branding/company-details",

  //---------------- APP SELECTED TENANT ROUTES ----------------

  TENANT_OVERVIEW: "/app/tenant/:tenantId",
  TENANT_EDIT_KNOWLEDGE: "/app/tenant/:tenantId/edit-knowledge",
  TENANT_TEST_BOT: "/app/tenant/:tenantId/test-bot",
  TENANT_ROUTE_BASED_NOTIFICATIONS:
    "/app/tenant/:tenantId/bot-appearance/advance",
  TENANT_LIVE_CHATS: "/app/tenant/:tenantId/live-chats",
  TENANT_CHAT_HISTORY: "/app/tenant/:tenantId/chat-history",
  TENANT_TRAINING: "/app/tenant/:tenantId/training",
  TENANT_LEAD_CAPTURE: "/app/tenant/:tenantId/lead",
  TENANT_FEEDBACK: "/app/tenant/:tenantId/feedback",
  TENANT_SETTINGS: "/app/tenant/:tenantId/settings",
  TENANT_MEMBER_SETTINGS: "/app/tenant/:tenantId/settings/members",
  TENANT_BOT_APPEARANCE: "/app/tenant/:tenantId/settings/bot-appearance",
  TENANT_HUMAN_ASSIST_SETTINGS: "/app/tenant/:tenantId/settings/human-assist",
  TENANT_LEADS_QUALIFICATION: "/app/tenant/:tenantId/settings/leads",
  TENANT_INTEGRATIONS: "/app/tenant/:tenantId/settings/integrations",
  TENANT_CHANNELS: "/app/tenant/:tenantId/channels",
  TENANT_TEMPLATES: "/app/tenant/:tenantId/settings/templates",
  TENANT_QUOTA: "/app/tenant/:tenantId/settings/quota",
})

export const APP_PAGES = {
  WEBSITE_HOME: "Home",
  WEBSITE_PRICING: "Pricing",
  WEBSITE_FEATURED_BOT: "Featured Bot",
  WEBSITE_PRIVACY_POLICY: "Privacy Policy",

  DASHBOARD: "My Chatbots",
  BILLING: "Billing",
  ONBOARDING: "Onboarding",
  MAIN_PRICING: "Pricing",
  BILLING_OVERVIEW: "My Billings",
  TRIAL_PRICING: "Trial Pricing",
  LOGIN: "Login",
  SIGNUP: "Signup",
  FORGOT_PASSWORD: "Forgot Password",
  VERIFY_EMAIL: "Verify Email",
  PROFILE: "Profile",
  SUPPORT: "Support",
  SETTINGS: "Settings",
  DEPLOY: "Launch",
  TENANT_OVERVIEW: "Dashboard",
  TENANT_EDIT_KNOWLEDGE: "Resources",
  TENANT_TEST_BOT: "Test Bot",
  TENANT_BOT_APPEARANCE: "Bot Appearance",
  TENANT_LIVE_CHATS: "Live Chats",
  TENANT_CHAT_HISTORY: "Chat History",
  TENANT_TRAINING: "Test & Train",
  TENANT_LEAD_CAPTURE: "Leads",
  TENANT_FEEDBACK: "Bot Feedback",
  TENANT_SETTINGS: "AI Settings",
  TENANT_MEMBERS: "Team Members",
  TENANT_CHANNELS: "Launch",
  TENANT_HUMAN_ASSIST_SETTINGS: "Human Assist",
  TENANT_ROUTE_BASED_NOTIFICATIONS: "Advance",
  TENANT_INTEGRATIONS: "LeadFlow Integrations",
  TENANT_LEADS_QUALIFICATION: "Leads Generation",
  TENANT_TEMPLATES: "Templates",
  TENANT_QUOTA: "Quota Management",
  BUY_MORE: "Account Top Up"
}

export const TENANT_SIDER_LIST = [
  {
    icon: <DashboardIcon />,
    appPage: APP_PAGES.TENANT_OVERVIEW,
    route: ROUTE_PATH.TENANT_OVERVIEW,
  },
  {
    icon: <ThreePIcon />,
    appPage: APP_PAGES.TENANT_LIVE_CHATS,
    route: ROUTE_PATH.TENANT_LIVE_CHATS,
  },
  {
    icon: <HistoryIcon />,
    appPage: APP_PAGES.TENANT_CHAT_HISTORY,
    route: ROUTE_PATH.TENANT_CHAT_HISTORY,
  },
  {
    icon: <ModelTrainingIcon />,
    appPage: APP_PAGES.TENANT_TRAINING,
    route: ROUTE_PATH.TENANT_TRAINING,
  },{
    icon: <ModelTrainingIcon />,
    appPage: APP_PAGES.TENANT_EDIT_KNOWLEDGE,
    route: ROUTE_PATH.TENANT_EDIT_KNOWLEDGE,
  },
  {
    icon: <GroupAddIcon />,
    appPage: APP_PAGES.TENANT_LEAD_CAPTURE,
    route: ROUTE_PATH.TENANT_LEAD_CAPTURE,
  },
  {
    icon: <ReviewsIcon />,
    appPage: APP_PAGES.TENANT_FEEDBACK,
    route: ROUTE_PATH.TENANT_FEEDBACK,
  },
  {
    icon: <SettingsIcon/>,
    appPage: APP_PAGES.SETTINGS,
    route: ROUTE_PATH.TENANT_SETTINGS
  },
  {
    icon: <SettingsIcon/>,
    appPage: APP_PAGES.TENANT_CHANNELS,
    route: ROUTE_PATH.TENANT_CHANNELS
  }
]

export const TENANT_SETTINGS_SIDER_LIST = [
  {
    icon: <i className="ri-settings-line"></i>,
    appPage: APP_PAGES.TENANT_SETTINGS,
    route: ROUTE_PATH.TENANT_SETTINGS,
  },
  {
    icon: <i className="ri-map-pin-user-line"></i>,
    appPage: APP_PAGES.TENANT_MEMBERS,
    route: ROUTE_PATH.TENANT_MEMBER_SETTINGS,
  },
  {
    icon: <i className="ri-brush-line"></i>,
    appPage: APP_PAGES.TENANT_BOT_APPEARANCE,
    route: ROUTE_PATH.TENANT_BOT_APPEARANCE,
  },
  {
    icon: <i className="ri-nurse-line"></i>,
    appPage: APP_PAGES.TENANT_HUMAN_ASSIST_SETTINGS,
    route: ROUTE_PATH.TENANT_HUMAN_ASSIST_SETTINGS,
  },
  // {
  //   icon: <IntegrationInstructionsIcon />,
  //   appPage: APP_PAGES.TENANT_CHANNELS,
  //   route: ROUTE_PATH.TENANT_CHANNELS,
  // },
  {
    icon: <i class="ri-bubble-chart-line"></i>,
    appPage: APP_PAGES.TENANT_LEADS_QUALIFICATION,
    route: ROUTE_PATH.TENANT_LEADS_QUALIFICATION,
  },
  {
    icon: <i className="ri-command-line"></i>,
    appPage: APP_PAGES.TENANT_INTEGRATIONS,
    route: ROUTE_PATH.TENANT_INTEGRATIONS,
  },
  {
    icon: <i className="ri-pages-line"></i>,
    appPage: APP_PAGES.TENANT_TEMPLATES,
    route: ROUTE_PATH.TENANT_TEMPLATES,
  },
  {
    icon: <i class="ri-donut-chart-fill"></i>,
    appPage: APP_PAGES.TENANT_QUOTA,
    route: ROUTE_PATH.TENANT_QUOTA,
  }
]

// export const TRAINING_LOADER_TEXT = [
//   "Learning about your company",
//   "Understanding your business domain",
//   "Curating your objectives",
//   "Your best sales man is suiting up",
// ]

export const CHANNEL_INTEGRATION_STEPS = {
  website: [
    "Copy the above code snippet.",
    "To install chatbot, you can place this code before the </body> tag.",
    "Thats it your bot is ready to go.",
  ],
  wordpress: [
    "Add orimon-chatbot plugin from the wordpress plugin store",
    "Install and activate the plugin",
    "Go to settings on the side navigation bar, you will find orimon chatbot",
    "Click on orimon chatbot",
    "Copy the chatbot id from here",
    "Click on enable bot",
    "Save the settings",
    "Your bot is ready to use",
  ],
  facebook: [
    "Make sure you have admin access to your Facebook business page.",
    "Click on Start Setup. Log in to your Facebook account.",
    "Select the Facebook business pages you want to connect to platform.",
  ],
  telegram: [
    "Click on the Above Link",
    "Send /start to the BotFather",
    "Choose /newbot to create new bot",
    "Choose a name for your bot",
    "Choose a unique Username for your bot that must end with the word bot",
    "You will recieve a token, Use this token below to integrate your telegram bot",
  ],
  zapier: [
    "Create an account on Zapier.",
    "Create a Zap between 'Webhooks by Zapier' and the desired service.",
    "Select Catch Hook as the trigger and an action based on the service you're integrating with.",
    "You will receive a webhook URL by Zapier, copy and paste it below.",
    "Also fill the service your are integrating and click on integrate.",
    "We'll send a sample request on zapier which you need to accept in order to get all the leads.",
  ],
  webhook: [
    "You need to give us a link to your server.",
    "We should be able to send (POST request) data to your server using the link. This is us handing over some important stuff (leads data) for you to keep safe.",
    "Once you've given us the link to your server and we've ensured that it returns 200 status on POST request, we'll give all the existing leads data (like a list of potential customers) we have to your server. If all this happens smoothly, it means you've successfully connected (integrated) your bot with our system. Just remember, the same link should be used to both check your ID (GET request) and to hand over the data (POST request).",
  ],
  instagram: [
    "Make sure you have a Facebook Page and an Instagram Business Page that is linked with the Facebook Page. ",
    "Click on Start Setup. Log in to your Instagram account.",
    "Select the Instagram business pages you want to connect to platform.",
  ],
  webflow: [
    <React.Fragment>Begin by accessing the Orimon App on the Webflow Marketplace. You can find it easily by following this <a href="https://webflow.com/apps/detail/orimon" target="_blank" rel="noreferrer">link</a>.</React.Fragment>,
    "Open your website in Webflow.",
    "Navigate to the Apps section in your Webflow dashboard.",
    "Look for the Orimon App and select Install to add it to your project.",
    "During installation, you'll be prompted to enter your unique Tenant ID, copy it from here and insert it in the popup shown upon app launch in webflow.",
  ],
  slack: [
    "Click on the Above Link",
    "Send /start to the BotFather",
    "Choose /newbot to create new bot",
    "Choose a name for your bot",
    "Choose a unique Username for your bot that must end with the word bot",
    "You will recieve a token, Use this token below to integrate your telegram bot",
  ],
  slackWorkSpaceSetting: [
    <React.Fragment>{<strong>Create Workspace: </strong>}Visit {<a href="https://slack.com/get-started#/createnew" target="_blank" rel="noreferrer noopener"> Slack's workspace creation page</a>} , fill in details, and create your workspace.</React.Fragment>,
    <React.Fragment>{<strong>Sign In: </strong>}Access your workspace at {<a href="https://slack.com/signin#/signin" target="_blank" rel="noreferrer noopener"> Slack's sign-in page</a>} , using your created credentials.</React.Fragment>
  ],
  hubspot: [
    "Sign in to your Hubspot account, then click on the 'Connect' button below to connect your account with your OrimonAI Chat-Bot.",
    "Click 'Agree' on the permissions page, that pops up.",
    "If for some reason, your account doesn't connect properly, click on 'Disconnect' and  then click on 'Connect' to connect your account again"
  ],
  googlesheets: [
    "Sign in to your Google Sheets account",
    <React.Fragment>Copy the entire URL of the sheet you want your data to be synced with, and paste it in the Input Box below {<strong>(Please provide the URL of a brand new google-sheet, and don't utilise it for any other purpose other than this integration)</strong>}</React.Fragment>,
    "Then click on the 'Connect' button below to connect your account with your OrimonAI Chat-Bot.",
    "Click 'Agree' on the permissions page, that pops up.",
    "If for some reason, your account doesn't connect properly, click on 'Disconnect' and  then click on 'Connect' to connect your account again"
  ],
  salesforce: [
    "Sign in to your Salesforce account, then click on the 'Connect' button below to connect your account with your OrimonAI Chat-Bot.",
    "Click 'Agree' on the permissions page, that pops up.",
    "If for some reason, your account doesn't connect properly, click on 'Disconnect' and  then click on 'Connect' to connect your account again"
  ]
}

export const MESSAGE_TYPES = {
  TEXT: "text",
  UPLOADED_DOCUMENT: "uploadedDocument",
  FORM: "form",
  IMAGE: "image",
  CAROUSEL: "carousel",
}

export const MESSAGE_TYPE_OPTIONS = [
  {
    label: "Text",
    value: MESSAGE_TYPES.TEXT,
    icon: <i class='ri-text-snippet'></i>
  },
  {
    label: "Form",
    value: MESSAGE_TYPES.FORM,
    icon: <i class='ri-survey-line'></i>
  },
  {
    label: "Carousel",
    value: MESSAGE_TYPES.CAROUSEL,
    icon: <i class='ri-carousel-view'></i>
  },
]

export const SOCKET_EVENTS = {
  CONNECT: "connect",
  CONNECT_ERROR: "connect_error",
  CONNECT_TIMEOUT: "connect_timeout",
  ERROR: "error",
  DISCONNECT: "disconnect",
  JOIN: "join",
  LEAVE: "leave",
  ADDITION: "addition",
  DELETION: "deletion",
  UPDATION: "updation",

  RESPONSE: "response",
  MESSAGE_SEEN: "messageSeen",
  ADMIN_TO_USER_MSG: "adminToUserMsg",
  RESOLVE_CHAT: "resolveChat",
  TYPING_STATUS: "typingStatus",
  UNSENT_MESSAGE: "unsentMessage",
}

export const CHAT_STATE = {
  UNASSIGNED: "Unassigned",
  AGENT_HANDLING: "AgentHandling",
  BOT_HANDLING: "BotHandling",
  RESOLVED: "Resolved",
}

export const LIVE_CHAT_TABS = {
  ALL_CHATS: "all",
  ACTIVE_CHATS: "active",
}

export const LEAD_FORM_TYPES = {
  TEXT: "text",
  NUMBER: "number",
  DROPDOWN: "dropdown",
}

export const LEAD_FORM_OPTIONS = [
  { label: "Text", value: LEAD_FORM_TYPES.TEXT },
  { label: "Number", value: LEAD_FORM_TYPES.NUMBER },
  { label: "Dropdown", value: LEAD_FORM_TYPES.DROPDOWN },
]

export const BUTTON_TYPES_OPTIONS = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Link",
    value: "link",
  },
]

export const BUTTON_TYPES = {
  DEFAULT: "default",
  LINK: "link",
}

export const CHANNELS = {
  WEBSITE: "website",
  WORDPRESS: "wordpress",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  TELEGRAM: "telegram",
  SLACK_WORKSPACE_SETTING: "slackWorkSpaceSetting",
  LIVE_CHAT: "liveChat",
  WHATSAPP: "whatsapp",
  GBM: "gbm",
  DISCORD: "discord",
  WEBFLOW: "webflow",
  ZAPIER: "zapier",
  PABBLY: "pabbly",
  MSTEAMS: "msteam",
  SLACK: "slack"
}

export const INTEGRATION_CHANNELS = [
  {
    name: "Website",
    icon: <i className="ri-global-line"></i>,
    value: "website",
    paywallDisabled: true,
    hide: false,
    showForECommerce: true
  },
  {
    name: "Wordpress",
    icon: <i className="ri-wordpress-line"></i> ,
    value: "wordpress",
    hide: false,
    showForECommerce: true
  },
  {
    name: "Webflow",
    iconSrc: IMG_WEBFLOW,
    value: "webflow",
    hide: false,
    showForECommerce: true
  },
  {
    name: "Telegram",
    icon: <i className="ri-telegram-line"></i>,
    value: "telegram",
    showSwitch: true,
    hide: false
  },
  {
    name: "Facebook",
    icon: <i className="ri-facebook-circle-line"></i>,
    value: "facebook",
    showSwitch: true,
    hide: false
  },
  {
    name: "Whatsapp",
    icon: <i className="ri-whatsapp-line"></i>,
    value: "whatsapp",
    showSwitch: true,
    hide: false
  },{
    name: "Instagram",
    icon: <i className="ri-instagram-line"></i>,
    value: "instagram",
    showSwitch: true,
    hide: false
  },{
    name: "Google Business",
    icon: <i className="ri-store-2-line"></i>,
    value: "gbm",
    hide: false,
    showSwitch: true
  },{
    name: "Discord",
    icon: <i className="ri-discord-line"></i>,
    value: "discord",
    showSwitch: true,
  }, {
    name: "Zapier",
    iconSrc: IMG_ZAPIER,
    value: "zapier",
    showSwitch: true,
    hide: false
  },{
    name: "Pabbly",
    iconSrc: IMG_PABBLY,
    showSwitch: true,
    value: "pabbly"
  },
  {
    name: "MS Teams",
    iconSrc: IMG_MSTEAMS,
    showSwitch: true,
    value: "msteam"
  },
  {
    name: "Slack",
    iconSrc: IMG_SLACK,
    value: "slack",
    showSwitch: true,
    hide: false
  }
]

export const INTEGRATIONS = {
  ZAPIER: "zapier",
  WEBHOOK: "webhook",
  HUBSPOT: "hubspot",
  SALESFORCE: "salesforce",
  GOOGLESHEETS: "googlesheets",
  PABBLY: "pabbly"
}

export const INTEGRATIONS_CONFIGRATION = [
  {
    name: "Webhook",
    value: "webhook",
    iconSrc: IMG_WEBHOOK,
  }, {
    name: "Zapier",
    value: "zapier",
    iconSrc: IMG_ZAPIER,
    showSwitch: true,
  }, {
    name: "Pabbly",
    value: "pabbly",
    iconSrc: IMG_PABBLY,
    showSwitch: true,
  },
  {
    name: "Hubspot",
    value: "hubspot",
    iconSrc: IMG_HUBSPOT,
  },
  {
    name: "Google Sheets",
    value: "googlesheets",
    iconSrc: IMG_GOOGLESHEETS
  },
  {
    name: "Salesforce",
    value: "salesforce",
    iconSrc: IMG_SALESFORCE
  }
]

export const TEXT_TYPE = {
  USER_TYPED: "userTyped",
  CANNED_RESPONSE: "cannedResponse",
}

export const INPUT_COMPOSER_TYPE = {
  BARGE: "barge",
  WHISPER: "whisper",
}

export const LANGUAGE_OPTIONS = [
  { label: "Afrikaans", value: "af" },
  { label: "Albanian", value: "sq" },
  { label: "Amharic", value: "am" },
  { label: "Arabic", value: "ar" },
  { label: "Armenian", value: "hy" },
  { label: "Assamese", value: "as" },
  { label: "Aymara", value: "ay" },
  { label: "Azerbaijani", value: "az" },
  { label: "Bambara", value: "bm" },
  { label: "Basque", value: "eu" },
  { label: "Belarusian", value: "be" },
  { label: "Bengali", value: "bn" },
  { label: "Bhojpuri", value: "bho" },
  { label: "Bosnian", value: "bs" },
  { label: "Bulgarian", value: "bg" },
  { label: "Catalan", value: "ca" },
  { label: "Cebuano", value: "ceb" },
  { label: "Chinese", value: "zh" },
  { label: "Corsican", value: "co" },
  { label: "Croatian", value: "hr" },
  { label: "Czech", value: "cs" },
  { label: "Danish", value: "da" },
  { label: "Dhivehi", value: "dv" },
  { label: "Dogri", value: "doi" },
  { label: "Dutch", value: "nl" },
  { label: "English", value: "en" },
  { label: "Esperanto", value: "eo" },
  { label: "Estonian", value: "et" },
  { label: "Ewe", value: "ee" },
  { label: "Filipino", value: "fil" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "Frisian", value: "fy" },
  { label: "Galician", value: "gl" },
  { label: "Georgian", value: "ka" },
  { label: "German", value: "de" },
  { label: "Greek", value: "el" },
  { label: "Guarani", value: "gn" },
  { label: "Gujarati", value: "gu" },
  { label: "Haitian Creole", value: "ht" },
  { label: "Hausa", value: "ha" },
  { label: "Hawaiian", value: "haw" },
  { label: "Hebrew", value: "he" },
  { label: "Hindi", value: "hi" },
  { label: "Hmong", value: "hmn" },
  { label: "Hungarian", value: "hu" },
  { label: "Icelandic", value: "is" },
  { label: "Igbo", value: "ig" },
  { label: "Ilocano", value: "ilo" },
  { label: "Indonesian", value: "id" },
  { label: "Irish", value: "ga" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Javanese", value: "jv" },
  { label: "Kannada", value: "kn" },
  { label: "Kazakh", value: "kk" },
  { label: "Khmer", value: "km" },
  { label: "Kinyarwanda", value: "rw" },
  { label: "Konkani", value: "gom" },
  { label: "Korean", value: "ko" },
  { label: "Krio", value: "kri" },
  { label: "Kurdish", value: "ku" },
  { label: "Kurdish (Sorani)", value: "ckb" },
  { label: "Kyrgyz", value: "ky" },
  { label: "Lao", value: "lo" },
  { label: "Latin", value: "la" },
  { label: "Latvian", value: "lv" },
  { label: "Lingala", value: "ln" },
  { label: "Lithuanian", value: "lt" },
  { label: "Luganda", value: "lg" },
  { label: "Luxembourgish", value: "lb" },
  { label: "Macedonian", value: "mk" },
  { label: "Maithili", value: "mai" },
  { label: "Malagasy", value: "mg" },
  { label: "Malay", value: "ms" },
  { label: "Malayalam", value: "ml" },
  { label: "Maltese", value: "mt" },
  { label: "Maori", value: "mi" },
  { label: "Marathi", value: "mr" },
  { label: "Meiteilon (Manipuri)", value: "mni-Mtei" },
  { label: "Mizo", value: "lus" },
  { label: "Mongolian", value: "mn" },
  { label: "Myanmar (Burmese)", value: "my" },
  { label: "Nepali", value: "ne" },
  { label: "Norwegian", value: "no" },
  { label: "Nyanja (Chichewa)", value: "ny" },
  { label: "Odia (Oriya)", value: "or" },
  { label: "Oromo", value: "om" },
  { label: "Pashto", value: "ps" },
  { label: "Persian", value: "fa" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese (Portugal, Brazil)", value: "pt" },
  { label: "Punjabi", value: "pa" },
  { label: "Quechua", value: "qu" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Samoan", value: "sm" },
  { label: "Sanskrit", value: "sa" },
  { label: "Scots Gaelic", value: "gd" },
  { label: "Sepedi", value: "nso" },
  { label: "Serbian", value: "sr" },
  { label: "Sesotho", value: "st" },
  { label: "Shona", value: "sn" },
  { label: "Sindhi", value: "sd" },
  { label: "Sinhala (Sinhalese)", value: "si" },
  { label: "Slovak", value: "sk" },
  { label: "Slovenian", value: "sl" },
  { label: "Somali", value: "so" },
  { label: "Spanish", value: "es" },
  { label: "Sundanese", value: "su" },
  { label: "Swahili", value: "sw" },
  { label: "Swedish", value: "sv" },
  { label: "Tagalog (Filipino)", value: "tl" },
  { label: "Tajik", value: "tg" },
  { label: "Tamil", value: "ta" },
  { label: "Tatar", value: "tt" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Tigrinya", value: "ti" },
  { label: "Tsonga", value: "ts" },
  { label: "Turkish", value: "tr" },
  { label: "Turkmen", value: "tk" },
  { label: "Twi (Akan)", value: "ak" },
  { label: "Ukrainian", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uyghur", value: "ug" },
  { label: "Uzbek", value: "uz" },
  { label: "Vietnamese", value: "vi" },
  { label: "Welsh", value: "cy" },
  { label: "Xhosa", value: "xh" },
  { label: "Yiddish", value: "yi" },
  { label: "Yoruba", value: "yo" },
  { label: "Zulu", value: "zu" },
]

export const CLEVERTAP_EVENT = {
  DASHBOARD: "Dashboard",
  LEARN: "Learn Bot",
  TEACH_MANUALLY: "Teach Manually",
  PAGE_VISIT: "Page Visiting",
  PAGE_LEAVE: "Page Leaving",
  PRICE_VISIT: "Price Visiting",
  PRICE_VISIT_TYPE: {
    TOPUP: "TopUp",
    SUBSCRIBE: "Subscribe",
    TRIAL: "Trial",
  },
}

export const DATA_SOURCES_TYPE = {
  WEBSITE: "website",
  SITEMAP: "sitemap",
  GITBOOK: "gitbook",
  PDF: "pdf",
  FAQS: "faqs",
  CATALOG: "catalog",
  TEXT: "text",
  E_COMMERCE: "ecommerce",
  CLOUD: "cloud",
}

export const DATA_SOURCES_OPTIONS = [
  {
    label: "Ecommerce",
    value: DATA_SOURCES_TYPE.E_COMMERCE,
    icon: <i className="ri-shopping-cart-2-line"></i>,
    count:(overviewDetails)=>{
      return overviewDetails?.eCommerceOnboarding?.storeInfo?.productCount
    },
  },
  {
    label: "Website",
    value: DATA_SOURCES_TYPE.WEBSITE,
    icon: <i className="ri-global-line"/>,
    count:(overviewDetails)=>{
      return overviewDetails?.tenantInfo?.connectedUrls?.length
    }
  },
  // {
  //   label: "SiteMap",
  //   value: DATA_SOURCES_TYPE.SITEMAP,
  //   icon: <AccountTreeIcon />,
  // },
  // {
  //   label: "GitBook",
  //   value: DATA_SOURCES_TYPE.GITBOOK,
  //   icon: <i className="ri-git-repository-fill"></i>,
  // },
  {
    label: "PDF",
    value: DATA_SOURCES_TYPE.PDF,
    icon: <i className="ri-file-pdf-2-line"></i>,
    count:(overviewDetails)=>{
      return overviewDetails?.uploadedTenantInfoDocumentList?.connectedUrls?.length
    }
  },
  {
    label: "Text",
    value: DATA_SOURCES_TYPE.TEXT,
    icon: <i className="ri-text-spacing"></i>,
  },
  {
    label: "FAQs",
    value: DATA_SOURCES_TYPE.FAQS,
    icon: <i className="ri-question-line"></i>,
  },
  {
    label: "Catalog",
    value: DATA_SOURCES_TYPE.CATALOG,
    icon: <i className="ri-shopping-bag-3-line"></i>,
  },
  {
    label: "Cloud",
    value: DATA_SOURCES_TYPE.CLOUD,
    icon: <i className="ri-cloud-line"></i>,
  }
]

export const TOTAL_DAYS = {
  month: 30,
  week: 7,
  today: 0,
}

export const DAYS = [
  {
    label: "sun",
    value: 0,
  },
  {
    label: "mon",
    value: 1,
  },
  {
    label: "tue",
    value: 2,
  },
  {
    label: "wed",
    value: 3,
  },
  {
    label: "thu",
    value: 4,
  },
  {
    label: "fri",
    value: 5,
  },
  {
    label: "sat",
    value: 6,
  },
]
export const MODULE_CONFIG = {
  LIVE_CHATS_ALL_CHATS_LIMIT: 20,
}

export const WORKSPACE_SETTINGS_MENU = {
  GENERAL: "General",
  BRANDING: "Branding",
  DASHBOARD_WHITE_LABELING: "Dashboard Domain",
  BOT_WHITE_LABELING: "Bot Domain"
}

export const WORKSPACE_UPSTREAM_STATUS = {
  ADDED: 'upstream_added',
  NOT_ADDED: 'upstream_not_added',
}

export const WORKSPACE_UPSTREAM_LABELS = {
  DASHBOARD: 'Dashboard',
  BOT: 'Bot',
}

export const SERVICE_KEY = {
  STRIPE_BILLING: "stripe_billing",
  BILLING: "billing"
}

export const USER_STRIP_VERSION_V2 = 'v2'

export const MAINTENANCE_STATUS = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS"
}

export const ACL_IDENTIFIERS = {
  WORKSPACE_SETTINGS: "workspace-settings",
  GENERAL: "general",
  BRANDING: "branding",
  BOT: "bot",
  DOMAIN : "domain",
  DASHBOARD: "dashboard",
  EMAIL:'email',
  APPEARANCE:'appearance',
  INFO:'info',
  QUOTA_LIMITS: "quotaLimit",
  MODEL_CONFIGURATION: "modelConfiguration",
  BYOK: "byok",
  PROMPTS: "prompts",
  LIVE_CHAT_CONFIGURATION: "liveChatConfiguration",
  TRANSFER_OWNERSHIP: "transferOwnership",
  ADD_TEAM_MEMBERS: "addTeamMembers",
  INVITATIONS: 'invitations',
  BOT_APPEARANCE_BRANDING: "branding",
  BILLING: 'billing',
  ADD_NEW_TENANT: 'addNewTenant',
  ADD_NEW_ECOMM_TENANT: 'addNewEcommTenant',
  TENANT: 'tenant',
  PROFILE: 'profile',
  PROFILE_REDEEM_CODE: 'redeemCode',
  PROFILE_GENERATE_API_KEY: 'generateApiKey',
  PROFILE_DELETE_ACCOUNT: 'deleteAccount',
  LIVE_AGENT: "liveAgent",
}

export const USER_ROLES = {
  WORKSPACE_OWNER : "workspaceOwner",
  WORKSPACE_USER : "workspaceUser",
  USER: "user",
  WORKSPACE_ADMIN: "workspaceAdmin",
  OWNER: "owner"
}

export const USER_BANNER_TYPES = {
  ALERT: 'alert',
  MESSAGE: 'message',
  NEWS: 'news',
}

export const USER_BANNER_LINKS = {
  REFRESH: 'refresh',
}

export const GBM_VERIFICATION_STATUS = {
  UNVERIFIED : "VERIFICATION_STATE_UNVERIFIED",
  VERIFIED: "VERIFICATION_STATE_VERIFIED",
  PENDING: "VERIFICATION_STATE_PENDING",
}

export const GBM_LAUNCH_STATUS = {
  NOT_LAUNCHED: "LAUNCH_STATE_UNLAUNCHED",
  LAUNCHED: "LAUNCH_STATE_LAUNCHED",
}

export const GBM_NON_LOCAL_REGIONS = {
  AD: "Andorra",
  AE: "United Arab Emirates",
  AF: "Afghanistan",
  AG: "Antigua & Barbuda",
  AI: "Anguilla",
  AL: "Albania",
  AM: "Armenia",
  AO: "Angola",
  AQ: "Antarctica",
  AR: "Argentina",
  AS: "American Samoa",
  AT: "Austria",
  AU: "Australia",
  AW: "Aruba",
  AX: "Åland Islands",
  AZ: "Azerbaijan",
  BA: "Bosnia",
  BB: "Barbados",
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BL: "St. Barthélemy",
  BM: "Bermuda",
  BN: "Brunei",
  BO: "Bolivia",
  BQ: "Caribbean Netherlands",
  BR: "Brazil",
  BS: "Bahamas",
  BT: "Bhutan",
  BW: "Botswana",
  BY: "Belarus",
  BZ: "Belize",
  CA: "Canada",
  CC: "Cocos (Keeling) Islands",
  CD: "Congo (DRC)",
  CF: "Central African Republic",
  CG: "Congo (Republic)",
  CH: "Switzerland",
  CI: "Ivory Coast",
  CK: "Cook Islands",
  CL: "Chile",
  CM: "Cameroon",
  CN: "China",
  CO: "Colombia",
  CR: "Costa Rica",
  CU: "Cuba",
  CV: "Cabo Verde",
  CW: "Curaçao",
  CX: "Christmas Island",
  CY: "Cyprus",
  CZ: "Czechia",
  DE: "Germany",
  DJ: "Djibouti",
  DK: "Denmark",
  DM: "Dominica",
  DO: "Dominican Republic",
  DZ: "Algeria",
  EC: "Ecuador",
  EE: "Estonia",
  EG: "Egypt",
  EH: "Western Sahara",
  ER: "Eritrea",
  ES: "Spain",
  ET: "Ethiopia",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands (Islas Malvinas)",
  FM: "Micronesia",
  FO: "Faroe Islands",
  FR: "France",
  GA: "Gabon",
  GB: "United Kingdom",
  GD: "Grenada",
  GE: "Georgia",
  GF: "French Guiana",
  GG: "Guernsey",
  GH: "Ghana",
  GI: "Gibraltar",
  GL: "Greenland",
  GM: "Gambia",
  GN: "Guinea",
  GP: "Guadeloupe",
  GQ: "Equatorial Guinea",
  GR: "Greece",
  GS: "South Georgia & South Sandwich Islands",
  GT: "Guatemala",
  GU: "Guam",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HK: "Hong Kong",
  HN: "Honduras",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IM: "Isle of Man",
  IN: "India",
  IO: "British Indian Ocean Territory",
  IQ: "Iraq",
  IR: "Iran",
  IS: "Iceland",
  IT: "Italy",
  JE: "Jersey",
  JM: "Jamaica",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenya",
  KG: "Kyrgyzstan",
  KH: "Cambodia",
  KI: "Kiribati",
  KM: "Comoros",
  KN: "St. Kitts & Nevis",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KY: "Cayman Islands",
  KZ: "Kazakhstan",
  LA: "Laos",
  LB: "Lebanon",
  LC: "St. Lucia",
  LI: "Liechtenstein",
  LK: "Sri Lanka",
  LR: "Liberia",
  LS: "Lesotho",
  LT: "Lithuania",
  LU: "Luxembourg",
  LV: "Latvia",
  LY: "Libya",
  MA: "Morocco",
  MC: "Monaco",
  MD: "Moldova",
  ME: "Montenegro",
  MF: "St. Martin",
  MG: "Madagascar",
  MH: "Marshall Islands",
  MK: "North Macedonia",
  ML: "Mali",
  MM: "Myanmar",
  MN: "Mongolia",
  MO: "Macao",
  MP: "Northern Mariana Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauritius",
  MV: "Maldives",
  MW: "Malawi",
  MX: "Mexico",
  MY: "Malaysia",
  MZ: "Mozambique",
  NA: "Namibia",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Aotearoa New Zealand",
  OM: "Oman",
  PA: "Panama",
  PE: "Peru",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PH: "Philippines",
  PK: "Pakistan",
  PL: "Poland",
  PM: "St. Pierre & Miquelon",
  PN: "Pitcairn Islands",
  PR: "Puerto Rico",
  PS: "Palestine",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paraguay",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SB: "Solomon Islands",
  SC: "Seychelles",
  SD: "Sudan",
  SE: "Sweden",
  SG: "Singapore",
  SH: "St. Helena",
  SI: "Slovenia",
  SJ: "Svalbard & Jan Mayen",
  SK: "Slovakia",
  SL: "Sierra Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalia",
  SR: "Suriname",
  SS: "South Sudan",
  ST: "São Tomé & Príncipe",
  SV: "El Salvador",
  SX: "Sint Maarten",
  SY: "Syria",
  SZ: "Swaziland",
  TC: "Turks & Caicos Islands",
  TD: "Chad",
  TF: "French Southern Territories",
  TG: "Togo",
  TH: "Thailand",
  TJ: "Tajikistan",
  TK: "Tokelau",
  TL: "East Timor",
  TM: "Turkmenistan",
  TN: "Tunisia",
  TO: "Tonga",
  TR: "Türkiye",
  TT: "Trinidad & Tobago",
  TV: "Tuvalu",
  TW: "Taiwan",
  TZ: "Tanzania",
  UA: "Ukraine",
  UG: "Uganda",
  UM: "U.S. Outlying Islands",
  US: "United States of America",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VA: "Vatican City",
  VC: "St. Vincent & Grenadines",
  VE: "Venezuela",
  VG: "British Virgin Islands",
  VI: "U.S. Virgin Islands",
  VN: "Vietnam",
  VU: "Vanuatu",
  WF: "Wallis & Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Yemen",
  YT: "Mayotte",
  ZA: "South Africa",
  ZM: "Zambia",
  ZW: "Zimbabwe",
}

export const PLAN_TYPES = {
  LTD: "LTD",
  FREE: "Free",
  STARTER: "Starter",
  GROWTH: "Growth",
  ELITE: "Elite",
}

export const E_COMMERCE_PLATFORMS = {
  SHOPIFY: "shopify",
  WOOCOMMERCE: "woo-commerce",
  CATALOG: "orimon"
}

export const E_COMMERCE_CATALOG_TYPES = {
  CSV: "csv",
  GOOGLESHEETS: "googlesheets",
}

export const currencyMap = new Map([
  ['usd','$'],
  ['inr','₹']
]);

export const GBM_UPLOAD_IMAGE_MAX_SIZE = 209715.2;

export const MESSAGE_FEEDBACK_SOURCE = {
  SHEPARD_TEST_PASSED: "shephardTestPassed",
  CUSTOMER_VOTE: "customerVote",
}

export const MESSAGE_FEEDBACK_ADMIN_ACTIONS = {
  PENDING: "pending",
  IGNORED: "ignored",
  TRAINED: "trained",
  SAVED: "saved for training",
}

export const DYNAMIC_POPUP_EVENTS = {
  CLOSE_POPUP: "CLOSE_POPUP",
  BUTTON_CLICK: "BUTTON_CLICK",
  OPT_OUT: "OPT_OUT",
  VIEWED: "VIEWED",
  EVENT: "EVENT"
}

export const DYNAMIC_POPUP_TRIGGER_EVENTS = {
  MAX_TRAINING_URL_LIMIT_REACHED: "maxTrainingUrlLimitReached",
  TESTED_ONCE_BUT_NOT_INTEGRATED: "testedOnceButNotIntegrated",
  INCOMPLETE_BOT_CREATION: "incompleteBotCreation",
  BOT_QUOTA_EXPIRED: "chatBotQuotaExpiry",
  SUBSCRIPTION_EXPIRED: "chatBotSubExpiry",
  LOGOUT_INTENT: "logoutIntent",
}

export const DYNAMIC_POPUP_CUSTOM_EVENTS = {
  SECONDS_ON_PAGE: "secondsOnPage",
  EXIT_INTENT: "exitIntent",
  LOGOUT_INTENT: "logoutIntent",
}

export const MANAGE_BILLING_COMPONENTS =  {
  MANAGE_BILLING : 'MANAGE_BILLING',
  PLAN_UPDATION : 'PLAN_UPDATION'
}

export const CREATE_BOT_DEFAULT_VALUES = {
  BOT_NAME : ['SmartBot','Your AI assistant'],
  COMPANY_NAME : ['brandify','Tech Co']
}

export const GTAGS = {
  HELP_VISIT: "help_visit",
  LOGOUT: "logout",
  TAB_CLOSE: "tab_close",
  TRACK_BOT_CREATION_INTENT: "trck_bot_creation_intent",
  PRICING_VISIT: "pricing_visit",
  BOT_CREATION: "bot_creation",
  BOT_CREATION_GUIDE_SKIPPED: "bot_creation_guide_skipped",
  TRACK_BOT_CREATION_ACTION: "trck_bot_creation_action",
  USER_EXIT_INTENT: "user_exit_intent",
}
export const USER_LOGIN_ATTRIBUTES_STEPS = [
  `It is recommended to use this method, if at any point you get access to basic user details like name or email, as this greatly increases the user experience with your smart chatbot, by giving it better context and situational information`,

  <React.Fragment>
    <Typography gutterBottom>Simply call window.addUserDetails with the user's name, email, and/or phone number as shown below: </Typography>
    <CodeViewer code={
      <pre>
        {`window.addUserDetails({\n  name: "<user_name_here>",\n  email: "<user_email_here>",\n  phone: "<user_phone_here>",\n  userId: "<your_identifier_here>"\n});`}
      </pre>
    } 
    copyCode={`window.addUserDetails({  name: "<user_name_here>",  email: "<user_email_here>",  phone: "<user_phone_here>",  userId: "<your_identifier_here>"});`}
    />
  </React.Fragment>,

  <React.Fragment>
    <Typography gutterBottom><strong>For example:</strong> if John Doe logs into your platform, with email john.doe@email.com where your chatbot is deployed you can call the method as follows which will then save it to the, bot's knowledge.</Typography>
    <CodeViewer code={
      <pre>
        {`window.addUserDetails({\n  name: "John Doe",\n  email: "john.doe@email.com"\n})`}
      </pre>
    } copyCode={`window.addUserDetails({  name: "John Doe",  email: "john.doe@email.com"})`}/>
  </React.Fragment>,
]


export const USER_GUIDED_JOURNEY_IDS = {
  REWARD_JOURNEY_ID: "reward_journey",
}

export const ECOMMERCE_PRODUCT_SYNC_STATUS = {
  SYNCED: "synced",
  SYNCING: "syncing",
  FAILED: "failed",
}

export const LEAD_VARIANTS = {
  DEFAULT: "default",
  MESSAGE: "message",
}

export const PROMO_CODES = {
  STARTER_LTD: "starterLtdOffer"
}

export const STARTER_LTD_CHECKOUT_SOURCE = {
  PRICING_PAGE_EXIT_INTENT: "pricingPageExitIntent",
  CANCEL_SUBSCRIPTION_TRIGGER: "cancelSubscriptionTrigger",
  EXTERNAL: "external"
}

export const CLOUD_DATA_INTEGRATIONS = [
  {
    name: "Google Sheets",
    description: "Add tabular data from your Google Sheets in your bot knowledge",
    value: "google_sheets",
    iconSrc: IMG_GOOGLESHEETS,
    bgColor: '#1CA261',
  },
  {
    name: "Google Docs",
    description: "Add data from your Google Docs in your bot knowledge",
    value: "google_docs",
    iconSrc: IMG_GOOGLEDOCS,
    bgColor: '#0086F9',
  },
]

export const CLOUD_RESOURCES_INTEGRATIONS_STEPS = {
  google_sheets: [
    `Create a new Google Sheets document and add your data in it.`,
    `Go to File -> Share -> Publish to Web`,
    `S`
  ]
}