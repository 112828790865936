import { BREAKPOINT_VALUES, COLORS } from "../configs/constants"

const theme = {
  type: "light",
  palette: {
    primary: {
      main: COLORS.PRIMARY_LIGHT,
    },
    secondary: {
      main: COLORS.SECONDARY_LIGHT,
    },
    tertiary: {
      main: COLORS.TERTIARY_LIGHT,
    },
    text: {
      primary: "#000000",
      buttonPrimary: "#f7f7f9"
    },
    background: {
      default: "#F7F7F7",
    },
  },
  typography:{
    h1:{
      fontFamily: [
        'Poppins', 'sans-serif'
      ].join(','),
    },
    h2:{
      fontFamily: [
        'Poppins', 'sans-serif'
      ].join(','),
    },
    h3:{
      fontFamily: [
        'Poppins', 'sans-serif'
      ].join(','),
    },
    h4:{
      fontFamily: [
        'Poppins', 'sans-serif'
      ].join(','),
      fontWeight: 600
    },
    h5:{
      fontFamily: [
        'Poppins', 'sans-serif'
      ].join(','),
      fontWeight: 400
    },
    h6:{
      fontFamily: [
        'Poppins', 'sans-serif'
      ].join(','),
    }
  },
  shape: {
    borderRadius: 8
  },
  breakpoints: {
    values: BREAKPOINT_VALUES,
  },
  shadows: [
    "none",
    "0px 2px 6px rgba(0, 0, 0, 0.1)",
    "0px 35px 60px rgba(0, 0, 0, 0.25)",
    "20px 55px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    "10px 15px 60px rgba(0, 0, 0, 0.25)",
    ...Array(12).fill("none"),
  ],
}

export default theme
