import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../data/configs/constants';

const ContactOwnerPaywall = () => {
    const navigate = useNavigate();
    const handleContactOwner = () => {
        navigate(ROUTE_PATH.SUPPORT);
    }
    return (
        <Grid container justifyContent={'center'} alignContent={'center'} padding={1}>
            <Grid item xs={12} textAlign={'center'}>
                <Typography variant='caption' gutterBottom>To unlock this feature or renew quota, please contact the owner</Typography>
            </Grid>
            <Grid item>
                <Button startIcon={<SupportAgentIcon/>} size='small' variant='contained' onClick={handleContactOwner}>Contact Owner</Button>
            </Grid>
        </Grid>
    )
}

export default ContactOwnerPaywall