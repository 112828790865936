import styled from '@emotion/styled'
import { Button, alpha, keyframes, lighten } from '@mui/material'

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

export const StyledButton = styled(Button)(({ theme, color, variant, disabled, shouldPulse }) => ({
    borderRadius: 5,
    textTransform: 'none',
    boxShadow: 'none',
    animation: !disabled && shouldPulse && `${pulse} 2s infinite`,
    "&:hover": {
        boxShadow: "none"
    },
    "&:disabled": {
        backgroundColor: color === 'primary' && variant === 'contained' && alpha(theme.palette.primary.main, 0.3),
        color: color === 'primary' && variant === 'contained' && lighten(theme.palette.primary.main, 0.3),
    }
}))