import React, {lazy, Suspense, useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {BrowserRouter as Router, Route, Routes, useParams} from "react-router-dom"
import axios from "axios"
import {closeSnackbar, SnackbarProvider} from "notistack"
import {createTheme, ThemeProvider,} from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import ReportIcon from "@mui/icons-material/Report"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import WarningIcon from "@mui/icons-material/Warning"
import InfoIcon from "@mui/icons-material/Info"
import Favicon from "react-favicon";

import {getLatestVersion, getMaintenanceInfo, updatePageState} from "../data/store/pageSlice"
import themeConfig from "../data/theme"
import {
  cleverTap,
  getDataFromLocalStorage,
  getDeviceData,
  isAppleDevice,
  setDataInLocalStorage,
  throttled,
} from "../data/utils"
import {IP_INFO_URL} from "../data/configs/urls"
import {
  LOCAL_STORAGE,
  ROUTE_PATH,
  SERVICE_KEY,
  WORKSPACE_UPSTREAM_LABELS,
  WORKSPACE_UPSTREAM_STATUS,
} from "../data/configs/constants"

import ErrorBoundary from "./components/ErrorBoundary"
import GlobalInformation from "./components/GlobalInformation"
import ResetScroll from "./components/ResetScroll"
import SpinnerLoader from "./components/SpinnerLoader"
import GoogleAuthentication from "./components/GoogleAuthentication"
import EmailVerification from "./components/EmailVerification"
import InstagramAuthentication from "./components/InstagramAuthentication"
import MaintenanceOverlay from "./components/MaintenanceOverlay"
import {getThemeObject} from "../data/utils/theme"
import {getDomainConfig, getUserUpstreamList, initializeDomainSetting} from "../data/store/WorkspaceSettingsSlice"
import GbmAuthentication from "./components/GbmAuthentication"
import {Settings} from "./PageContainer/TenantContainer/Settings";
import Members from "./PageContainer/TenantContainer/Settings/Members";
import HumanAssistSettings from "./PageContainer/TenantContainer/Settings/HumanAssistSettings";
import HubspotAuthentication from "./components/HubspotAuthentication"
import SalesforceAuthentication from "./components/SalesforceAuthentication"
import GoogleSheetAuthentication from "./components/GoogleSheetAuthentication"
import {fetchMyProfile, getACL, getUserQuota, updateUserDetails} from "../data/store/authSlice"
import {getBillingData} from "../data/store/billingSlice"
import EnterpriseAuthentication from "./components/EnterpriseAuthentication"
import useSystemTheme from "../data/hooks/useSystemTheme"
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

const BillingHome = lazy(() => import("./PageContainer/Billing"))

const SupportPage = lazy(() => import("./PageContainer/Support"))

const AuthContainer = lazy(() => import("./AuthContainer"))
const WhiteLabeledAuthContainer = lazy(() => import("./WhiteLabeledAuthContainer"))
const Login = lazy(() => import("./AuthContainer/Login"))
const Signup = lazy(() => import("./AuthContainer/Signup"))
const ForgotPassword = lazy(() => import("./AuthContainer/ForgotPassword"))
const ResetPassword = lazy(() => import("./AuthContainer/ResetPassword"))

const PageNotFound = lazy(() => import("./components/PageNotFound"))

const WebsiteContainer = lazy(() => import("./WebsiteContainer"))
const Home = lazy(() => import("./WebsiteContainer/Home"))
const Pricing = lazy(() => import("./WebsiteContainer/Pricing"))
const FeaturedBots = lazy(() => import("./WebsiteContainer/FeaturedBots"))
const PrivacyPolicy = lazy(() => import("./WebsiteContainer/PrivacyPolicy"))

const PageContainer = lazy(() => import("./PageContainer"))
const Dashboard = lazy(() => import("./PageContainer/Dashboard"))
const MainPricing = lazy(() => import("./PageContainer/MainPricing"))
const OnBoarding = lazy(() => import("./PageContainer/OnBoarding"))
const CreateBot = lazy(() => import("./PageContainer/CreateBot"))
const RedirectShopify = lazy(() => import("./components/RedirectShopify"))
const Profile = lazy(() => import("./PageContainer/Profile"))
const BuyMore = lazy(() => import("./PageContainer/BuyMore"))
const PaymentSuccess = lazy(() => import("./PageContainer/PaymentSuccess"))

const TenantContainer = lazy(() => import("./PageContainer/TenantContainer"))
const Overview = lazy(() => import("./PageContainer/TenantContainer/Overview"))
const EditKnowledge = lazy(() =>
  import("./PageContainer/TenantContainer/Overview/EditKnowledge")
)
const TestBot = lazy(() =>
  import("./PageContainer/TenantContainer/Overview/TestBot")
)
const BotAppearance = lazy(() =>
  import("./PageContainer/TenantContainer/Settings/BotAppearance")
)
const Leads = lazy(() => import("./PageContainer/TenantContainer/Settings/Leads"))
const RouteBasedNotifications = lazy(() =>
  import(
    "./PageContainer/TenantContainer/Settings/BotAppearance/RouteBasedNotifications"
    )
)
const ChatHistory = lazy(() =>
  import("./PageContainer/TenantContainer/ChatHistory")
)
const LiveChats = lazy(() =>
  import("./PageContainer/TenantContainer/LiveChats")
)
const Training = lazy(() => import("./PageContainer/TenantContainer/Training"))
const LeadCapture = lazy(() =>
  import("./PageContainer/TenantContainer/LeadCapture")
)
const Feedback = lazy(() => import("./PageContainer/TenantContainer/Feedback"))
const AdvancedSettings = lazy(() =>
  import("./PageContainer/TenantContainer/Settings/AdvancedSettings")
)
const Channels = lazy(() => import("./PageContainer/TenantContainer/Channels"))
const Integrations = lazy(() =>
  import("./PageContainer/TenantContainer/Settings/Integrations")
)
const Templates = lazy(() =>
  import("./PageContainer/TenantContainer/Templates")
)
const WorkspaceSettings = lazy(() =>
  import("./WorkspaceSettings")
)
const GeneralSettings = lazy(() =>
  import("./WorkspaceSettings/GeneralSettings")
)
const BrandingSettings = lazy(() =>
  import("./WorkspaceSettings/BrandingSettings")
)
const DashboardWhiteLabel = lazy(() =>
  import("./WorkspaceSettings/DashboardWhiteLabel")
)
const BotWhiteLabel = lazy(() =>
  import("./WorkspaceSettings/BotWhiteLabel")
)
const BrandingEmailSettings = lazy(() =>
  import("./WorkspaceSettings/BrandingSettings/EmailSettings")
)
const BrandingAppearanceSettings = lazy(() =>
  import("./WorkspaceSettings/BrandingSettings/AppearanceSettings"))
const BrandingCompanyDetails = lazy(() =>
  import("./WorkspaceSettings/BrandingSettings/CompanyDetails"))
const Referral = lazy(() =>
  import("./PageContainer/Referral")
)
const ECommerceOnboarding = lazy(() => import("./PageContainer/ECommerceOnBoarding"))
const TenantQuota = lazy(() => import("./PageContainer/TenantContainer/Settings/Quota"))
const WelcomeOnboarding = lazy(() => import("./PageContainer/WelcomeOnboarding"))

const App = () => {
  const params = useParams()
  const themeMode = useSelector((state) => state.pageDetails.themeMode)
  const applySystemTheme = useSelector((state) => state.pageDetails.applySystemTheme)
  const systemTheme = useSystemTheme();
  const userId = useSelector((state) => state.authDetails.userId)
  const user = useSelector((state) => state.authDetails.user)
  const maintenanceInfo = useSelector((state) => state.pageDetails.maintenanceInfo);
  const domainConfig = useSelector((state) => state.workspaceSettingsDetails.domainConfig);
  const domainConfigTheme = useSelector((state) => state.workspaceSettingsDetails.domainConfig?.branding?.theme);
  const userTheme = useSelector((state) => state.pageDetails.theme);
  const isLoggedIn = useSelector((state) => state.authDetails.isLoggedIn)
  const token = useSelector((state) => state.authDetails.token)
  const domainUpstreamData = useSelector(state => state.workspaceSettingsDetails.allUpstreamStatus?.[WORKSPACE_UPSTREAM_LABELS.DASHBOARD]);
  const botUpstreamData = useSelector(state => state.workspaceSettingsDetails.allUpstreamStatus?.[WORKSPACE_UPSTREAM_LABELS.BOT]);
  const dispatch = useDispatch()
  const resizeTimerRef = useRef(null)
  const scrollTimerRef = useRef(null)
  const [isLoadingTheme, setIsLoadingTheme] = useState(true);
  const isWhiteLabelDashboard = (process.env.REACT_APP_WHITE_LABELED_APP === "true")
  const logoutSuccess = useSelector((state) => state.authDetails.logoutSuccess)
  const posthogKey = process.env.REACT_APP_POSTHOG_KEY;
  const posthogHost = process.env.REACT_APP_POSTHOG_HOST;

  if (posthogKey) {
    posthog.init(posthogKey, {
      api_host: posthogHost,
      capture_pageview: false,
      person_profiles: 'identified_only'
    });
  }


  useEffect(() => {
    const appVersion = getDataFromLocalStorage(LOCAL_STORAGE.APP_VERSION, null)
    dispatch(getLatestVersion())
      .unwrap()
      .then((res) => {
        if (res?.data?.latestVersion) {
          if (!appVersion) {
            setDataInLocalStorage(
              LOCAL_STORAGE.APP_VERSION,
              res.data.latestVersion
            )
          } else if (appVersion !== res.data.latestVersion) {
            setDataInLocalStorage(
              LOCAL_STORAGE.APP_VERSION,
              res.data.latestVersion
            )
            window.location.reload(true)
          }
        }
      })

    // getting maintenance info
    dispatch(getMaintenanceInfo())

    axios({url: IP_INFO_URL})
      .then((res) => {
        if (res?.data?.country)
          setDataInLocalStorage(LOCAL_STORAGE.COUNTRY, res.data.country)
        if (res?.data)
          setDataInLocalStorage(LOCAL_STORAGE.IP_META, res.data)
      })
      .catch(() => {
        const country = getDataFromLocalStorage(LOCAL_STORAGE.COUNTRY, null)
        if (!country) setDataInLocalStorage(LOCAL_STORAGE.COUNTRY, "US")
      })

  }, [dispatch])

  useEffect(() => {
    const handleDeviceResizeChange = () => {
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
      resizeTimerRef.current = setTimeout(
        () =>
          dispatch(
            updatePageState({
              deviceData: getDeviceData(),
            })
          ),
        500
      )
    }
    window.addEventListener("resize", handleDeviceResizeChange)
    return () => {
      window.removeEventListener("resize", handleDeviceResizeChange)
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
    }
  }, [dispatch])

  useEffect(() => {
    const isApple = isAppleDevice()

    const handleScrollbarView = throttled((event) => {
      if (event?.target?.style) {
        event.target.style.setProperty("--scroll-display", "visible")
        event.target.classList.add("prevent-inheritance")
      }
      if (scrollTimerRef.current) {
        clearTimeout(scrollTimerRef.current)
      }
      scrollTimerRef.current = setTimeout(() => {
        if (event?.target?.style)
          event.target.style.setProperty("--scroll-display", "none")
      }, 800)
    }, 800)

    if (!isApple) {
      const element = document.getElementsByTagName("html")
      element[0].classList.add("scroll-visibility")
      window.addEventListener("scroll", handleScrollbarView, true)
    }

    return () => {
      if (!isApple) {
        window.removeEventListener("scroll", handleScrollbarView)
        if (scrollTimerRef.current) clearTimeout(scrollTimerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!isLoggedIn) return;
    if (!params?.tenantId) {
      dispatch(getACL({byRole: true})).then(() => {
        dispatch(getBillingData());
      });
    }
    dispatch(initializeDomainSetting())
  }, [dispatch, isLoggedIn, params?.tenantId])
  useEffect(() => {
    if (!isLoggedIn) return;
    if (userId && user?.email) {
      // setting cleverTap user data
      cleverTap.onUserLogin({
        Name: user?.nickname,
        Email: user?.email,
        Identity: user?.email,
        // isSubscribed: user?.isSubscribed,
        // isTrial: user?.isTrial,
        // userId: user?.userId,
        // hasLifeTimeDeal: user?.hasLifeTimeDeal,
        // trialExpiresAt: user?.trialExpiresAt,
      })
      // setting posthog user data
      if (posthogKey && posthogHost) {
        posthog.identify(user?.email, {
          email: user?.email,
          userId: userId
        })
      }
    }
  }, [user?.nickname, user?.email, userId, user?.isSubscribed, user?.isTrial, user?.hasLifeTimeDeal, user?.trialExpiresAt, isLoggedIn, posthogKey, posthogHost])

  useEffect(() => {
    dispatch(getDomainConfig());
  }, [dispatch])

  useEffect(() => {
    if (!isLoggedIn || !token || !userId) {
      console.log(window.location.pathname, ROUTE_PATH.RESET_PASSWORD, "window.location.pathname");
      
      const isOldUser = getDataFromLocalStorage(
        LOCAL_STORAGE.IS_OLD_USER,
        false
      )
      if (logoutSuccess) window.location.assign(ROUTE_PATH.WEBSITE_HOME)
      else {
        if (
          window.location.pathname === ROUTE_PATH.LOGIN ||
          window.location.pathname === ROUTE_PATH.SIGNUP ||
          window.location.pathname === ROUTE_PATH.VERIFY_ENTERPRISE_AUTH ||
          window.location.pathname === ROUTE_PATH.WEBSITE_PRICING ||
          window.location.pathname === ROUTE_PATH.FORGOT_PASSWORD ||
          window.location.pathname === ROUTE_PATH.RESET_PASSWORD) return;
        setDataInLocalStorage(LOCAL_STORAGE.LAST_ROUTE, window.location.pathname+window.location.search)
        if (!isWhiteLabelDashboard){
          if(window.location.pathname === ROUTE_PATH.MAIN_PRICING && window.location.search === ""){
            window.location.assign(ROUTE_PATH.WEBSITE_PRICING)
          }
        }
        window.location.assign(isOldUser ? ROUTE_PATH.LOGIN : ROUTE_PATH.SIGNUP)
      }
    }
  }, [isLoggedIn, logoutSuccess, token, userId, isWhiteLabelDashboard])

  useEffect(() => {
    if (isLoggedIn && token && userId) {
      const deviceMeta = getDataFromLocalStorage(LOCAL_STORAGE.IP_META)
      const isOldUser = getDataFromLocalStorage(LOCAL_STORAGE.IS_OLD_USER);
      dispatch(fetchMyProfile()).then((res) => {
        if (window) {
          /** Sending data to gtag about login/signup events **/
          const authType = getDataFromLocalStorage(LOCAL_STORAGE.AUTH_TYPE);
          const authMethod = getDataFromLocalStorage(LOCAL_STORAGE.AUTH_METHOD);
          if (window && authMethod && authType) {
            window.gtag('event', authType, {
              method: authMethod,
              userId: res.payload?.data?.userId
            })
            setDataInLocalStorage(LOCAL_STORAGE.AUTH_TYPE, null);
            setDataInLocalStorage(LOCAL_STORAGE.AUTH_METHOD, null);
          }
        }
        const userRole = res.payload?.data?.role;
        dispatch(getBillingData())
        const updatedData = {deviceMeta: deviceMeta};
        if (!isOldUser) {
          updatedData.utmParams = getDataFromLocalStorage(LOCAL_STORAGE.UTM_PARAMS) || {}
        }
        if (res.payload?.data?.timezone == null) {
          updatedData.timezone = deviceMeta?.timezone;
        }
        return dispatch(updateUserDetails(updatedData));
      }).then(() => {
        setDataInLocalStorage(LOCAL_STORAGE.IS_OLD_USER, true)
        setDataInLocalStorage(LOCAL_STORAGE.UTM_PARAMS, {})
        return dispatch(getUserQuota());
      })
    }
  }, [dispatch, isLoggedIn, token, userId])


  // polling to update upstream data
  useEffect(() => {
    if (!isLoggedIn) return;
    const intervalTimer = setInterval(() => {
      if (botUpstreamData && botUpstreamData.feStatus === WORKSPACE_UPSTREAM_STATUS.ADDED && botUpstreamData.live === false) {
        dispatch(getUserUpstreamList());
      } else if (domainUpstreamData && domainUpstreamData.feStatus === WORKSPACE_UPSTREAM_STATUS.ADDED && domainUpstreamData.live === false) {
        dispatch(getUserUpstreamList());
      }
    }, 1000 * 60);
    return () => {
      clearInterval(intervalTimer);
    };
  }, [dispatch, botUpstreamData, domainUpstreamData, isLoggedIn]);

  // initializing global theme
  useEffect(() => {
    let currentTheme = themeMode
    // checking system theme
    if(applySystemTheme === true) {
      currentTheme = systemTheme;
      dispatch(updatePageState({
        themeMode: systemTheme
      }))
    }
    let theme = themeConfig[currentTheme];
    if (!isWhiteLabelDashboard) {
      dispatch(updatePageState({
        theme: theme
      }))
      setIsLoadingTheme(false);
    } else {
      const localDomainConfig = getDataFromLocalStorage(LOCAL_STORAGE.DOMAIN_CONFIG, null);
      const localDomainTheme = localDomainConfig?.branding?.theme;
      if (localDomainConfig) {
        if (localDomainTheme?.isCustomActive && localDomainTheme?.primaryColor && localDomainTheme?.secondaryLightColor && localDomainTheme?.secondaryDarkColor) {
          theme = getThemeObject(currentTheme, localDomainTheme?.primaryColor, localDomainTheme?.secondaryLightColor, localDomainTheme?.secondaryDarkColor);
        }
        dispatch(updatePageState({
          theme: theme
        }))
        setIsLoadingTheme(false);
      }
    }
  }, [themeMode, domainConfigTheme, applySystemTheme, systemTheme, dispatch, isWhiteLabelDashboard])

  if (isLoadingTheme || !userTheme) return <SpinnerLoader brand/>;
  return (
    <PostHogProvider client={posthog}>
    <ThemeProvider theme={createTheme(userTheme)}>
      <CssBaseline/>
      <Favicon url={domainConfig?.branding?.logoUrlSmall || ""}/>
      <SnackbarProvider
        style={{
          maxWidth: 400,
        }}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        iconVariant={{
          success: <CheckCircleIcon sx={{mr: 1}}/>,
          error: <ReportIcon sx={{mr: 1}}/>,
          warning: <WarningIcon sx={{mr: 1}}/>,
          info: <InfoIcon sx={{mr: 1}}/>,
        }}
        action={(snackbarId) => (
          <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        )}
        preventDuplicate
      />
      <Router>
        <ErrorBoundary>
          <GlobalInformation/>
        </ErrorBoundary>
        <ResetScroll/>
        <Routes>
          <Route
            path={ROUTE_PATH.VERIFY_GOOGLE_AUTH}
            element={<GoogleAuthentication/>}
          />
          <Route
            path={ROUTE_PATH.VERIFY_ENTERPRISE_AUTH}
            element={<EnterpriseAuthentication/>}
          />
          <Route
            path={ROUTE_PATH.VERIFY_EMAIL}
            element={<EmailVerification/>}
          />
          <Route
            path={ROUTE_PATH.VERIFY_INSTAGRAM_AUTH}
            element={<InstagramAuthentication/>}
          />
          <Route
            path={ROUTE_PATH.VERIFY_GBM_AUTH}
            element={<GbmAuthentication/>}
          />
          <Route
            path={ROUTE_PATH.VERIFY_HUBSPOT}
            element={<HubspotAuthentication/>}
          />
          <Route
            path={ROUTE_PATH.VERIFY_SALESFORCE}
            element={<SalesforceAuthentication/>}
          />
          <Route
            path={ROUTE_PATH.VERIFY_GOOGLE_SHEETS}
            element={<GoogleSheetAuthentication/>}
          />
          {isWhiteLabelDashboard ? (
            <Route path={ROUTE_PATH.WEBSITE_HOME}
                   element={
                     <Suspense fallback={<SpinnerLoader brand/>}>
                       <WhiteLabeledAuthContainer/>
                     </Suspense>
                   }/>
          ) : (
            <Route
              path={ROUTE_PATH.WEBSITE_HOME}
              element={
                <Suspense fallback={<SpinnerLoader brand/>}>
                  <WebsiteContainer/>
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Home/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.WEBSITE_PRICING}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Pricing/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.WEBSITE_FEATURED_BOTS}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <FeaturedBots/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.WEBSITE_PRIVACY_POLICY}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <PrivacyPolicy/>
                  </Suspense>
                }
              />
            </Route>
          )}
          <Route
            element={
              <Suspense fallback={<SpinnerLoader brand/>}>
                {process.env.REACT_APP_WHITE_LABELED_APP === 'true' ? <WhiteLabeledAuthContainer/> : <AuthContainer/>}
              </Suspense>
            }
          >
            <Route
              path={ROUTE_PATH.LOGIN}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <Login/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SIGNUP}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <Signup/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.FORGOT_PASSWORD}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <ForgotPassword/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.RESET_PASSWORD}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <ResetPassword/>
                </Suspense>
              }
            />
            </Route>
            {isWhiteLabelDashboard === false && (
              <Route
                path={ROUTE_PATH.WELCOME_ONBOARDING}
                element={
                  <Suspense fallback={<SpinnerLoader />}>
                    <WelcomeOnboarding />
                  </Suspense>
                }
              />
            )}
          <Route
            path={ROUTE_PATH.DASHBOARD}
            element={
              <Suspense fallback={<SpinnerLoader/>}>
                <PageContainer/>
              </Suspense>
            }
          >
            <Route
              index
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <Dashboard/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.ONBOARDING}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <CreateBot/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.CREATE_BOT}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <OnBoarding/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.CREATE_E_COMMERCE_BOT}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <ECommerceOnboarding/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.REDIRECT_SHOPIFY}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <RedirectShopify/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.SUPPORT}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <SupportPage/>
                </Suspense>
              }
            />
            {process.env.REACT_APP_REFERRAL_ENABLED === "true" && (
              <Route
                path={ROUTE_PATH.REFERRAL}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Referral/>
                  </Suspense>
                }
              />
            )}
            <Route
              path={ROUTE_PATH.BILLING_HOME}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  {maintenanceInfo?.servicesUnderMaintenance.includes(SERVICE_KEY.BILLING) ?
                    <MaintenanceOverlay/> : <BillingHome/>}
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.MAIN_PRICING}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  {maintenanceInfo?.servicesUnderMaintenance.includes(SERVICE_KEY.STRIPE_BILLING) ?
                    <MaintenanceOverlay/> : <MainPricing/>}
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.THANK_YOU}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <PaymentSuccess/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.TENANT_OVERVIEW}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <TenantContainer/>
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Overview/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_EDIT_KNOWLEDGE}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <EditKnowledge/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_TEST_BOT}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <TestBot/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_ROUTE_BASED_NOTIFICATIONS}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <RouteBasedNotifications/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_LIVE_CHATS}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <LiveChats/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_CHAT_HISTORY}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <ChatHistory/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_TRAINING}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Training/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_LEAD_CAPTURE}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <LeadCapture/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_FEEDBACK}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Feedback/>
                  </Suspense>
                }
              />
              <Route
                path={ROUTE_PATH.TENANT_SETTINGS}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Settings/>
                  </Suspense>
                }
              >
                <Route
                  path={ROUTE_PATH.TENANT_SETTINGS}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <AdvancedSettings/>
                    </Suspense>
                  }
                />
                <Route
                  path={ROUTE_PATH.TENANT_MEMBER_SETTINGS}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <Members/>
                    </Suspense>
                  }
                />
                <Route
                  path={ROUTE_PATH.TENANT_HUMAN_ASSIST_SETTINGS}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <HumanAssistSettings/>
                    </Suspense>
                  }
                />
                <Route
                  path={ROUTE_PATH.TENANT_BOT_APPEARANCE}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <BotAppearance/>
                    </Suspense>
                  }
                />
                <Route
                  path={ROUTE_PATH.TENANT_LEADS_QUALIFICATION}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <Leads/>
                    </Suspense>
                  }
                />

                <Route
                  path={ROUTE_PATH.TENANT_INTEGRATIONS}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <Integrations/>
                    </Suspense>
                  }
                />
                <Route
                  path={ROUTE_PATH.TENANT_TEMPLATES}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <Templates/>
                    </Suspense>
                  }
                />
                <Route
                  path={ROUTE_PATH.TENANT_QUOTA}
                  element={
                    <Suspense fallback={<SpinnerLoader/>}>
                      <TenantQuota/>
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path={ROUTE_PATH.TENANT_CHANNELS}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <Channels/>
                  </Suspense>
                }
              />

              <Route
                path="*"
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <PageNotFound/>
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route
            path={ROUTE_PATH.WORKSPACE_SETTINGS}
            element={
              <Suspense fallback={<SpinnerLoader/>}>
                <WorkspaceSettings/>
              </Suspense>
            }
          >
            <Route
              path={ROUTE_PATH.WORKSPACE_GENERAL_SETTINGS}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <GeneralSettings/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.WORKSPACE_BRANDING_SETTINGS}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <BrandingSettings/>
                </Suspense>
              }
            >
              <Route
                path={ROUTE_PATH.WORKSPACE_BRANDING_SETTINGS_EMAIL}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <BrandingEmailSettings/>
                  </Suspense>
                }/>
              <Route
                path={ROUTE_PATH.WORKSPACE_BRANDING_SETTINGS_APPEARANCE}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <BrandingAppearanceSettings/>
                  </Suspense>
                }/>
              <Route
                path={ROUTE_PATH.WORKSPACE_BRANDING_SETTINGS_COMPANY_DETAILS}
                element={
                  <Suspense fallback={<SpinnerLoader/>}>
                    <BrandingCompanyDetails/>
                  </Suspense>
                }/>
            </Route>
            <Route
              path={ROUTE_PATH.WORKSPACE_DASHBOARD_SETTINGS}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <DashboardWhiteLabel/>
                </Suspense>
              }
            />
            <Route
              path={ROUTE_PATH.WORKSPACE_BOT_SETTINGS}
              element={
                <Suspense fallback={<SpinnerLoader/>}>
                  <BotWhiteLabel/>
                </Suspense>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <Suspense fallback={<SpinnerLoader/>}>
                <PageNotFound/>
              </Suspense>
            }
          />
        </Routes>
        <Suspense fallback={<SpinnerLoader/>}>
          <Profile/>
        </Suspense>
      </Router>
    </ThemeProvider>
    </PostHogProvider>
  )
}

export default App
