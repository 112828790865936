export const GET_WEBSITE_INFORMATION = "/bot/v2/nlp/fetch-website"
export const GET_TENANT_INFO_FROM_WEBSITE = "/iam/tenant/linkedin"
export const GET_URL_LIST_FROM_WEBSITE_TRAINING = "/bot/v2/nlp/url-fetch-status"
export const CREATE_NEW_TENANT = "/iam/tenant/create"
export const GET_TENANT_KNOWLEDGE = "/iam/tenant/:tenantId"
export const GET_BOT_APPEARANCE = "/bot/v2/bot-ui/:tenantId"
export const GET_TENANT_RESOURCE_URL_STATUS = "/ai/training/fetch-url-status"

export const GET_TENANT_INFO_DOCUMENT_LIST =
  "/bot/v2/nlp/:tenantId/knowledge-pdf"
export const ADD_TENANT_INFO_DOCUMENT =
  "/bot/v2/nlp/:tenantId/knowledge-pdf?tenantId=:tenantId&tenantCreatedByFrontend=:tenantCreatedByFrontend"
export const DELETE_TENANT_INFO_DOCUMENT = "/bot/v2/nlp/:tenantId/knowledge-pdf"

export const CLOUD_RESOURCE_GOOGLE_DOCS = "/bot/v2/nlp/google-docs?tenantId=:tenantId"
export const CLOUD_RESOURCE_GOOGLE_SHEETS = "/bot/v2/nlp/google-sheets?tenantId=:tenantId"

export const CLOUD_RESOURCE_STATUS = "/bot/v2/nlp/cloud-resources/status?tenantId=:tenantId"

export const AUTO_SYNC_RESOURCES = "/bot/v2/auto-sync?tenantId=:tenantId"

export const GET_TENANT_STATS = "/brain/v1/admin/analytics/static/count"
export const GET_TENANT_CHATLOG_TIME_SERIES =
  "/brain/v1/admin/analytics/static/timeSeries"
export const GET_TENANT_WORD_CLOUD_STATS = "/brain/v1/admin/analytics/static/ai-analytics"
export const TRAIN_TENANT_BOT = "/bot/v2/nlp/train/:tenantId"
export const GET_BOT_TRAINING_STATUS = "/bot/v2/nlp/training-status"
export const UPDATE_TENANT_INFO = "/iam/tenant/:tenantId"

export const GET_TENANT_CHATLOG_TIME_SERIES_ADMIN =
  "/brain/v1/superadmin/analytics/static/timeSeries"

export const UPDATE_BOT_APPEARANCE = "/bot/v2/bot-ui/:tenantId"
export const ADD_MEMBERS = "/iam/tenant/team/invitation"
export const GET_MEMBERS = "/iam/tenant/team/member/list"
export const DELETE_MEMBERS = "/iam/tenant/team/member/:memberId?tenantId=:tenantId&userId=:userId"
export const UPDATE_MEMBERS = "/iam/tenant/team/member/:memberId?tenantId=:tenantId"
export const UPDATE_INVITATION = "/iam/tenant/team/invitation/:inviteId?tenantId=:tenantId"
export const TRANSFER_TENANT =
  "/iam/tenant/:tenantId/transfer"
export const INVITE_MEMBERS = "/iam/tenant/team/invite-user?tenantId=:tenantId"
export const SUBMIT_USER_FEEDBACK = "/bot/v2/feedback/create"
export const GET_USER_FEEDBACK_DATA = "/bot/v2/feedback/generate"

export const GET_ROUTE_BASED_NOTIFICATION_LIST = "/bot/v2/notifications"
export const CREATE_ROUTE_BASED_NOTIFICATION =
  "/bot/v2/notifications?tenantId=:tenantId"
export const UPDATE_ROUTE_BASED_NOTIFICATION =
  "/bot/v2/notifications/:tenantId?tenantId=:tenantId"
export const DELETE_ROUTE_BASED_NOTIFICATION =
  "/bot/v2/notifications/:tenantId?tenantId=:tenantId&_id=:id"

export const LIVE_CHAT_ENABLED = "/iam/tenant/advanced/liveChat/status"

export const GET_TENANT_QUOTA = "/iam/billing/tenantQuota/:tenantId"
export const UPDATE_TENANT_QUOTA = "/iam/billing/tenantQuota/:tenantId"

export const LIVE_CHAT_CONFIG = "/liveChatConfigurations"
export const GET_QUOTA_LIST = "/brain/v1/admin/analytics/static/quota"
export const VALIDATE_BYOK = "/iam/tenant/openAiKey/validation"

export const LIST_INVITED_MEMBER = "/iam/tenant/team/invitation/list"
export const DELETE_INVITED_MEMBER = "/iam/tenant/team/invitation/:id?tenantId=:tenantId"

export const UPDATE_DYNAMIC_LEAD_STATUS = "/bot/v2/leads/update"

export const GET_DYNAMIC_FEEDBACK_FORM_TEMPLATE = "/bot/v2/feedback/template"
export const POST_FEEDBACK_FORM_DATA = "/bot/v2/feedback/create-feedback"

// Analytics stats
export const GET_LCS_STATS = "/brain/v1/admin/analytics/static/count/lcs"
export const GET_COUNTRY_STATS = "/brain/v1/admin/analytics/static/count/country"
export const GET_LANGUAGES_STATS = "/brain/v1/admin/analytics/static/count/languages"
export const GET_CHANNELS_STATS = "/brain/v1/admin/analytics/static/count/channels"
export const GET_ENGAGEMENT_STATS = "/brain/v1/admin/analytics/static/count/engagement"
export const GET_LEADS_STATS = "/brain/v1/admin/analytics/static/count/leads"
export const GET_FEEDBACKS_STATS = "/brain/v1/admin/analytics/static/count/feedbacks"
export const GET_CONVERSATION_STATS = "/brain/v1/admin/analytics/static/count/conversations"


export const UPLOAD_MESSAGE_MEDIA_IMAGE = "/bot/v2/bot-ui/message-media/:tenantId?tenantId=:tenantId"

// E-commerce
export const SYNC_SMART_CHATBOT = "/ai/ecom/:platform/products/sync"
export const SYNC_CATALOG = "/ai/ecom/orimonCommerce/products/sync?tenantId=:tenantId"
export const APPEND_CATALOG = "/ai/ecom/orimonCommerce/products/append?tenantId=:tenantId"
export const DELETE_CATALOG = "/ai/ecom/orimonCommerce/products/delete?tenantId=:tenantId"
export const CREATE_NEW_SMART_TENANT = "iam/tenant/create-smart"
export const GET_TENANT_STORE_DATA = "/ai/ecom/orimonCommerce/storeInfo"
export const INTEGRATE_WO_COMMERCE = "/ai/ecom/woocommerce/integrate"
export const INTEGRATE_CATALOG = "/ai/ecom/orimonCommerce/integrate"
export const INTEGRATE_SHOPIFY = "/ai/ecom/shopify/integrate";
export const SHOPIFY_VERIFY = "/ai/ecom/shopify/verify";
export const WOO_COMMERCE_VERIFY = "/ai/ecom/woocommerce/verify";
export const GET_SYNC_INFO = "/ai/ecom/syncInfo"
export const GET_ALL_ECOM_PRODUCTS = "/ai/ecom/get-all-products"
export const TRAIN_ECOM_BOT = "ai/ecom/trainEcommerceStore"
export const GET_ECOM_PRODUCT_STATUS = "ai/ecom/products/status"
