import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import { Box, Checkbox, MenuItem, Select, TextField, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { updateInvitations } from "../../../data/store/overviewSlice"

const EditMemberInvite = ({ defaultData = {}, onComplete = () => {} }) => {
  const dispatch = useDispatch()
  const { tenantId } = useParams()
  const [member, setMember] = useState(defaultData?.email || "")
  const [role, setRole] = useState(defaultData?.role || "tenantManager")
  const [isLiveAgent, setIsLiveAgent] = useState(defaultData?.isLiveAgent || false);
  const [extendInvite, setExtendInvite] = useState(true);
  const modalLoading = useSelector((state) => state.pageDetails.modalLoading)

  const handleMemberAddition = (event) => {
    setMember(event.target.value)
  }

  const handleIsLiveAgentChanged = (event) => {
    setIsLiveAgent(event.target.checked);
  }

  const handleExtendInviteChanged = (event) => {
    setExtendInvite(event.target.checked);
  }

  const updateInvitation = () => {
    dispatch(updateInvitations(defaultData?._id, tenantId, {
      updates: {
        role,
        isLiveAgent,
      },
      extendInvite: extendInvite
    },
      () => {
        onComplete();
        enqueueSnackbar(`Successfully updated invitation`, {
          variant: 'success'
        })
      }
    ))
  }

  return (
    <>
      <TextField
        placeholder="Enter email address"
        value={member}
        onChange={handleMemberAddition}
        size="small"
        sx={{ mb: 1 }}
        fullWidth
        disabled
      />
      <Box display="flex" my={1}>
        <Select
          onChange={(e) => setRole(e.target.value)}
          value={role}
          size="small"
          sx={{ flex: 1 }}
        >
          <MenuItem value="tenantManager">Bot Manager</MenuItem>
        </Select>
        <Box marginLeft={2}>
          <Typography variant="caption">Live Agent</Typography>
          <Checkbox title="Live Agent" defaultChecked={isLiveAgent} value={isLiveAgent} onChange={handleIsLiveAgentChanged} />
        </Box>
        <Box marginLeft={2}>
          <Typography variant="caption">Extend Invite</Typography>
          <Checkbox title="Live Agent" defaultChecked value={isLiveAgent} onChange={handleExtendInviteChanged} />
        </Box>
        <LoadingButton
          size="small"
          variant="contained"
          sx={{ ml: 2 }}
          onClick={updateInvitation}
          loading={modalLoading}
          disabled={member === "" || modalLoading}
        >
          Update Invite
        </LoadingButton>
      </Box>
    </>
  )
}

export default EditMemberInvite
