import { BREAKPOINT_VALUES, THEME_MODE } from "../configs/constants"
import light from "../theme/light"
import dark from "../theme/dark"

export const getThemeObject = (themeMode, primary, secondaryLight, secondaryDark) => {
    if (themeMode === THEME_MODE.LIGHT) {
        return {
            ...light,
            palette: {
                ...light.palette,
                mode: themeMode,
                primary: {
                    main: primary,
                },
                secondary: {
                    main: secondaryLight,
                },
            },
        }
    }
    return {
        ...dark,
        palette: {
            ...dark.palette,
            mode: themeMode,
            primary: {
                main: primary,
            },
            secondary: {
                main: secondaryDark,
            },
        },
    }
}