import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import { fetchDataForToolkit } from "../helpers";
import { FETCH_AVAILABLE_JOURNEYS } from "./api";

const userJourneySlice = createSlice({
  initialState: initialState,
  name: "userJourney",
  reducers: {
    updateUserJourneyState(state, action) {
      return { ...state, ...action.payload };
    },
    updateUserJourneyDrawerState(state, action) {
      return {
        ...state,
        journeyDrawerState: {
          ...state.journeyDrawerState,
          ...action.payload,
        },
      };
    },
    updateGuidedJourneyState(state, action) {
      return {
        ...state,
        guidedJourneyState: {
          ...state.guidedJourneyState,
          ...action.payload,
        },
      };
    },
    trackUserJourneyEvent(state, action){
      const { type } = action.payload;
      return {
        ...state,
        trackedEvents: {
          ...state.trackedEvents,
          [type]: state.trackedEvents[type] ? {
            count: state.trackedEvents[type].count + 1,
            payload: action.payload
          }: {
            count: 1,
            payload: action.payload
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAvailableJourneys.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAvailableJourneys.fulfilled, (state, { payload }) => {
      if (payload) {
        state.journeys = payload.data.journeys || [];
        state.steps = payload.data.steps || [];
        state.journeyOrder = payload.data.journeyOrder || [];
      }
      state.loading = false;
    });
    builder.addCase(getAllAvailableJourneys.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const getAllAvailableJourneys = createAsyncThunk(
  "userJourney/getAllAvailableJourneys",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: FETCH_AVAILABLE_JOURNEYS,
        data: data,
        loader: false,
      },
      helpers,
    ),
);

export const { updateUserJourneyState, updateUserJourneyDrawerState, trackUserJourneyEvent, updateGuidedJourneyState } =
  userJourneySlice.actions;
export default userJourneySlice.reducer;