import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { alpha, circularProgressClasses, useTheme } from "@mui/material";

function CircularProgressWithLabel({ color='primary', isSwitch=false, ...restProps }) {
  const theme = useTheme();
  if (isSwitch) {
    const isCompleted = restProps.value === 100;
    return (
      <Typography variant="h5"
        color={theme.palette[color].main}>
        {isCompleted ? (
          <i class="ri-checkbox-circle-line"></i>
        ) : (
          <i class="ri-circle-line"></i>
        )}
      </Typography>
    );
  }
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...restProps} value={restProps?.value > 100 ? 100 : restProps?.value} thickness={5}
        sx={{
          zIndex: 1,
          color: theme.palette[color].main,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: alpha(theme.palette[color].main, 0.2),
          borderRadius: "50%",
        }}
      >
        <Typography
          fontSize={((restProps.size || 54) * (0.25)) || 'auto'}
          fontWeight="bold"
          component="div"
          color={theme.palette[color].main}
        >{`${Math.round(restProps.value)}%`}</Typography>
      </Box>
    </Box>
  );
}


export default CircularProgressWithLabel;
