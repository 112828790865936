import styled from '@emotion/styled'
import { Chip, alpha, lighten } from '@mui/material'
import { THEME_MODE } from '../../../data/configs/constants'

export const StyledChips = styled(Chip)(({theme}) => ({
    "&.MuiChip-root": {
        borderRadius: 8
    },
    "&.MuiChip-colorPrimary" : {
        backgroundColor: alpha(theme.palette.primary.main, 0.1) ,
        color: theme.palette.mode === THEME_MODE.LIGHT ? theme.palette.primary.main : lighten(theme.palette.primary.main, 0.5)
    },
    "&.MuiChip-colorWarning": {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
        color: theme.palette.warning.main
    },
    "&.MuiChip-colorSuccess": {
        backgroundColor: alpha(theme.palette.success.main, 0.1),
        color: theme.palette.success.main
    },
    "&.MuiChip-colorSecondary": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.1),
        color: theme.palette.secondary.main
    },
    "&.MuiChip-colorTertiary": {
        backgroundColor: alpha(theme.palette.tertiary.main, 0.1),
        color: theme.palette.tertiary.main
    },
    "&.MuiChip-colorError": {
        backgroundColor: alpha(theme.palette.error.main, 0.1),
        color: theme.palette.error.main
    },
    "&.MuiChip-colorInfo": {
        backgroundColor: alpha(theme.palette.info.main, 0.1),
        color: theme.palette.info.main
    }
}))