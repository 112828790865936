import React, { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
 
import SpinnerLoader from "../SpinnerLoader"

import {
  getDataFromLocalStorage,
} from "../../../data/utils"
import {
  CHANNELS,
  LOCAL_STORAGE,
  ROUTE_PATH,
} from "../../../data/configs/constants"

import { integrateInstagramPages } from "../../../data/store/channelSlice"

function InstagramAuthentication() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const arr = location.hash.split("&")
    const payload = {
      tenantId: getDataFromLocalStorage(
        LOCAL_STORAGE.INSTAGRAM_TENANT_ID,
        null
      ),
      userAccessToken: arr[0].substring(14),
    }
    if (payload.tenantId && payload.userAccessToken) {
      dispatch(integrateInstagramPages(payload))
        .unwrap()
        .then((res) => {
          if (res && res.data) {
            navigate(
              `${ROUTE_PATH.TENANT_CHANNELS.replace(
                ":tenantId",
                payload.tenantId
              )}?channel=${CHANNELS.INSTAGRAM}`
            )
          }
        })
        .catch((err) => {
          navigate(
            `${ROUTE_PATH.TENANT_CHANNELS.replace(
              ":tenantId",
              payload.tenantId
            )}?channel=${CHANNELS.INSTAGRAM}`
          )
        })
    } else {
      navigate(ROUTE_PATH.DASHBOARD)
    }
    localStorage.removeItem(LOCAL_STORAGE.INSTAGRAM_TENANT_ID)
  }, [dispatch, location.hash, navigate])

  return <SpinnerLoader brand />
}
export default InstagramAuthentication
