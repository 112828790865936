import styled from "@emotion/styled";
import { ListItemButton, alpha } from "@mui/material";

export const StyledListItemButton = styled(ListItemButton)(({theme, collapse}) => ({
    borderRadius: 8,
    marginBottom: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    "&.Mui-selected": {
      color: theme.palette.text.buttonPrimary,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.8),
      },
    },
    "&.MuiListItemButton-root": {
      [collapse === "true"
        ? theme.breakpoints.up("sm")
        : theme.breakpoints.between("sm", "md")]: {
        marginLeft: 10,
        marginRight: 10,
        padding: 1,
      },
    },
  }))