import styled from '@emotion/styled'
import { WorkspacePremiumOutlined } from '@mui/icons-material';
import { Grid, Stack, Tooltip, Typography, alpha, darken, lighten, tooltipClasses } from '@mui/material'
import React from 'react'
import { THEME_MODE } from '../../../data/configs/constants';


const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.mode === THEME_MODE.LIGHT ? 
        lighten(theme.palette.primary.main,0.8): 
        darken(theme.palette.primary.main,0.8)
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.mode === THEME_MODE.LIGHT ? 
        lighten(theme.palette.primary.main,0.8): 
        darken(theme.palette.primary.main,0.8),
        color: theme.palette.primary.main,
        padding: 12
    },
}));

const PaywallTooltip = ({
    plans,
    addOns,
    size = "default",
    title = "Get access to this feature",
    message = "Please subscribe to a plan or purchase addons from the list given below to unlock feature.",
    children
}) => {
    return (
        <StyledTooltip arrow title={<>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                        <WorkspacePremiumOutlined />
                        <Typography fontWeight={900}>{title}</Typography>
                    </Stack>
                </Grid>
            </Grid>
        </>}>
            {children}
        </StyledTooltip>
    )
}

export default PaywallTooltip