import { enqueueSnackbar } from "notistack";
export const getLogicFunctionFromString = (fnStr) => {
    if (!fnStr) return () => true;
    try {
        return new Function(fnStr)();
    } catch (err) {
        return () => true;
    }
}

export const aclAllowApi = (cb, aclData, method, showNotification = false) => {
    switch (method) {
        case 'GET': {
            if (!aclData?.get && showNotification) enqueueSnackbar("unauthorized", { variant: "error" });
            else if(aclData?.get) return cb();
            break;
        }
        case 'POST': {
            if (!aclData?.post && showNotification) enqueueSnackbar("unauthorized", { variant: "error" });
            else if(aclData?.post) return cb();
            break;
        }
        case 'PUT':{
            if (!aclData?.put && showNotification) enqueueSnackbar("unauthorized", { variant: "error" });
            else if(aclData?.put) return cb();
            break
        }
        case 'DELETE':{
            if (!aclData?.delete && showNotification) enqueueSnackbar("unauthorized", { variant: "error" });
            else if(aclData?.delete) return cb();
            break;
        }
        default: {
            if(showNotification) enqueueSnackbar("unauthorized", { variant: "error" });
            return null;
        }
    }
}