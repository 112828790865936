import dayjs from "dayjs"

const initialState = {
  feedbackList: [],
  totalCount: 0,
  endDate: dayjs().format(),
  startDate: dayjs().subtract(7, "days").format(),
}

export default initialState
