import { Close } from '@mui/icons-material'
import { IconButton, Modal, Paper } from '@mui/material'
import React from 'react'

const CenteredPaperModal = ({open, onClose, width=800, children, sx={}}) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Paper sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: width,
                maxWidth: '90vw',
                maxHeight: '90vh',
                overflow:'auto',
                p: 2,
                borderRadius: 2,
                boxShadow: 0,
                ...sx,
            }}>
                <IconButton size='small' onClick={onClose} sx={{
                    position:'absolute',
                    top: 16,
                    right: 16
                }}>
                    <Close />
                </IconButton>
                {children}
            </Paper>
        </Modal>
    )
}

export default CenteredPaperModal