import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { addMembersAndInvitation, getMembers, updateMembers } from "../../../data/store/overviewSlice"
import { Checkbox, Grid, Typography } from "@mui/material"
import { verifyEmailFormatUsingRegex } from "../../../data/utils"
import * as _ from 'lodash';
import StyledTextField from "../StyledTextfieldV2"
import { StyledLoadingButton } from "../StyledLoadingButton"
import AclComponentWrapper from "../AclComponentWrapper"
import { ACL_IDENTIFIERS } from "../../../data/configs/constants"

const AddMember = ({ defaultData = {}, onComplete = () => { }, onClose = () => {}}) => {
  const dispatch = useDispatch()
  const { tenantId } = useParams()
  const [member, setMember] = useState(defaultData?.email || "")
  const [role, setRole] = useState(defaultData?.role || "tenantManager")
  const [isLiveAgent, setIsLiveAgent] = useState(defaultData?.isLiveAgent || false);
  const [updatedKeys, setUpdatedKeys] = useState([]);
  const [memberError, setMemberError] = useState(false);
  const isUpdate = defaultData?._id || defaultData?.member || defaultData?.role || defaultData?.isLiveAgent;
  const modalLoading = useSelector((state) => state.pageDetails.modalLoading)
  const currentPageAcl = useSelector((state) => state.pageDetails?.currentPageAcl);

  const handleMemberAddition = (event) => {
    if(updatedKeys.indexOf('email') === -1){
      setUpdatedKeys([...updatedKeys, 'email']);
    }
    setMember(event.target.value)
    setMemberError(!verifyEmailFormatUsingRegex(event.target.value));
  }

  const handleIsLiveAgentChanged = (event) => {
    if(updatedKeys.indexOf('isLiveAgent') === -1){
      setUpdatedKeys([...updatedKeys, 'isLiveAgent']);
    }
    setIsLiveAgent(event.target.checked);
  }

  const handleRoleChange = (event) => {
    if(updatedKeys.indexOf('role') === -1){
      setUpdatedKeys([...updatedKeys, 'role']);
    }
    setRole(event.target.value);
  }

  const showMessages = (msgArr=[], type="success") => {
    msgArr.forEach(msg => {
      enqueueSnackbar(msg, {
        variant: type,

      })
    })
  }

  const addTeamMember = () => {
    dispatch(
      addMembersAndInvitation(
        {
          tenantId,
          invites: [
            {
              role,
              email: member,
              isLiveAgent
            }
          ]
        },
        (res) => {
          const invitations = res?.data?.invitations || [];
          const assignations = res?.data?.assignations || [];
          if (invitations?.success?.length > 0 || assignations?.success?.length > 0)
            showMessages([...invitations?.success, ...assignations?.success], "success");
          if (invitations?.warnings?.length > 0 || assignations?.warnings?.length > 0)
            showMessages([...invitations?.warnings, ...assignations?.warnings], "warning");
          if (invitations?.errors?.length > 0 || assignations?.errors?.length > 0)
            showMessages([...invitations?.errors, ...assignations?.errors], "error");
          dispatch(
            getMembers({
              tenantId,
            })
          )
          setMember("")
          onClose();
        }
      )
    )
  }

  const updateMember = () => {
    const updates = {
      email: member,
      role,
      isLiveAgent
    }
    dispatch(updateMembers(defaultData?._id, tenantId, {
      updates: _.pick(updates, updatedKeys)
    },
      (res) => {
        const invitations = res?.data?.invitations || [];
        const assignations = res?.data?.assignations || [];
        if(invitations?.success?.length > 0 || assignations?.success?.length > 0)
          showMessages([...invitations?.success, ...assignations?.success], "success");
        if(invitations?.warnings?.length > 0 || assignations?.warnings?.length > 0)
         showMessages([...invitations?.warnings, ...assignations?.warnings], "warning");
        if(invitations?.errors?.length > 0 || assignations?.errors?.length > 0)
         showMessages([...invitations?.errors, ...assignations?.errors], "error");
        dispatch(
          getMembers({
            tenantId,
          })
        )
        setMember("")
        onComplete();
       }
    ))
  }

  const handleSubmit = () => {
    if (isUpdate) {
      updateMember();
    } else {
      addTeamMember();
    }
  }

  return (
  <Grid container spacing={2}>
    <Grid item xs={12} >
      <Typography variant="h6" gutterBottom>Add a team member</Typography>
    </Grid>
    <Grid item xs={12}>
      {memberError && <Typography variant="caption" color="error">Invalid Email Id</Typography>}
      <StyledTextField
        placeholder="Enter email address"
        value={member}
        onChange={handleMemberAddition}
        size="small"
        fullWidth
        disabled={isUpdate}
      />
    </Grid>
    <Grid item xs={12}>
      <Box display="flex">
        <Select
          onChange={handleRoleChange}
          value={role}
          size="small"
          sx={{ flex: 1 }}
        >
          <MenuItem value="tenantManager">Bot Manager</MenuItem>
        </Select>
        <AclComponentWrapper payWallSize="icon" componentAcl={currentPageAcl?.[ACL_IDENTIFIERS.ADD_TEAM_MEMBERS]?.[ACL_IDENTIFIERS.LIVE_AGENT]}>
        <Box marginLeft={2}>
          <Typography variant="caption">Live Agent</Typography>
          <Checkbox title="Live Agent" defaultChecked={isLiveAgent} onChange={handleIsLiveAgentChanged} />
        </Box>
        </AclComponentWrapper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <StyledLoadingButton
          size="small"
          variant="contained"
          onClick={handleSubmit}
          loading={modalLoading}
          disabled={member === "" || modalLoading || memberError}
          sx={{ p: 1 }}
          fullWidth
        >
          {isUpdate ? "Update Member" : "Assign role"}
        </StyledLoadingButton>
      </Grid>
    </Grid>
  )
}

export default AddMember
