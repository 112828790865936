export const verifyEmailFormatUsingRegex = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

export const verifyDomainFormat = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
}

export const isURL = (str) => {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+\.[a-z.]{2,6}|[\d.]+)([/?#].*)?$/;
    return urlPattern.test(str);
  }

export function isValidHttpsUrl(url) {
    const pattern = /^https:\/\//;
    return pattern.test(url);
}