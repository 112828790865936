import { verifyDomainFormat, verifyEmailFormatUsingRegex } from "./validation"

export const validateFormFields = ({value, type, required}) => {
    if (!value && required) {
        return {
            valid: false,
            msg: "Required"
        }
    } else if (value) {
        if (type === 'email' && !verifyEmailFormatUsingRegex(value)) {
            return {
                valid: false,
                msg: "Invalid Email"
            }
        } else if (type === 'domain' && !verifyDomainFormat(value)) {
            return {
                valid: false,
                msg: "Invalid Domain"
            }
        }
    }
    return {
        valid: true,
        msg: ""
    }
}