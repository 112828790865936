import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"

import { history } from "../../../data/store/helpers"

import CircularProgressWithLabel from "../CircularProgressWithLabel"

const GlobalInformation = () => {
  const currentPage = useSelector((state) => state.pageDetails.currentPage)
  const pageLoading = useSelector((state) => state.pageDetails.pageLoading)
  const domainConfig = useSelector((state) => state.workspaceSettingsDetails.domainConfig);
  const isWhiteLabeledDashboard = process.env.REACT_APP_WHITE_LABELED_APP === "true";
  const progressPercent = useSelector(
    (state) => state.pageDetails.progressPercent
  )
  const pageLoaderText = useSelector(
    (state) => state.pageDetails.pageLoaderText
  )

  history.navigate = useNavigate()
  history.location = useLocation()

  useEffect(() => {
    var title = "Orimon.ai - No-Code, Sales Enabling, Generative AI bot builder";
    if(isWhiteLabeledDashboard) title = domainConfig?.branding?.organizationLabel || "";
    document.title =
      currentPage || title
  }, [currentPage])

  if (!pageLoading) return false

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
      open={pageLoading}
    >
      <Paper className="od-animated od-fadeIn" sx={{ p: 2, minWidth: 300, display:'flex', alignItems: 'center', flexDirection: 'column' }}>
        {progressPercent !== null ? (
          <CircularProgressWithLabel
            value={progressPercent}
            size={40}
            color={'secondary'}
          />
        ) : (
          <CircularProgress color="secondary" />
        )}
        <Typography sx={{ my: 2, textTransform: "capitalize", maxWidth: 400, textAlign:'center' }} >
          {pageLoaderText}...
        </Typography>
      </Paper>
    </Backdrop>
  )
}

export default GlobalInformation
