import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { fetchDataForToolkit } from "../helpers"
import { METHOD_TYPES } from "../../configs/constants"
import initialState from "./initialState"
import {
  GET_FLAGGEDCHAT_LIST_FOR_USER,
  DELETE_FLAGGED_CHAT,
  UPDATE_FLAGGED_CHAT,
  FETCH_QNA,
  CREATE_FAQ,
  GET_FAQ_LIST,
  UPDATE_DELETE_FAQ,
  FETCH_EMBEDDINGS
} from "./api"

const trainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {
    updateTrainingState(state, action) {
      return { ...state, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlaggedChatList.pending, (state, { meta }) => {
        if (meta?.arg?.page === 1) {
          state.loading = true
        }
        state.loadError = false
      })
      .addCase(getFlaggedChatList.fulfilled, (state, { meta, payload }) => {
        if (meta?.arg?.page === 1) {
          if (payload.data) {
            state.flaggedChatList = payload.data.chatLogs
            state.hasMore =
              payload.data.chatLogs.length === meta?.arg?.limit
          }
        } else if (meta?.arg?.page > 1) {
          if (payload.data) {
            state.flaggedChatList = [
              ...state.flaggedChatList,
              ...payload.data.chatLogs
            ]
            state.hasMore =
              payload.data.chatLogs.length === meta?.arg?.limit
          }
        }
        state.loading = false
        state.loadError = false
      })
      .addCase(getFlaggedChatList.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(updateFlaggedChat.fulfilled, (state, { payload }) => {
        if (payload.data) {
          const index = state.flaggedChatList.findIndex(
            (item) => item.chatLogId === payload.data.chatLogId
          )
          if (index > -1) {
            state.flaggedChatList = [
              ...state.flaggedChatList.slice(0, index),
              payload.data,
              ...state.flaggedChatList.slice(index + 1)
            ]
          }
        }
      })
      .addCase(deleteFlaggedChat.fulfilled, (state, { payload }) => {
        if (payload.data) {
          const index = state.flaggedChatList.findIndex(
            (item) => item.chatLogId === payload.data.chatLogId
          )
          if (index > -1) {
            state.flaggedChatList = [
              ...state.flaggedChatList.slice(0, index),
              ...state.flaggedChatList.slice(index + 1)
            ]
          }
        }
      })
      .addCase(createFaq.fulfilled, (state, { payload }) => { 
        if (payload.data) {
          state.faqList = [
            ...state.faqList,
            payload.data?.newFaq
          ]
        }
      })
      .addCase(getFaqList.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.faqList = payload.data?.faqs
        }
      })
      .addCase(updateFaq.fulfilled, (state, { payload }) => {
        if (payload.data) {
          const index = state.faqList?.findIndex(
            (item) => item._id === payload.data._id
          )
          if (index > -1) {
            state.faqList = [
              ...state.faqList?.slice(0, index),
              payload.data,
              ...state.faqList?.slice(index + 1)
            ]
          }
        }
      })
      .addCase(deleteFaqData.fulfilled, (state, { meta }) => {
        const faqId = meta?.arg?.faqId
        if (faqId) {
          const index = state.faqList?.findIndex(
            (item) => item._id === faqId
          )
          if (index > -1) {
            state.faqList = [
              ...state.faqList?.slice(0, index),
              ...state.faqList?.slice(index + 1)
            ]
          }
        }
      })
  }
})

export const getFlaggedChatList = createAsyncThunk(
  "training/getFlaggedChatList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_FLAGGEDCHAT_LIST_FOR_USER,
        data: data,
        loader: false
      },
      helpers
    )
)

export const updateFlaggedChat = createAsyncThunk(
  "training/updateFlaggedChat",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_FLAGGED_CHAT,
        data: data,
        method: METHOD_TYPES.POST,
        loader: data.updatedText ? false : true,
        modalLoader: data.updatedText ? true : false,
      },
      helpers
    )
)

export const deleteFlaggedChat = createAsyncThunk(
  "training/deleteFlaggedChat",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: DELETE_FLAGGED_CHAT,
        data,
        method: METHOD_TYPES.DELETE
      },
      helpers
    )
)

export const fetchQnaForScrapedLink = createAsyncThunk(
  "training/fetchQnaForScrapedLink",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: FETCH_QNA,
        data: data,
        loader: false
      },
      helpers
    )
)

export const createFaq = createAsyncThunk(
  'training/createFaq',
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: CREATE_FAQ,
        data,
        method: METHOD_TYPES.POST
      },
      helpers
    )
)

export const getFaqList = createAsyncThunk(
  'training/getFaqList',
  async (data, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_FAQ_LIST,
        data
      },
      helpers
    )
);

export const updateFaq = createAsyncThunk(
  'training/updateFaq',
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_DELETE_FAQ.replace(':faqId', data.faqId),
        data,
        method: METHOD_TYPES.PUT
      },
      helpers
    )
);

export const deleteFaqData = createAsyncThunk(
  'training/deleteFaq',
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_DELETE_FAQ.replace(':faqId', data.faqId),
        data,
        method: METHOD_TYPES.DELETE
      },
      helpers
    )
);

export const fetchUrlEmbeddings = createAsyncThunk(
  'training/fetchUrlEmbeddings',
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: FETCH_EMBEDDINGS,
        data: data,
        loader: false,
        timeout: 30000
      },
      helpers
    )
)

export const { updateTrainingState } = trainingSlice.actions
export default trainingSlice.reducer
