import SpinnerLoader from "../SpinnerLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { connectGoogleSheets } from "../../../data/store/integrationSlice";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { ROUTE_PATH } from "../../../data/configs/constants";
const GoogleSheetAuthentication = () => {
  const dispatch = useDispatch();
  const url = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(url.search);
  const code = param.get("code");
  const storedTenantId = localStorage.getItem("GOOGLE_SHEET_TENANT_ID");
  const tenantId = JSON.parse(storedTenantId);
  const storedSheetId = localStorage.getItem("GOOGLE_SHEET_ID");
  const sheetId = JSON.parse(storedSheetId);

  useEffect(() => {
    dispatch(connectGoogleSheets({ tenantId, code, sheetId }))
      .unwrap()
      .then((res) => {
        if (res?.status === "success") {
          enqueueSnackbar("Connected",{variant: "success"});
        }
        const url = ROUTE_PATH.GOOGLESHEETS.replace(":tenantId",tenantId);
        localStorage.removeItem("GOOGLE_SHEET_TENANT_ID");
        localStorage.removeItem("GOOGLE_SHEET_ID");
        navigate(url);
      });
  },[]);

  return <SpinnerLoader />;
};
export default GoogleSheetAuthentication;