import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { enqueueSnackbar } from "notistack"

import { fetchDataForToolkit } from "../helpers"
import initialState from "./initialState"
import { ACL_IDENTIFIERS, METHOD_TYPES } from "../../configs/constants"
import { GET_TENANT_LIST, DELETE_TENANT } from "./api"
import { aclAllowApi } from "../../utils/acl"

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateDashboardState(state, action) {
      return { ...state, ...action.payload }
    },
    updateFilteredTenantList(state,action) {
      state.filteredTenantList = action.payload
    },
    updateBotOwnerFlag(state,action) {
      state.isBotOwner = action.payload
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTenantList.pending, (state) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getTenantList.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loadError = false
        if (payload.data) state.tenantList = payload.data
      })
      .addCase(getTenantList.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(deleteTenant.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.tenantList = state.tenantList.filter(
            (tenant) => tenant.tenantId !== payload.data.tenantId
          )
          enqueueSnackbar(
            payload.data.message || "Tenant deleted successfully",
            { variant: "success" }
          )
        }
      })
  },
})

export const getTenantList = createAsyncThunk(
  "dashboard/getTenantList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_LIST,
        data,
      },
      helpers
    )
)

export const deleteTenant = createAsyncThunk(
  "dashboard/deleteTenant",
  async (data = {}, helpers) =>{
    const {getState} = helpers;
    const acl = getState()?.authDetails?.acl;
    return aclAllowApi(() => fetchDataForToolkit(
      {
        url: DELETE_TENANT.replace(":tenantId", data.tenantId),
        data,
        method: METHOD_TYPES.DELETE,
        loader: false,
        modalLoader: true,
      },
      helpers
    ), acl?.[ACL_IDENTIFIERS.TENANT], METHOD_TYPES.DELETE, true)
  }
)

export const { updateDashboardState, updateFilteredTenantList, updateBotOwnerFlag } = dashboardSlice.actions
export default dashboardSlice.reducer
