import { Button, CircularProgress, Grid, Modal, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { uniqueId } from '../../../data/utils'
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import CustomDataGrid from "../CustomDataGrid"
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch, useSelector } from 'react-redux'
import { deleteInvitedMember, getInvitedMemberList } from '../../../data/store/overviewSlice'
import { useParams } from 'react-router-dom'
import CustomTable from '../CustomTable'
import { enqueueSnackbar } from 'notistack'
import RefreshIcon from '@mui/icons-material/Refresh';
import CenteredPaperModal from '../CenteredPaperModal'
import EditMemberInvite from '../EditMemberInvite'
import { StyledButton } from '../StyledButton'
import { StyledChips } from '../StyledChips'
import Card from "@mui/material/Card";


const ShowInvitedUsers = () => {
    const dispatch = useDispatch();
    const { tenantId } = useParams();
    const memberInvitationData = useSelector(state => state.overviewDetails.memberInvitations)
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 5,
    });
    const [defaultEditData, setDefaultEditData] = useState({})
    const [deleteLoading, setDeleteLoading] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const columns = [
        {
            field: "email",
            headerName: "Email",
            headerAlign: "center",
            width: 300,
        },
        {
            field: "role",
            headerName: "Role",
            headerAlign: "center",
            align: "center",
            renderCell: (parameters) => {
                if (parameters.row.role === "tenantManager")
                    return <Typography variant="body2">Bot Manager</Typography>
                else if (parameters.row.role === "owner")
                    return <Typography variant="body2">Owner</Typography>
            },
        },
        {
            field: "isLiveAgent",
            headerName: "Live Agent",
            headerAlign: "center",
            align: "center",
            renderCell: (parameters) => {
                return <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {parameters.row.isLiveAgent && <StyledChips size='small' color="primary" label='Live Agent'/>}
                    {!parameters.row.isLiveAgent && <StyledChips size='small' label='Not Live Agent'/>}
                </Box>
            }
        },
        {
            field: "status",
            headerName: "Status",
            headerAlign: "center",
            align: "center",
            width: 250,
            renderCell: (parameters) => {
                return (
                    <Grid container alignItems='center' justifyContent='center' borderRadius={1} padding={1} maxWidth={300} margin="auto" sx={{
                        backgroundColor: parameters.row.status === "accepted" ? 'rgba(0, 255, 0, 0.1)' :
                            parameters.row.status === "declined" ? 'rgba(255,0,0,0.1)' : 'rgba(242, 147, 5, 0.1)',
                        color: parameters.row.status === "accepted" ? 'success.main' :
                            parameters.row.status === "declined" ? 'error.main' : 'warning.main',
                    }}>
                        <Grid item sx={{ display: 'flex' }} xs={2}>
                            {parameters.row.status === "accepted" ? <CheckCircleOutlinedIcon /> :
                                parameters.row.status === "declined" ? <ErrorOutlineOutlinedIcon /> : <HourglassTopOutlinedIcon />}
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body2" fontWeight={500}>
                                {parameters.row.status === "accepted" ? 'Accepted' :
                                    parameters.row.status === "declined" ? 'Expired/Declined' : 'Pending'}
                            </Typography>
                        </Grid>

                    </Grid>
                )
            }
        },
        {
            headerName: "Actions",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (data) => {
                return (
                    <Box sx={{
                        display: "flex",
                        justifyContent: 'center',
                        gap: 1
                    }}>
                        <IconButton
                            size="small"
                            disabled={data.row.role === "owner"}
                            onClick={() => {
                                setOpenModal(true)
                                setDefaultEditData({ ...data.row })
                            }}>
                             <Typography variant='h6'><i class="ri-pencil-line"></i></Typography>
                        </IconButton>
                        <IconButton
                            size="small"
                            disabled={data.row.role === "owner"}
                            onClick={() => {
                                handleInvitedMemberDelete(data.row._id, tenantId)
                            }}
                        >
                            {!deleteLoading?.[data?.row?._id] &&  <Typography variant='h6'><i class="ri-delete-bin-7-line"></i></Typography>}
                            {deleteLoading?.[data?.row?._id] && <CircularProgress size={16} />}
                        </IconButton>

                    </Box>

                )
            },
        },
    ]

    const fetchInvitedMemberList = () => {
        dispatch(getInvitedMemberList({ tenantId, page: paginationModel.page + 1, limit: paginationModel.pageSize }))
    }

    const handleInvitedMemberDelete = (id, tenantId) => {
        setDeleteLoading((prev) => ({
            ...prev,
            [id]: true,
        }));
        dispatch(deleteInvitedMember({ id, tenantId }))
            .unwrap()
            .finally(() => {
                enqueueSnackbar("Invite deleted successfully", { variant: "success" })
                fetchInvitedMemberList();
                setDeleteLoading((prev) => ({
                    ...prev,
                    [id]: false,
                }));
            });
    }

    useEffect(() => {
        fetchInvitedMemberList();
    }, [paginationModel])

    return (<>
        <CenteredPaperModal open={openModal} onClose={() => {
            setOpenModal(false);
        }}>
            <Typography variant='h6' gutterBottom>Edit Invite</Typography>
            <EditMemberInvite defaultData={defaultEditData} onComplete={() => {
                setOpenModal(false);
                fetchInvitedMemberList();
            }} />
        </CenteredPaperModal>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'space-between'} mb={1}>
                    <Typography variant='h6'>Invitations</Typography>
                    <StyledButton onClick={fetchInvitedMemberList} startIcon={<RefreshIcon />}>
                        Refresh
                    </StyledButton>
                </Stack>
            </Grid>
        </Grid>
          <Card sx={{boxShadow:0}}>
            <CustomTable
                columns={columns}
                pageSizeOptions={[5, 10]}
                dataList={memberInvitationData?.invitations}
                count={memberInvitationData?.totalCount}
                paginationData={paginationModel}
                setPaginationData={setPaginationModel}
                disableRowSelectionOnClick
                disableColumnMenu
                autoHeight
            />
          </Card>
    </>
    )
}

export default ShowInvitedUsers