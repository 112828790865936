import { io } from "socket.io-client"
import { enqueueSnackbar } from "notistack"

import {
  SOCKET_EVENTS,
  APP_PAGES,
  CHAT_STATE,
  LOCAL_STORAGE,
  MESSAGE_SENDER,
} from "../../configs/constants"
import { LIVE_CHAT_SOCKET_URL } from "../../configs/urls"
import {
  playNotification,
  log,
  setDataInLocalStorage,
} from "../../utils"
import { updatePageState, updateBadgeOnSiderModule } from "../pageSlice"
import {
  makeLiveChatSocketConnection,
  updateLiveChatState,
  disconnectLiveChatSocket,
  emitLiveChatSocketEvent,
  joinLiveChatPage,
  chatListReceivedOnJoin,
  liveChatItemClicked,
  addNewLiveChatItem,
  updateLiveChatItem,
  deleteLiveChatItem,
  switchToAlreadyJoinedChat,
  joinLiveChatRoom,
  getLiveUserChatlogDetails,
  updateSelectedChats,
  sendMessage,
  resolveLiveChat,
  updateUnreadMessageCountMap,
} from "."
import * as _ from 'lodash'

const middleware = (store) => {
  let socket = null
  return (next) => (action) => {
    if (makeLiveChatSocketConnection.match(action)) {
      if (socket !== null) socket.close()
      const socketUrl = `${LIVE_CHAT_SOCKET_URL}/${action.payload.tenantId}`
      socket = io(socketUrl, {
        transports: ["websocket"],
        auth: {
          token: store.getState().authDetails.token,
        },
        query: {
          tenantId: action.payload.tenantId,
          userId: store.getState().authDetails.userId,
        },
        extraHeaders:{
          "x-served-for": window?.location?.host,
        }
      })

      socket.on(SOCKET_EVENTS.CONNECT, () => {
        store.dispatch(
          updateLiveChatState({ isSocketConnected: socket.connected })
        )
        // NOTE: Handle case - Users goes offline during a session
        const { selectedChatId, selectedChats, liveChats } = store.getState().liveChatDetails
        const { userId } = store.getState().authDetails
        if (selectedChatId) {
          store
            .dispatch(
              joinLiveChatRoom({
                clientPsid: selectedChatId,
                adminPsid: userId,
                tenantId: _.get(selectedChats, `${selectedChatId}.profile.tenantId`, null),
                socketId: socket.id,
              })
            ).unwrap()
            .then(() => {
              // Fetching chats that are lost while user was offline
              const sessionId = _.get(liveChats, `${selectedChatId}.sessionId`, "")
              if (sessionId)
                store.dispatch(
                  getLiveUserChatlogDetails({
                    id: selectedChatId,
                    sessionId,
                    data: {
                      tenantId: _.get(selectedChats, `${selectedChatId}.profile.tenantId`, null),
                      fetchProfile: true, // passing this as true because reducers don't update chat if user is not fetched
                    },
                  })
                )
              else enqueueSnackbar("SessionId is missing", { variant: "warning" })
            })
        }
      })

      socket.on(SOCKET_EVENTS.CONNECT_ERROR, (err) => {
        if (err.message === "invalid credentials") {
          // socket.auth.token = "efgh"
          // socket.connect()
          log("Unauthorize user err", err)
        }

        store.dispatch(
          updateLiveChatState({ isSocketConnected: socket.connected })
        )
      })

      socket.on(SOCKET_EVENTS.ERROR, () => {
        store.dispatch(
          updateLiveChatState({ isSocketConnected: socket.connected })
        )
      })

      socket.on(SOCKET_EVENTS.DISCONNECT, (reason) => {
        if (reason === "io server disconnect") socket.connect()
        store.dispatch(
          updateLiveChatState({ isSocketConnected: socket.connected })
        )
      })

      socket.on(SOCKET_EVENTS.ADDITION, (payload) => {
        if (payload?.newLiveUser?.psid) {
          store.dispatch(addNewLiveChatItem(payload.newLiveUser))
        }
      })

      socket.on(SOCKET_EVENTS.UPDATION, (payload) => {
        if (payload.type === "customerHash") {
          const liveChats = store.getState().liveChatDetails.liveChats
          if (
            payload?.data?.clientPsid &&
            liveChats[payload?.data?.clientPsid] &&
            payload?.data?.value
          )
            store.dispatch(updateLiveChatItem(payload.data))
        } else if (payload.type === "customerProfile") {
          if (payload?.data?.clientPsid && payload?.data?.value)
            store.dispatch(
              updateSelectedChats({
                id: payload.data.clientPsid,
                key: "profile",
                value: payload.data.value,
              })
            )
        } else if (payload.type === "customerStateChange") {
          const {
            liveChats,
            botChatPsids,
            inQueuePsids,
            myChatPsids,
            otherAgentChatPsids,
          } = store.getState().liveChatDetails
          if (
            payload?.data?.clientPsid &&
            liveChats[payload?.data?.clientPsid] &&
            payload?.data?.value?.chatState
          ) {
            if (payload.data.value.chatState === CHAT_STATE.AGENT_HANDLING) {
              const userId = store.getState().authDetails.userId
              store.dispatch(
                updateLiveChatState({
                  botChatPsids: botChatPsids.filter(
                    (id) => id !== payload.data.clientPsid
                  ),
                  inQueuePsids: inQueuePsids.filter(
                    (id) => id !== payload.data.clientPsid
                  ),
                  ...(payload.data.value.agentId === userId
                    ? {
                        myChatPsids: [payload.data.clientPsid, ...myChatPsids],
                      }
                    : {
                        otherAgentChatPsids: [
                          payload.data.clientPsid,
                          ...otherAgentChatPsids,
                        ],
                      }),
                })
              )
            }
          }
        } else if (payload.type === "totalChatListCount") {
          const liveChatsCount =
            store.getState().pageDetails.badgeOnSiderModule[
              APP_PAGES.TENANT_LIVE_CHATS
            ]
          if (
            payload?.data?.totalCount &&
            payload?.data?.totalCount !== liveChatsCount
          )
            playNotification()

          store.dispatch(
            updateBadgeOnSiderModule({
              appPage: APP_PAGES.TENANT_LIVE_CHATS,
              count: payload?.data?.totalCount,
            })
          )
        }
      })

      socket.on(SOCKET_EVENTS.DELETION, (payload) => {
        if (payload?.disconnectedChatPsid) {
          const userId = store.getState().authDetails.userId
          store.dispatch(
            deleteLiveChatItem({
              id: payload.disconnectedChatPsid,
              userId,
              transition: true,
            })
          )
        }
      })

      socket.on(SOCKET_EVENTS.RESPONSE, (payload) => {
        const userId = store.getState().authDetails.userId
        const { selectedChats, selectedChatId, liveChats } =
          store.getState().liveChatDetails
        if (
          payload?.clientPsid &&
          liveChats[payload.clientPsid] &&
          payload.clientPsid !== selectedChatId
        )
          store.dispatch(
            updateUnreadMessageCountMap({
              id: payload.clientPsid,
            })
          )

        if (
          payload &&
          payload.clientPsid &&
          selectedChats[payload.clientPsid] &&
          payload.message &&
          payload.message.senderInfo?.userId !== userId
        ) {
          store.dispatch(
            updateSelectedChats({
              id: payload.clientPsid,
              key: "messages",
              isArray: true,
              value: payload.message,
            })
          )
        }
      })

      socket.on(SOCKET_EVENTS.TYPING_STATUS, (payload) => {
        const userId = store.getState().authDetails.userId
        if (
          payload?.typingInfo?.sender &&
          payload?.typingInfo?.clientPsid &&
          payload?.typingInfo?.senderPsid !== userId
        ) {
          store.dispatch(
            updateSelectedChats({
              id: payload.typingInfo.clientPsid,
              key: "typingInfo",
              value: {
                [payload.typingInfo.sender]: payload.typingInfo,
              },
            })
          )
        }
      })

      socket.on(SOCKET_EVENTS.UNSENT_MESSAGE, (payload) => {
        if (payload?.clientPsid && payload.sender === MESSAGE_SENDER.USER) {
          store.dispatch(
            updateSelectedChats({
              id: payload.clientPsid,
              key: "userUnsentMessage",
              value: payload.text || "",
              replaceDoc: true,
            })
          )
        }
      })
    } else if (
      emitLiveChatSocketEvent.match(action) &&
      socket &&
      action.payload.event
    ) {
      socket.emit(action.payload.event, action.payload.data, (err, res) => {
        if (err && res && res.errorCode && res.errorCode === 204) {
          store.dispatch(joinLiveChatPage())
          enqueueSnackbar(res.message || `${action.event} error occured`, {
            variant: "error",
          })
        }
      })
    } else if (socket && joinLiveChatPage.match(action)) {
      store.dispatch(updateLiveChatState({ pageJoining: true }))
      socket.emit(SOCKET_EVENTS.JOIN, {}, (err, res) => {
        store.dispatch(updateLiveChatState({ pageJoining: false }))
        if (!err && res?.data?.liveChatData) {
          store.dispatch(
            chatListReceivedOnJoin({
              liveChats: res.data.liveChatData,
              userId: store.getState().authDetails.userId,
            })
          )
        } else
          enqueueSnackbar(res.message || "join event error occured", {
            variant: "error",
          })
      })
    } else if (socket && liveChatItemClicked.match(action)) {
      const { unreadMessageCountMap, selectedChats } =
        store.getState().liveChatDetails
      if (
        !action.payload.isResolvedChat &&
        action.payload.id &&
        unreadMessageCountMap[action.payload.id]
      ) {
        const unreadMsgCount = { ...unreadMessageCountMap }
        delete unreadMsgCount[action.payload.id]
        store.dispatch(
          updateLiveChatState({
            unreadMessageCountMap: unreadMsgCount,
          })
        )
        setDataInLocalStorage(
          LOCAL_STORAGE.LCS_UNREAD_MESSAGE_COUNT_MAP,
          unreadMsgCount,
          true
        )
      }

      const alreadyJoinedChatIds = Object.keys(selectedChats)
      const index = alreadyJoinedChatIds.findIndex(
        (id) => id === action.payload.id
      )
      if (index !== -1 && !action.payload.forceJoin) {
        // NOTE: uncomment to prevent joinChat api called every time selected chat changes
        // store.dispatch(switchToAlreadyJoinedChat({ id: action.payload.id }))
        if (socket && action.payload.isMyChat) {
          socket.emit(SOCKET_EVENTS.MESSAGE_SEEN, {
            clientPsid: action.payload.psid,
            senderPsid: action.payload.userId,
          })
        }
      } 
      // NOTE: change 'if' to 'else if' to prevent joinChat api called every time selected chat changes
      if (action.payload.isResolvedChat) {
        if (action.payload.sessionId)
          store.dispatch(
            getLiveUserChatlogDetails({
              id: action.payload.sessionId,
              sessionId: action.payload.sessionId,
              chatItem: action.payload.chatItem,
              data: {
                tenantId: action.payload.tenantId,
                fetchProfile: true,
              },
            })
          )
        else enqueueSnackbar("SessionId is missing", { variant: "warning" })
      } else {
        store
          .dispatch(
            joinLiveChatRoom({
              clientPsid: action.payload.psid,
              adminPsid: action.payload.userId,
              tenantId: action.payload.tenantId,
              socketId: socket.id,
            })
          )
          .unwrap()
          .then(() => {
            if (action.payload.sessionId)
              store.dispatch(
                getLiveUserChatlogDetails({
                  id: action.payload.psid,
                  sessionId: action.payload.sessionId,
                  data: {
                    tenantId: action.payload.tenantId,
                    fetchProfile: true,
                  },
                })
              )
            else enqueueSnackbar("SessionId is missing", { variant: "warning" })
          })
      }
    } else if (socket && resolveLiveChat.match(action)) {
      store.dispatch(updatePageState({ modalLoading: true }))
      socket.emit(SOCKET_EVENTS.RESOLVE_CHAT, action.payload, (err, res) => {
        store.dispatch(updatePageState({ modalLoading: false }))
        if (!err && res?.data?.resolvedChatPsid)
          store.dispatch(
            deleteLiveChatItem({
              id: res.data.resolvedChatPsid,
              userId: action.payload.userId,
              reason: "Chat resolved successfully",
            })
          )
        else enqueueSnackbar(res?.message || "Resolve chat event error occured")
      })
    } else if (
      socket &&
      sendMessage.match(action) &&
      action.payload?.clientPsid &&
      action.payload?.messageId
    ) {
      socket.emit(
        SOCKET_EVENTS.ADMIN_TO_USER_MSG,
        action.payload,
        (err, res) => {
          if (!err && res?.data?.clientPsid && res?.data?.message) {
            store.dispatch(
              updateSelectedChats({
                id: res.data.clientPsid,
                key: "messages",
                isArray: true,
                update: true,
                replace: true,
                uniqueKey: "id",
                uniqueKeyValue: action.payload.messageId,
                value: res.data.message,
              })
            )
          } else {
            store.dispatch(
              updateSelectedChats({
                id: action.payload.clientPsid,
                key: "messages",
                isArray: true,
                update: true,
                uniqueKey: "id",
                uniqueKeyValue: action.payload.messageId,
                value: { status: "failed" },
              })
            )
          }
        }
      )
    } else if (socket && disconnectLiveChatSocket.match(action)) {
      socket.disconnect()
      socket = null
    }

    next(action)
  }
}

export default middleware
