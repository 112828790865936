const initialState = {
  selectedIntegration: "",
  integrationStatus: {},
  zapierIntegrationList: [],
  webhookInfo: {},
  hubspotInfo: {
    "connection" : null,
    "isEnabled" : null
  },
  salesforceInfo: {
    "connection" : null,
    "isEnabled" : null
  },
  googleSheetsInfo: {
    "connection" : null,
    "isEnabled" : null
  }
}

export default initialState
