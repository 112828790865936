import * as _ from 'lodash'
import { trackUserJourneyEvent } from '.'

const middleware = (store) => (next) => (action) => {
    const isWhiteLabeledDashboard = process.env.REACT_APP_WHITE_LABELED_APP === 'true';
    if (action.type?.split("/")[0] !== "userJourney" && !isWhiteLabeledDashboard) {
        store.dispatch(trackUserJourneyEvent(action))
    }
    next(action)
}

export default middleware