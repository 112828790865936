import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { fetchDataForToolkit } from "../helpers"

import {
    GET_TENANT_TEMPLATES_LIST,
    UPDATE_TENANT_TEMPLATE,
    CREATE_TENANT_TEMPLATE,
    DELETE_TEMPLATE_MESSAGE,
    UPDATE_TEMPLATE_MESSAGE,
    CREATE_TEMPLATE_MESSAGE, DENY_LIST_ENDPOINT,
} from "./api"
import initialState from "./initialState"
import { METHOD_TYPES } from "../../configs/constants"

const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    updateTemplateState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTenantTemplateList.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loadError = false
        state.templates = payload.data?.response
      })
      .addCase(getTenantTemplateList.pending, (state, { payload }) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getTenantTemplateList.rejected, (state, { payload }) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(createTenantTemplate.fulfilled, (state, { payload }) => {
        state.templates = [payload?.data?.response, ...state.templates]
      })
      .addCase(updateTenantTemplate.fulfilled, (state, { payload }) => {
        if (payload?.data?.response?._id) {
          const index = state.templates.findIndex(
            (item) => item._id === payload?.data?.response?._id
          )
          if (index > -1) {
            state.templates = [
              ...state.templates.slice(0, index),
              payload?.data?.response,
              ...state.templates.slice(index + 1),
            ]
          }
        }
      })
        .addCase(getDenyList.fulfilled, (state, { payload }) => {
            state.denyList = {
                ...state.denyList,
                [payload.data.pseudoName]: payload.data
            }
        })
        .addCase(updateDenyList.fulfilled, (state, { payload }) => {
            state.denyList = {
                ...state.denyList,
                [payload.data.pseudoName]: payload.data
            }
        })
  },
})

export const getTenantTemplateList = createAsyncThunk(
  "dashboard/getTenantTemplateList",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_TENANT_TEMPLATES_LIST,
        data,
        loader: false,
      },
      helpers
    )
)

export const createTenantTemplate = createAsyncThunk(
  "dashboard/createTenantTemplate",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: CREATE_TENANT_TEMPLATE,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const updateTenantTemplate = createAsyncThunk(
  "dashboard/updateTenantTemplate",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: UPDATE_TENANT_TEMPLATE,
        method: METHOD_TYPES.PUT,
        data,
      },
      helpers
    )
)

export const getDenyList = createAsyncThunk(
    'training/getDenyList',
    async (data = {}, helpers) =>
        fetchDataForToolkit(
            {
                url: DENY_LIST_ENDPOINT,
                data: data
            },
            helpers
        )
)

export const updateDenyList = createAsyncThunk(
    'training/updateDenyList',
    async (data = {}, helpers) =>
        fetchDataForToolkit(
            {
                url: DENY_LIST_ENDPOINT,
                data: data,
                method: METHOD_TYPES.PUT
            },
            helpers
        )
)

export const { updateTemplateState } = templateSlice.actions
export default templateSlice.reducer
