import { COLORS } from "../../configs/constants"

const initialState = {
  tenantInfo: {},
  urlList: [],
  tenantLoading: false,
  uploadedTenantInfoDocumentList: [],
  stats: {},
  statsLoading: false,
  botAppearance: {
    primaryColor: COLORS.PRIMARY_MAIN,
    secondaryColor: COLORS.SECONADRY_MAIN,
    fontSize: "14px", //12px, 14px and 16px
    bubbleShape: "cornered", //square, rounded and cornered
    headerHeight: "64px", //56px, 64px and 72px
    triggerUrl: "",
    avatarUrl: "",
    welcomeMessages: [],
    fallbackMessages: [],
    leadForm: {},
  },
  chatSession: {},
  wordCloudData: {},
  memberList: [],

  // Route based Notifications List
  notificationListLoading: false,
  notificationListLoadError: false,
  routeBasedNotificationsList: [],
  tenantQuota: {},
  liveChatConfigurations: {},
  quotaList: [],

  memberInvitations:{},

  // Ecommerce Onboarding
  eCommerceOnboarding : {
    currentStep: 0,
    selectedPlatform: null,
    tenantId: null,
    storeInfo: null,
    isOpenAIKeyRequired: true,
  },
  eCommerceProducts: [],
  
  // Feedback Form
  dynamicFeedbackFormTemplate: null,
}

export default initialState
