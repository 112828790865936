import Box from "@mui/material/Box"
import Backdrop from "@mui/material/Backdrop"
import ShowUpgradeOptions from "./ShowUpgradeOptions"
import ContactOwnerPaywall from "./ContactOwnerPaywall"

const PaywallWrapper = ({ children, payWallLogicData, sxProps, size = "default", open = true }) => {
  const isContactOwner = payWallLogicData?.isContactOwner || false;
  const isQuotaPayWall = payWallLogicData?.isQuotaPayWall || false;
  const backdropSxProps = payWallLogicData?.backdropSxProps || {};
  return (
    <Box position="relative" overflow='hidden' minHeight={size === "default" ? 120 : 0} sx={{ ...sxProps }}>
      {children}
      <Backdrop
        open={open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          zIndex: 99,
          gap: size === "default" ? 0 : 1,
          position: "absolute",
          bgcolor: "rgba(0,0,0,0.4)",
          borderRadius: 1,
          backdropFilter: "blur(4px)",
          ...backdropSxProps,
        }}
      >
        <PayWall size={size} isContactOwner={isContactOwner} isQuotaPayWall={isQuotaPayWall} payWallLogicData={payWallLogicData} />
      </Backdrop>
    </Box>
  )
}

const PayWall = ({ size, isContactOwner, isQuotaPayWall, payWallLogicData }) => {
  if (isContactOwner) {
    return <ContactOwnerPaywall />
  } else if (isQuotaPayWall) {
    return <ShowUpgradeOptions
      plans={payWallLogicData?.info?.plans}
      addOns={payWallLogicData?.info?.addOns}
      size={size}
      title={payWallLogicData?.info?.title}
      message={payWallLogicData?.info?.description || "You have exceeded your quota. Please subscribe to a plan or purchase addons from the list given below to increase your quota"}
      btnData={payWallLogicData?.info?.button}/>
  } else {
    return <ShowUpgradeOptions
    plans={payWallLogicData?.info?.plans} 
    addOns={payWallLogicData?.info?.addOns} 
    title={payWallLogicData?.info?.title}
    message={payWallLogicData?.info?.description}
    size={size} 
    btnData={payWallLogicData?.info?.button}/>
  }
}

export default PaywallWrapper
