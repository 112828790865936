import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, tableCellClasses } from '@mui/material'
import React from 'react'

const CustomTable = ({ columns, dataList, count, paginationData, setPaginationData, hideHeader = false }) => {
    return (
        <TableContainer component={Box}>
            <Table size='small' border={0} sx={{
                [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none"
                },
                minWidth: 650
            }}>
            {!hideHeader && (
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.field}
                                align={column.align}
                                width={column.width}
                                style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                            >
                                {column.renderHeader && column.renderHeader()}
                                {!column.renderHeader && column.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            )}
                <TableBody >
                    {dataList?.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={columns.length} align='center' sx={{
                                p: 2,
                            }}>
                                No data
                            </TableCell>
                        </TableRow>
                    )}
                    {dataList?.map((row, index) => (
                        <TableRow key={row.name}>
                            {columns?.map((column) => {
                                return (
                                    <TableCell key={column.field} align={column.align}>
                                        {column.renderCell && column.renderCell({ row, index })}
                                        {!column.renderCell && row[column.field]}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
                {dataList?.length > 0 && paginationData && (
                    <TablePagination
                        page={paginationData?.page}
                        rowsPerPage={paginationData?.pageSize}
                        count={count}
                        rowsPerPageOptions={[5, 10]}
                        onPageChange={(event, value) => {
                            setPaginationData((prev) => ({
                                ...prev,
                                page: value
                            }))
                        }}
                        onRowsPerPageChange={(event) => {
                            setPaginationData((prev) => ({
                                pageSize: event.target.value,
                                page: 0
                            }))
                        }} />
                )}

            </Table>
        </TableContainer>
    )
}

export default CustomTable