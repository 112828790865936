import { THEME_MODE } from "../configs/constants"

import light from "./light"
import dark from "./dark"

const theme = {
  [THEME_MODE.LIGHT]: light,
  [THEME_MODE.DARK]: dark,
}

export default theme
