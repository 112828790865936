import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"

import {fetchDataForToolkit} from "../helpers"
import initialState from "./initialState"
import {ACL_IDENTIFIERS, METHOD_TYPES} from "../../configs/constants"
import {FETCH_PLAN, GET_BILLING_INFO, GET_PLAN_GUIDE_STEPS, GET_PRICING} from "./api"
import { aclAllowApi } from "../../utils/acl"
import { isEmptyObject } from "../../utils"

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    updatePlanGuideStep : (state,action) => {
      state.planGuideStep = action.payload
    },
    updatePlanGuideAnswers : (state,action) => {
      state.planGuideAnswers = action.payload
    },
    updatePlanGuideCustomField : (state,action) => {
      state.planGuideCustomField = action.payload
    },
    updateShowFinalPlanFlag : (state,action) => {
      state.showFinalPlanFlag = action.payload
    },
    updateFinalPlanDetails : (state,action) => {
      state.planDetail = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPricingTableData.pending, (state) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getPricingTableData.fulfilled, (state, {payload}) => {
        state.loading = false
        state.loadError = false
        if (payload.data) state.pricingTableData = payload.data
      })
      .addCase(getPricingTableData.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(getBillingData.pending, (state) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getBillingData.fulfilled, (state, {payload}) => {
        state.loading = false
        state.loadError = false
        if (payload?.data) state.billingData = payload?.data
      })
      .addCase(getBillingData.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(getPlanGuideSteps.fulfilled, (state, {payload}) => {
        state.planGuideSteps = payload.data.steps
        if(payload?.data?.planSuggestions && !isEmptyObject(payload?.data?.planSuggestions) && state.showFinalPlanFlag) {
          state.planDetail = {
            plans : payload?.data?.planSuggestions
          }
          state.planGuideStep = payload?.data?.steps.length+1
        }
        else {
          state.showFinalPlanFlag = false;
        }
      })
      .addCase(fetchFinalPlan.fulfilled ,(state,{payload})=>{
        state.planDetail = payload.data
      })
  }
})

export const getPricingTableData = createAsyncThunk(
  "billing/getPricingTableData",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_PRICING,
        data,
      },
      helpers
    )
)

export const getBillingData = createAsyncThunk(
  "billing/getBillingData",
  async (data = {}, helpers) => {
    const {getState} = helpers;
    const acl = getState()?.authDetails?.acl;
    return aclAllowApi(
      () => fetchDataForToolkit(
        {
          url: GET_BILLING_INFO,
          data,
        },
        helpers
      ), acl?.[ACL_IDENTIFIERS.BILLING], METHOD_TYPES.GET)
  } 
)


export const getPlanGuideSteps = createAsyncThunk(
  "billing/getPlanGuideSteps",
  async (data = {}, helpers)  => 
    fetchDataForToolkit({
      url: GET_PLAN_GUIDE_STEPS,
      data,
    },
    helpers
  ),
)

export const fetchFinalPlan = createAsyncThunk(
  "billing/fetchFinalPlan",
  async (data={}, helpers) =>
    fetchDataForToolkit({
      url : FETCH_PLAN,
      data,
      method: METHOD_TYPES.POST
    },
    helpers
  )
)

export const {updatePlanGuideStep, updatePlanGuideAnswers, updatePlanGuideCustomField, updateShowFinalPlanFlag, updateFinalPlanDetails} = billingSlice.actions
export default billingSlice.reducer
