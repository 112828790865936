import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { enqueueSnackbar } from "notistack"

import initialState from "./initialState"
import { fetchDataForToolkit } from "../helpers"
import { GET_USER_CHATLOG_DETAILS } from "../chatHistorySlice/api"
import { updateMessageTrainingStatus } from "../pageSlice"
import { GET_ALL_CHATS, JOIN_LIVE_CHAT_ROOM } from "./api"
import {
  CHAT_STATE,
  LIVE_CHAT_TABS,
  LOCAL_STORAGE,
  METHOD_TYPES,
  MODULE_CONFIG,
} from "../../configs/constants"
import {
  createPsidMapForLiveChat,
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "../../utils"

const liveChatSlice = createSlice({
  name: "liveChat",
  initialState,
  reducers: {
    updateLiveChatState(state, action) {
      return { ...state, ...action.payload }
    },
    makeLiveChatSocketConnection(state) {
      state.socketRequestProcessing = true
    },
    disconnectLiveChatSocket() {
      return
    },
    emitLiveChatSocketEvent() {
      return
    },
    joinLiveChatPage(state) {
      return
    },
    chatListReceivedOnJoin(state, action) {
      if (action.payload?.liveChats) {
        const liveChatPsids = Object.keys(action.payload.liveChats)
        const { botPsids, inQueuePsids, myChatPsids, otherAgentChatPsids } =
          createPsidMapForLiveChat(
            action.payload.liveChats,
            action.payload.userId
          )
        state.botChatPsids = botPsids
        state.inQueuePsids = inQueuePsids
        state.myChatPsids = myChatPsids
        state.otherAgentChatPsids = otherAgentChatPsids
        state.liveChats = action.payload.liveChats

        const unreadMessageCountMap = getDataFromLocalStorage(
          LOCAL_STORAGE.LCS_UNREAD_MESSAGE_COUNT_MAP,
          {},
          true
        )
        Object.keys(unreadMessageCountMap).forEach((id) => {
          if (liveChatPsids.indexOf(id) === -1) delete unreadMessageCountMap[id]
        })
        state.unreadMessageCountMap = unreadMessageCountMap
        setDataInLocalStorage(
          LOCAL_STORAGE.LCS_UNREAD_MESSAGE_COUNT_MAP,
          state.unreadMessageCountMap,
          true
        )
      }
    },
    liveChatItemClicked() {
      return
    },
    resolveLiveChat() {
      return
    },
    addNewLiveChatItem(state, action) {
      if (action.payload.psid) {
        state.liveChats[action.payload.psid] = action.payload
        if (action.payload.chatState === CHAT_STATE.UNASSIGNED)
          state.inQueuePsids.push(action.payload.psid)
        if (state.selectedChatListTab !== LIVE_CHAT_TABS.ACTIVE_CHATS)
          state.unseenLiveChatCount = state.unseenLiveChatCount + 1
      }
    },
    updateLiveChatItem(state, action) {
      if (
        action.payload.clientPsid &&
        state.liveChats[action.payload.clientPsid]
      ) {
        state.liveChats[action.payload.clientPsid] = {
          ...state.liveChats[action.payload.clientPsid],
          ...action.payload.value,
        }
      }
    },
    deleteLiveChatItem(state, action) {
      if (
        action.payload.id &&
        state.unreadMessageCountMap[action.payload.id] !== undefined
      ) {
        delete state.unreadMessageCountMap[action.payload.id]
        setDataInLocalStorage(
          LOCAL_STORAGE.LCS_UNREAD_MESSAGE_COUNT_MAP,
          state.unreadMessageCountMap,
          true
        )
      }

      if (action.payload.id && state.liveChats[action.payload.id]) {
        if (
          state.liveChats[action.payload.id]?.chatState ===
          CHAT_STATE.UNASSIGNED
        )
          state.inQueuePsids = state.inQueuePsids.filter(
            (id) => id !== action.payload.id
          )
        else if (
          state.liveChats[action.payload.id]?.chatState ===
          CHAT_STATE.BOT_HANDLING
        )
          state.botChatPsids = state.botChatPsids.filter(
            (id) => id !== action.payload.id
          )
        else if (
          state.liveChats[action.payload.id]?.chatState ===
            CHAT_STATE.AGENT_HANDLING &&
          action.payload.userId &&
          state.liveChats[action.payload.id]?.agentId === action.payload.userId
        )
          state.myChatPsids = state.myChatPsids.filter(
            (id) => id !== action.payload.id
          )
        else if (
          state.liveChats[action.payload.id]?.chatState ===
            CHAT_STATE.AGENT_HANDLING &&
          action.payload.userId &&
          state.liveChats[action.payload.id]?.agentId !== action.payload.userId
        )
          state.otherAgentChatPsids = state.otherAgentChatPsids.filter(
            (id) => id !== action.payload.id
          )

        if (state.selectedChats[action.payload.id]) {
          if (
            action.payload.transition &&
            state.liveChats[action.payload.id]?.sessionId
          ) {
            const chatItem = state.liveChats[action.payload.id]
            chatItem.chatState = CHAT_STATE.RESOLVED
            state.selectedChats[chatItem?.sessionId] = {
              ...state.selectedChats[action.payload.id],
              chatItem,
            }
          }

          delete state.selectedChats[action.payload.id]
          if (action.payload.reason)
            enqueueSnackbar(action.payload.reason, { variant: "info" })
        }

        if (state.selectedChatId === action.payload.id) {
          if (
            action.payload.transition &&
            state.liveChats[action.payload.id]?.sessionId
          ) {
            state.selectedChatId = state.liveChats[action.payload.id]?.sessionId
          } else state.selectedChatId = null
        }

        delete state.liveChats[action.payload.id]

        if (
          state.selectedChatListTab === LIVE_CHAT_TABS.ALL_CHATS &&
          state.unseenLiveChatCount > 0
        )
          state.unseenLiveChatCount = state.unseenLiveChatCount - 1

        if (
          action.payload.transition &&
          Object.keys(state.liveChats)?.length === 0
        )
          state.selectedChatListTab = LIVE_CHAT_TABS.ALL_CHATS
      }
    },
    switchToAlreadyJoinedChat(state, action) {
      state.selectedChatId = action.payload.id
    },
    closeLiveChatWindow(state) {
      if (state.selectedChatId) {
        delete state.selectedChats[state.selectedChatId]
        state.selectedChatId = null
      }
    },
    updateSelectedChats(state, action) {
      if (
        action.payload.id &&
        action.payload.key &&
        state.selectedChats[action.payload.id]
      ) {
        if (action.payload.replaceDoc)
          state.selectedChats[action.payload.id][action.payload.key] =
            action.payload.value
        else if (action.payload.isArray) {
          if (
            action.payload.update &&
            action.payload.uniqueKey &&
            action.payload.uniqueKeyValue
          ) {
            const index = state.selectedChats[action.payload.id][
              action.payload.key
            ].findIndex(
              (item) =>
                item[action.payload.uniqueKey] === action.payload.uniqueKeyValue
            )
            if (index !== -1) {
              if (action.payload.replace)
                state.selectedChats[action.payload.id][action.payload.key][
                  index
                ] = action.payload.value
              else
                state.selectedChats[action.payload.id][action.payload.key][
                  index
                ] = {
                  ...state.selectedChats[action.payload.id][action.payload.key][
                    index
                  ],
                  ...action.payload.value,
                }
            }
          } else
            state.selectedChats[action.payload.id][action.payload.key] = [
              ...state.selectedChats[action.payload.id][action.payload.key],
              action.payload.value,
            ]
        } else
          state.selectedChats[action.payload.id][action.payload.key] = {
            ...state.selectedChats[action.payload.id][action.payload.key],
            ...action.payload.value,
          }
      }
    },
    sendMessage() {
      return
    },
    updateUnreadMessageCountMap(state, action) {
      if (action.payload.id) {
        if (state.unreadMessageCountMap[action.payload.id])
          state.unreadMessageCountMap[action.payload.id]++
        else state.unreadMessageCountMap[action.payload.id] = 1
        setDataInLocalStorage(
          LOCAL_STORAGE.LCS_UNREAD_MESSAGE_COUNT_MAP,
          state.unreadMessageCountMap,
          true
        )
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChats.pending, (state, { meta }) => {
        if (!meta.arg.lastScannedTicketId) state.allChatsLoading = true
      })
      .addCase(getAllChats.fulfilled, (state, { meta, payload }) => {
        state.allChatsLoading = false
        if (payload.data.resolvedChatData) {
          state.allChats = meta.arg.lastScannedTicketId
            ? [...state.allChats, ...payload.data.resolvedChatData]
            : payload.data.resolvedChatData
          state.lastScannedTicketId = payload.data.lastScannedTicketId || ""
          state.hasMore =
            payload.data.resolvedChatData.length >=
            MODULE_CONFIG.LIVE_CHATS_ALL_CHATS_LIMIT
        }
      })
      .addCase(getAllChats.rejected, (state, { meta }) => {
        if (!meta.arg.lastScannedTicketId) state.allChatsLoading = false
      })
      .addCase(
        getLiveUserChatlogDetails.fulfilled,
        (state, { meta, payload }) => {
          if (
            meta?.arg?.id &&
            payload?.data?.chatLogs
          ) {
            if(payload?.data?.userProfile) {
              state.selectedChatId = meta.arg.id
              state.selectedChats[meta.arg.id] = {
                messages: payload.data.chatLogs,
                profile: payload.data.userProfile,
                chatItem: meta.arg.chatItem || null,
              }
            } else if (state.selectedChatId) {
              state.selectedChats[state.selectedChatId] = {
                ...state.selectedChats[state.selectedChatId],
                messages: payload.data.chatLogs,
              }
            }
          }
        }
      )
      .addCase(
        updateMessageTrainingStatus.fulfilled,
        (state, { meta, payload }) => {
          if (
            payload?.data?.messageId &&
            meta?.arg?.selectedKey &&
            state.selectedChats[meta.arg.selectedKey] &&
            state.selectedChats[meta.arg.selectedKey].messages
          ) {
            const index = state.selectedChats[
              meta.arg.selectedKey
            ].messages.findIndex((msg) => msg.id === payload.data.messageId)
            if (index >= 0)
              state.selectedChats[meta.arg.selectedKey].messages[
                index
              ].isTrainingRequired = payload.data.isTrainingRequired
          }
        }
      )
  },
})

export const getAllChats = createAsyncThunk(
  "liveChat/getAllChats",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_ALL_CHATS,
        baseURL: process.env.REACT_APP_BASE_SOCKET_URL,
        method: METHOD_TYPES.POST,
        data,
        loader: false,
      },
      helpers
    )
)

export const joinLiveChatRoom = createAsyncThunk(
  "liveChat/joinLiveChatRoom",
  async (data = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: JOIN_LIVE_CHAT_ROOM,
        baseURL: process.env.REACT_APP_BASE_SOCKET_URL,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
)

export const getLiveUserChatlogDetails = createAsyncThunk(
  "liveChat/getLiveCustomerDetails",
  async (payload = {}, helpers) =>
    fetchDataForToolkit(
      {
        url: GET_USER_CHATLOG_DETAILS.replace(":sessionId", payload.sessionId),
        data: payload.data,
        method: METHOD_TYPES.POST,
        loader: false,
      },
      helpers
    )
)

export const {
  updateLiveChatState,
  makeLiveChatSocketConnection,
  disconnectLiveChatSocket,
  emitLiveChatSocketEvent,
  joinLiveChatPage,
  chatListReceivedOnJoin,
  liveChatItemClicked,
  resolveLiveChat,
  addNewLiveChatItem,
  updateLiveChatItem,
  deleteLiveChatItem,
  switchToAlreadyJoinedChat,
  closeLiveChatWindow,
  updateSelectedChats,
  sendMessage,
  updateUnreadMessageCountMap,
} = liveChatSlice.actions
export default liveChatSlice.reducer
