import { DataGrid } from "@mui/x-data-grid"
import { styled, darken, lighten } from "@mui/material/styles"

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7)

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6)

// const getSelectedBackgroundColor = (color, mode) =>
//   mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5)

// const getSelectedHoverBackgroundColor = (color, mode) =>
//   mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4)

const StyledDataGrid = styled(DataGrid)(({ theme, hideHeader = false }) => ({
  "& .super-app-theme--warning": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      )
    }
  },
  "& .super-app-theme--success": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      )
    }
  },
  // backgroundColor: "background.paper",
  border: "none",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "action.hover"
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none"
  },
  "& .MuiDataGrid-columnHeaders": {
    display: hideHeader ?  "none": "flex",
  },
  "& .MuiDataGrid-cell":{
    outline: "inherit !important"
  }
}))

export default StyledDataGrid
